
let mergeBlackListKeys = new Set(['_meta']);

function deepMerge(target, source) {
  if (Array.isArray(target)) {
    if (!Array.isArray(source)) {
      source = [source];
    }
    target.splice(source.length);
    source.forEach((s,i) => {
      target[i] = deepMerge(target[i], source[i]);
    });
    return target;
  }
  else if (typeof target === 'object' && target) {
    Object.keys(source).filter(d => !mergeBlackListKeys.has(d)).forEach(key => {
      target[key] = deepMerge(target[key], source[key]);
    });
    return target;
  }
  else {
    return source;
  }
}

module.exports = deepMerge;