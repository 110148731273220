const defaultFilters = require('./defaultFilters');

const SITE_NAMES = {
  linkedin: 'LinkedIn',
  facebook: 'Facebook',
  twitter: 'Twitter',
  pinterest: 'Pinterest',
  instagram: 'Instagram'
};
const SOCIAL_ICONS = {
  linkedin: require('../assets/img/linkedin.svg'),
  instagram: require('../assets/img/instagram.svg'),
  twitter: require('../assets/img/twitter.svg'),
  facebook: require('../assets/img/facebook.svg')
};

const SOURCE_NAME = {
  street_view: 'Google Street View',
  redfin: 'Redfin',
  bpo: 'BPO',
  zillow: 'Zillow',
  trulia: 'Trulia'
};

const ordinal = require('ordinal');

export let shortenNumber = (number, precision) => {
  number = Number(number);
  var abs = Math.abs(number);
  if (abs >= Math.pow(10, 12)) {
    number = (number / Math.pow(10, 12)).toFixed(precision !== undefined ? precision : 1)+"t";
  } else if (abs < Math.pow(10, 12) && abs >= Math.pow(10, 9)) {
    number = (number / Math.pow(10, 9)).toFixed(precision !== undefined ? precision : 1)+"b";
  } else if (abs < Math.pow(10, 9) && abs >= Math.pow(10, 6)){
    number = (number / Math.pow(10, 6)).toFixed(precision !== undefined ? precision : 1)+"m";
  } else if (abs < Math.pow(10, 6) && abs >= Math.pow(10, 3)){
    number = (number / Math.pow(10, 3)).toFixed(precision !== undefined ? precision : 1)+"k";
  } else {
    number = number.toFixed(precision !== undefined ? precision : 2);
  }
  return number;
}

export let capitalizeWords = words => {
  return (words||'').toLowerCase().split(' ').map(word => word.substring(0,1).toUpperCase() + word.substring(1)).join(' ');
}

export let numberFilter = (n, decimals) => {
  let s;
  if (decimals !== undefined) {
    s = parseFloat(n).toFixed(decimals)
  }
  else {
    s = String(n);
  }
  var parts = s.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (decimals === undefined && parts.length >= 2 && parts[1].length >= 3) {
    parts[1] = parts[1].substring(0, 3);
  }
  return parts.join(".");
}
export let miles = (input) => parseFloat(input).toFixed(2) + ' miles';
export let capitalize = (input) => input ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : '';
export let masking = (input) => "xxxxxxxxxx";
export let addressMasking = (input) => "xx xxxx xxx";
export let phoneMasking = (input) => "xxx-xxx-xxxx";
export let emailMasking = (input) => String(input).split('@').map(function(d, i) {return (i===0) ? 'xxxxx@' : d;}).join('');
export let fullNameMaksing = (input) => String(input).split(' ').map(function(d) {return d.split('').map(function(f, i) { return (i===0) ? f : "";}).concat('xxxx').join(''); }).join(' ')
export let nameMasking = (input) => {
      if (!input) {
        return null;
      }
      return String(input).split('').map(function(d,i){return (i===0) ? d : "";}).concat('xxxx').join('');
    };
export let capString = (input) => String(input).replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
export let roundToPercent = (input) => parseInt(input * 10, 10) + '%';
export let toPercent = (input) => input*10 + '%';
export let int = (input) => parseInt(input);
export let toDate = (input, inputFormat = undefined, outputFormat = 'MM/DD/YYYY') => {
      if (input === null || input === undefined) {
        return null;
      }
      return moment(input, inputFormat).format(outputFormat);
    };
export const toPrettyDate = (input, inputFormat = undefined, outputFormat = 'MMM D, YYYY') => {
      if (input === null || input === undefined) {
        return null;
      }
      return moment(input, inputFormat).format(outputFormat);
    };
export let percent = (input, mustHaveValue, round, hidePercentSign, fixed) => {
      if (input === null || input === undefined) {
        return null;
      }
      if (typeof input === 'string') {
        input = parseFloat(input);
      }
      if (!Number.isFinite(input)) {
        return null;
      }
      if (!input && mustHaveValue) {
        return null;
      }
      if (round) {
        return Math.round(input * 100) + (hidePercentSign ? '' : '%');
      } 
      if (fixed !== undefined) {
        return Number(input * 100).toFixed(fixed) + (hidePercentSign ? '' : '%');
      }
      return Number(Math.round((input * 100)+'e2')+'e-2') + (hidePercentSign ? '' : '%');
    };
export let percent100 = (input, mustHaveValue) => {
      if (input === null || input === undefined) {
        return null;
      }
      if (!Number.isFinite(input)) {
        return null;
      }
      if (!input && mustHaveValue) {
        return null;
      }
      return Number(Math.round((input)+'e2')+'e-2') + '%';
    };
export let precisePercent100 = (input, mustHaveValue) => {
      if (input === null || input === undefined) {
        return null;
      }
      if (!Number.isFinite(input)) {
        return null;
      }
      if (!input && mustHaveValue) {
        return null;
      }
      return Number(input).toFixed(3) + '%';
    };
export let site = (input) => SITE_NAMES[input] || input;
export let socialIcon = (input) => SOCIAL_ICONS[input] || 'person_outline';
export let units = (input, singular, plural) => {
      if (input === null || input === undefined) {
        return null;
      }
      let number = Number(input);
      if (number === 1 || !plural) {
        return number + ' ' + singular;
      }
      else {
        return number + ' ' + plural;
      }
    };
export let bisectL = (input, breakPoint) => {
      if (!input) {
        return input;
      }
      if (!breakPoint) breakPoint = 0;
      if (input.length >= breakPoint) {
        return input.slice(0, Math.ceil(input.length / 2));
      }
      return input;
    };
export let bisectR = (input, breakPoint) => {
      if (!input) {
        return input;
      }
      if (!breakPoint) breakPoint = 0;
      if (input.length >= breakPoint) {
        return input.slice(Math.ceil(input.length / 2));
      }
      return [];
    };
export let limitText = (input, length) => {
      if (!input) {
        return "";
      }
      if (input.length > length-3) {
        return input.substring(0, length-3) + "...";
      }
      return input;
    };
export let preciseMoney = (input, mustHaveValue) => {
      if (input === null || input === undefined) {
        return null;
      }
      if (!input && mustHaveValue) {
        return null;
      }
      return (input < 0 ? '-$' : '$') + numberFilter(Math.abs(input), 2);
    };
export let percentile = (input) => {
      if (input === null || input === undefined) {
        return null;
      }
      return `${ordinal(Math.ceil((+input) * 100))} percentile`;
    };
export let money = (input, mustHaveValue) => {
      if (input === null || input === undefined) {
        return null;
      }
      if (!input && mustHaveValue) {
        return null;
      }
      return (input < 0 ? '-$' : '$') + numberFilter(Math.abs(input), 0);
    };
export let sqft = (input, mustHaveValue) => {
      if (input === null || input === undefined) {
        return null;
      }
      if (!input && mustHaveValue) {
        return null;
      }
      return (numberFilter(input, 0) || input) + ' sqft';
    };
export let acres = (input, mustHaveValue) => {
      if (input === null || input === undefined) {
        return null;
      }
      if (!input && mustHaveValue) {
        return null;
      }
      return numberFilter(input, 2) + ' acres';
    };
export let shortenMoney = (input, mustHaveValue, precision) => {
      if (input === null) {
        return null;
      }
      if (!input && mustHaveValue) {
        return null;
      }
      if (Number.isNaN(Number(input))) {
        return 'n/a';
      }
      return '$' + shortenNumber(input, precision);
    };
export let sources = (input, excludePrefix) => {
      if (!input) {
        return '';
      }
      if (!Array.isArray(input)) {
        input = [input];
      }

      input = new Set(input.map(source => SOURCE_NAME[source] || capitalizeWords(source)));

      return (excludePrefix ? '' : 'Sourced From ') + [...input].join(', ');
    };
export let updatedAndSource = (input) => {
      if (!input) {
        return '';
      }

      var pieces = [];
      if (input.updated) {
        pieces.push(`Last Updated ${moment(input.updated).format('MM/DD/YYYY')}`);
      }

      var source = Array.isArray(input.source) ? input.source[0] : input.source;
      if (source === 'override') {
        pieces.push('Agent Added');
      }
      else {
        pieces.push('Sourced From ' + capitalizeWords(source));
      }

      return pieces.join(', ');
    };
export let stars = (input, scale = 5) => {
      input = Number.parseInt(input, 10);
      if (Number.isInteger(input) && input >= 0 && input <= scale)
      {
        return "★".repeat(input) + "☆".repeat(scale - input);
      }
      return "";
    };
export let phone = (input, locale = "us") => {
      if (input)
      {
        let matches = input.match(/^(\d\d\d)(\d\d\d)(\d\d\d\d)$/);
        if (locale === "us" && matches)
        {
          return `(${matches[1]}; ${matches[2]}-${matches[3]}`;
        }
      }
      return input;
    };
export let persqft = (input) => {
      if (input)
      {
        return `${input} / sqft`;
      }
      return input;
    };
export let years = (input) => {
      if (input) {
        if (input === 1) {
          return `${input} year`;
        } else {
          return `${input} years`;
        }
      }
    };
export const { currency } = defaultFilters;
export const { number } = defaultFilters;

export default {
  shortenNumber,
  capitalizeWords,
  numberFilter,
  miles,
  capitalize,
  masking,
  addressMasking,
  phoneMasking,
  emailMasking,
  fullNameMaksing,
  nameMasking,
  capString,
  roundToPercent,
  toPercent,
  int,
  toDate,
  percent,
  percent100,
  precisePercent100,
  site,
  socialIcon,
  units,
  bisect_l: bisectL,
  bisect_r: bisectR,
  limitText,
  preciseMoney,
  percentile,
  money,
  sqft,
  acres,
  shortenMoney,
  sources,
  updatedAndSource,
  stars,
  phone,
  persqft,
  years,
  currency,
  number
};

angular.module('flashlightApp')
  .filter('shortenNumber', () => shortenNumber)
  .filter('capitalizeWords', () => capitalizeWords)
  .filter('numberFilter', () => numberFilter)
  .filter('miles', () => miles)
  .filter('capitalize', () => capitalize)
  .filter('masking', () => masking)
  .filter('addressMasking', () => addressMasking)
  .filter('phoneMasking', () => phoneMasking)
  .filter('emailMasking', () => emailMasking)
  .filter('fullNameMaksing', () => fullNameMaksing)
  .filter('nameMasking', () => nameMasking)
  .filter('capString', () => capString)
  .filter('roundToPercent', () => roundToPercent)
  .filter('toPercent', () => toPercent)
  .filter('int', () => int)
  .filter('toDate', () => toDate)
  .filter('percent', () => percent)
  .filter('percent100', () => percent100)
  .filter('precisePercent100', () => precisePercent100)
  .filter('site', () => site)
  .filter('socialIcon', () => socialIcon)
  .filter('units', () => units)
  .filter('bisect_l', () => bisectL)
  .filter('bisect_r', () => bisectR)
  .filter('limitText', () => limitText)
  .filter('preciseMoney', () => preciseMoney)
  .filter('percentile', () => percentile)
  .filter('money', () => money)
  .filter('sqft', () => sqft)
  .filter('acres', () => acres)
  .filter('shortenMoney', () => shortenMoney)
  .filter('sources', () => sources)
  .filter('updatedAndSource', () => updatedAndSource)
  .filter('stars', () => stars)
  .filter('phone', () => phone)
  .filter('persqft', () => persqft)
  .filter('years', () => years)
  .run(['$rootScope', function($rootScope) {
    $rootScope.shortenNumber = shortenNumber;
  }]);
