const Capabilities = require('../Capabilities');
const ChartFactory = require('./ChartFactory');
const ChartInput = require('./ChartInput');
const ColorGenerator = require('../ColorGenerator.js');
const ChartJSChart = require('./ChartJSChart');

const SCURVE_CHART_Y_AXIS = new ChartInput('yAxis', 'Y-Axis', Capabilities.CAPABILITIES_TIME_SERIES);
const SCURVE_CHART_X_AXIS = new ChartInput('xAxis', 'X-Axis', Capabilities.CAPABILITIES_TIME_SERIES);
const SCURVE_COLOR        = new ChartInput('color', 'Color', Capabilities.CAPABILITIES_GROUPING, true);

class SCurveChart extends ChartJSChart {
  constructor(config) {
    super(config, [SCURVE_CHART_Y_AXIS, SCURVE_CHART_X_AXIS, SCURVE_COLOR]);
  }

  title() {
    return 'S-Curve';
  }

  getConfigDescription(chartInput) {
    let result = this.getConfig(chartInput);
    if (result) {
      return result.title;
    }
  }

  optionsAndData(dataProvider, filterContext, step) {

    let yAxisColumn = this.getConfig(SCURVE_CHART_Y_AXIS);
    let xAxisColumn = this.getConfig(SCURVE_CHART_X_AXIS);
    let color       = this.getConfig(SCURVE_COLOR);

    if (!yAxisColumn || !xAxisColumn) {
      return;
    }

    return dataProvider.sCurveChartData(xAxisColumn, yAxisColumn, color, filterContext, step.configId).then(data => {
      // there is primary and comparison data
      let chartData = data;
      if (data.p) {
        chartData = data.p;
      }

      const labels = chartData[0].slice();
      const yVals = chartData[1].slice();
      const colors = chartData[2].slice();
      const counts = chartData[3].slice();
      let datasets = yVals.map((d,i,arr) => {
        return {
          data: d.slice(),
          borderWidth: 3,
          fill: false,
          counts: counts[i].slice()
        };
      }).filter(d => d.data.some(el => el !== null));
      datasets = datasets.map((d,i,arr) => {
        // Need to do this part after filtering nulls
        let label, bgColor;
        if (colors.length === 1 && colors[0] === yAxisColumn.key) {
          label = yAxisColumn.title;
          bgColor = 'rgba(0,110,218,1)';
        } else {
          label = colors[i] + (i === arr.length - 1 ? ` - ${color.title}` : ``);
          bgColor = ColorGenerator.getColor(yAxisColumn, colors[i]);
        }
        d.label = label;
        d.backgroundColor = bgColor;
        d.borderColor = bgColor;
        return d;
      });
      if (data.c) {
        let comparisonColors = data.c[2];
        datasets = datasets.concat(data.c[1].map((d,i) => {
          let label, bgColor;
          if (comparisonColors.length === 1 && comparisonColors[0] === yAxisColumn.key) {
            label = yAxisColumn.title + ' Comparison';
            bgColor = 'rgba(255,143,15,0.73)';
          } else {
            label = comparisonColors[i] + ' Comparison';
            bgColor = ColorGenerator.getColor(yAxisColumn, comparisonColors[i],0.73);
          }
          return {
            label: label,
            data: d.slice(),
            backgroundColor: bgColor,
            borderColor: bgColor,
            borderWidth: 1,
            borderDash: [5,5],
            fill: false,
            counts: counts[i].slice()
          };
        }));       
      }

      let chartDataSet = {
        data: {
          labels: labels,
          datasets: datasets
        },
        options: {
          maintainAspectRatio: false,
          title: { display: true,
                    text: yAxisColumn.title },
          legend: { 
            display: colors.length === 1 && colors[0] === yAxisColumn.key ? false : true,
            labels: {
              filter: (item,data) => ! / Comparison$/.test(item.text),
              boxWidth: 12,
              fontSize: 11
            },
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                return `${data.datasets[tooltipItem.datasetIndex].label}: ${yAxisColumn.formatValue(tooltipItem.yLabel)}`;
              },
              afterLabel: function(tooltipItem, data) {
                let counts = data.datasets[tooltipItem.datasetIndex].counts[tooltipItem.index];
                let counts_str = counts ? counts.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " Data Points" : "";
                return counts_str;
              }
            }
          },
          scales: {
            xAxes: [{
              scaleLabel: {
                display: true,
                labelString: xAxisColumn.title
              },
              ticks: {
                callback: function(value) {
                  return xAxisColumn.formatValue(value, true);
                },
                autoSkip: false
              }
            }],
            yAxes: [{
              scaleLabel: {
                display: false,
                labelString: yAxisColumn.title
              },
              ticks: {
                callback: function(value) {
                  return yAxisColumn.formatValue(value, true);
                }
              },
            }]
          }
        },
        type: 'line'
      };
      return chartDataSet;
    });
  }
}

ChartFactory.register('sCurve', SCurveChart);