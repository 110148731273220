angular.module('flashlightApp').config(['$provide', function($provide) {
  $provide.decorator('$http', ['$delegate', '$timeout', '$window', '$rootScope', '$q', function($delegate, $timeout, $window, $rootScope, $q) {
    ['get','post'].forEach(method => {
      var old = $delegate[method];
      $delegate[method] = function() {
        var uuid = method+String(Number(new Date()))+String(Math.floor(Math.random()*(1000000-1+1))+1);
        var renderToken = $rootScope.beginRender();
        if (typeof arguments[1] === 'object') {
          var cancel = $q.defer();
          arguments[1].timeout = cancel.promise;
          $rootScope.pendingRequests[uuid] = cancel;
        }

        return old.apply(null, [].slice.call(arguments))
          .finally(() => {
            renderToken();
            delete $rootScope.pendingRequests[uuid];
          });
      };
    });
    return $delegate;
  }])
  }])

  // intercept $http requests/responses
  // add stateparams as query params to requests
  // check headers on response
  .config(['$httpProvider', function($httpProvider) {
    $httpProvider.interceptors.push(['$q', 'growl', '$window', '$location', '$stateParams', '$rootScope', 'stateParams2QueryParams', function($q, growl, $window, $location, $stateParams, $rootScope, stateParams2QueryParams) {
      let newTokens = 0;
      function checkHeaders(response) {
        if (response.headers('X-Org-IP-Whitelist'))
          $rootScope.ipwhitelist = true;
        // check for INVALID_TOKEN
        // grab new token and click button
        if (response.data && response.data.message === 'INVALID_TOKEN' && newTokens < 2) {
          newTokens++;
          $.get('/auth/token', token => {
            $rootScope.token = token;
            $('.auth.login .btn-primary').click();
          });
        }
        if (response.data && response.data.message === 'TOO_MANY_ATTEMPTS')
          growl.error('Your account has been locked due to too many login attempts. Please wait and try again later', { ttl: 55000 });
      }
      return {
        'request': function(request) {
          if (/^\/api/.test(request.url)||request.url.indexOf($location.host()+'/api')!==-1) {
            if ($rootScope.token) {
              request.url += request.url.indexOf('?') > -1 ? '&' : '?';
              request.url += 'token='+$rootScope.token;
            }
            request.url = stateParams2QueryParams(request.url, $stateParams, request.data);
          }
          return request;
        },
        'response': function(response) {
          checkHeaders(response);
          return response;
        },
      'responseError': function(rejection) {
          checkHeaders(rejection);
          if (rejection.status === -1 && $window.location.href.toLowerCase().indexOf('adtech') !== -1) {
            console.log('Error: Client ad-blocker blocked internal request: '+rejection.config.method+ ': '+rejection.config.url+' on page '+$window.location.href);
            // Don't report with ng-http as it may be blocked with added query params
            // simple encryption to bypass ad blocker
            var rot1 = string => string.split('').map(d => String.fromCharCode(d.charCodeAt(0)+1)).join('');
            $.post('/api/report_block', {
              token: $rootScope.token,
              requestType: rot1(rejection.config.method),
              requestUrl: rot1(rejection.config.url),
              requestOriginalUrl: rot1($window.location.href)
            });
          }
          if (rejection.status === 500) {
            try {
              growl.error('Sorry, we had a problem. Please try again later');
            } catch(e) { }
          }
          if (rejection.status === 401) {
            // accessing restricted page, redirecting to root
            if (/\.html$/.test(rejection.config.url)) {
              var dest = encodeURIComponent($window.location.href);
              var query = /(\?.*)/.exec($window.location.href);
              query = query && query[1];
              query = query ? query + '&' : '?';
              query += 'dest=' + dest;
              $window.location.href = '/login' + query;
            }
          }
          if (rejection.status === -1) {
            try {
              growl.error('Network connection issue');
            } catch(e) { }
          }

          return $q.reject(rejection);
        }
      };
    }])
  }]);