angular.module('flashlightApp').factory('SearchSelectService', function() {
    let actions = {};

    return {
      register: (type, fn) => {
        if (actions[type]) {
          console.log('action already registered for ' + type);
        }
        actions[type] = fn;
      },

      run: (action) => {
        if (!action) {
          throw 'No action specified';
        }
        if (!action.type) {
          throw 'No action type specified';
        }
        if (! actions[action.type])
        {
          throw 'No action registered for ' + action.type;
        }
        actions[action.type](action.params);
      }
    };
});
