// Note: when adding a route, make sure to add it as well to:
//   - server/index.js (regex express route)
//     - clientAppRoutes
//     - clientAuthRoutes only if un-authenticated route
//   - client/src/app/run.js
//     - appRoutes
//     - authRoutes only if un-authenticated route
//   - client/gulpfile.js
//     - public-scripts and public-templates only if un-authenticated route
//
// Sidenote:
//   - root.analytics.record is defined by the extras
//     - extra/default/route.js
//     - extra/mbs/run.js

angular.module('flashlightApp').provider('modalState', ["$stateProvider", function($stateProvider) {
  const provider = this;
  this.$get = function() {
      return provider;
  };
  this.state = async function(stateName, options) {
    const { component } = options.data;
    $stateProvider.state(stateName, {
      url: options.url,
      onEnter: ["ModalService", "$state", async function(ModalService, $state) {
        
        const onExit = () => {
          if ($state.$current.name === stateName) {
            $state.go('^');
          }
        }
        ModalService.popup(component, { onExit });
      }],
      onExit: function() {
      }
    });
    return provider;
  };
}]);

function isPremarket() {
  if (document.location.href.indexOf('no_premarket') >= 0) {
    return false;
  }
  return document.location.host.startsWith('premarket') || document.location.href.indexOf('premarket_deals') >= 0;
}

// TODO: this is loaded in public, but should be split by public/private routes.  since templates are here.

angular.module('flashlightApp').config(["$stateProvider", "modalStateProvider", "$routeProvider", "$urlRouterProvider", "$urlMatcherFactoryProvider", "$locationProvider", "FlashlightQueryParameters", function($stateProvider, modalStateProvider, $routeProvider, $urlRouterProvider,  $urlMatcherFactoryProvider, $locationProvider, FlashlightQueryParameters) {
  // make trailing slash optional
  $urlMatcherFactoryProvider.strictMode(false);
  // html5mode
  $locationProvider.html5Mode(false);
  $locationProvider.hashPrefix('');

  // TODO: this admin default route isnt working
  $routeProvider.when('/admin', { redirectTo: '/admin/add-user' });

  // default Path	
  $urlRouterProvider.otherwise((e, url) => {	
    return '/';	
  }); // this has to keep using `urlRouterProvider`.... not sure why

  // auth states included in public
  $stateProvider
    .state('root', {
      // NOTE: To add a parameter, add it to constants.js
      url: ['/'].concat(FlashlightQueryParameters).join('?'),
      template: require('./states/root/index.pug'),
      // TODO: get rid of vmRoot (use $rootScope) vmr00t!!
      controller: 'RootController as vmRoot',
      resolve: {
        token: ['$http', '$rootScope',($http, $rootScope) => $rootScope.token || $http.post('/auth/token').then(d => d.data, e => null)],
        customer: ['$http', '$rootScope', '$state', '$stateParams', ($http, $rootScope, $state, $stateParams) => {
          if (window.isLoggedOut) {
            return Promise.resolve(null);
          }
          $rootScope.customer = $rootScope.customer ||
            $http.post('/api/portfolio_agg,portfolio_count,safe_portfolio_list,user,users.handles,org_for_ui,orgs,rev,version,branchname,cert_disabled,report.data.v2.supportsZipExport,report.data.has_executive_summary',$stateParams)
              .then(d => {
                if (d.data) {
                  d.data.portfolio_list = d.data.safe_portfolio_list;
                  d.data.org = d.data.org_for_ui;
                  delete d.data.org_for_ui;
                }
                return d.data;
              }, e => null);
          return $rootScope.customer;
        }],
        matomo: ['$stateParams', ($stateParams) => {
          if (typeof $stateParams.portfolio === 'undefined') {
            _paq.push(['deleteCustomVariables', 'page']);
          } else {
            _paq.push(['setCustomVariable', 1, 'Portfolio', $stateParams.portfolio, 'page']);
          }
          _paq.push(['trackPageView']);
        }]
      }
    })
    .state('root.notifications', {
      url: 'notifications?use?notify_date?notify_count?total_count',
      template: require('../app/states/notifications/index.pug'),
      controller: 'NotificationsController'
    })
    .state('root.login', !isPremarket() ? {
      url: 'login?dest',
      template: require('./states/auth/login/index.pug'),
      controller: 'LoginController'
    } : {
      url: 'login?dest?code',
      template: require('./states/auth/premarket/index.pug'),
      controller: 'LoginPremarketController'
    })
    .state('root.register', {
      url: 'register',
      template: require('./states/auth/register/index.pug'),
      controller: 'RegisterController'
    })
    .state('root.register-success', {
      url: 'register-success',
      template: require('./states/auth/register-success/index.pug')
    })
    .state('root.check-email-cert-sent', {
      url: 'check-email-cert-sent',
      template: require('./states/auth/check-email-cert-sent/index.pug')
    })
    .state('root.verify', {
      url: 'verify/:verificationToken?verified',
      template: require('./states/auth/verify/index.pug'),
      controller: 'VerifyController',
      resolve: {
        mfaDisabled: ['$http', ($http) => $http.get('/auth/mfa_disabled')
          .then(d => d.data, e => null)]
      }
    })
    .state('root.set-password', {
      url: 'set-password/:resetToken',
      template: require('./states/auth/set-password/index.pug'),
      controller: 'SetPasswordController'
    })
    .state('root.reset-password', {
      url: 'reset-password',
      template: require('./states/auth/reset-password/index.pug'),
      controller: 'ResetPasswordController'
    })
    .state('root.reset-password-success', {
      url: 'reset-password-success',
      template: require('./states/auth/reset-password-success/index.pug')
    })
    .state('root.scenarios', {
      url: 'scenarios',
      template: require('../app/states/scenarios/scenarios.pug'),
      controller: 'PayscenScenarioController'
    })
    .state('root.upload', {
      url: 'upload',
      template: '<navigation></navigation><portfolio-upload></portfolio-upload>'
    })
    .state('root.plugin-auth', {
      url: 'plugin-auth',
      template: require('../app/states/plugin-auth/index.pug'),
      controller: 'pluginAuthController'
    })
    .state('root.settings', {
      url: 'settings',
      template: require('../app/states/settings/index.pug')
    })
    .state('root.settings.auth', {
      url: '/auth',
      template: require('../app/states/settings/auth/index.pug'),
      controller: 'SettingsAuthController'
    })
    .state('root.navigator', {
      url: 'navigator',
      template: window.navigationIndex || require('../app/states/navigator/index.pug'),
      controller: function() {}
    })
    .state('root.navigator.edit', {
      url: '/edit',
      template: require('../app/states/navigator/edit/index.pug'),
      controller: 'NavigatorEditorController'
    })
    .state('root.navigator.edit.custom', {
      url: '/custom',
      template: require('../app/states/navigator/edit/custom/index.pug'),
      controller: 'CustomEditorController',
      resolve: {
        customSource: () => 'portfolio'
      }
    })
    .state('root.navigator.edit.org', {
      url: '/org',
      template: require('../app/states/navigator/edit/org/index.pug'),
      controller: 'OrgEditorController',
      params: { 'org': null, 'portfolio': null }
    })
    .state('root.navigator.edit.org_custom', {
      url: '/org_custom',
      template: require('../app/states/navigator/edit/custom/index.pug'),
      controller: 'CustomEditorController',
      resolve: {
        customSource: () => 'org'
      }
    })
    .state('root.navigator.edit.mapping', {
      url: '/mapping',
      template: require('../app/states/navigator/edit/mapping/index.pug'),
    })
    .state('root.navigator.edit.data', {
      url: '/data',
      template: require('../app/states/navigator/edit/data/index.pug'),
      controller: 'DataUploadController'
    })
    .state('root.navigator.edit.portfolio_api_controls', {
      url: '/portfolio_api_controls',
      params: {
        level: "portfolio"
      },
      template: require('../app/states/navigator/edit/apiControls/index.pug'),
      controller: 'ApiControlsEditorController'
    })
    .state('root.navigator.edit.org_api_controls', {
      url: '/org_api_controls',
      params: {
        level: "org"
      },
      template: require('../app/states/navigator/edit/apiControls/index.pug'),
      controller: 'ApiControlsEditorController'
    })
    .state('root.pivot', {
      url: 'pivot',
      template: window.pivotTemplate || require('../app/states/pivot/index.pug'),
      controller: 'PivotController'
    })
    .state('root.pivot_promo', {
      url: 'pivot_promo',
      template: require('../app/states/pivot/promo.pug'),
      controller: 'PivotPromoController'
    })
    .state('root.analytics', {
      url: 'analytics?date_interval',
      template: require('../app/states/analytics/index.pug'),
      controller: 'AnalyticsController as vmAnalytics'
    })
    .state('root.analytics.table', {
      url: '/table',
      reloadOnSearch: true,
      template: require('../app/states/analytics/table/index.pug'),
      controller: 'AnalyticsTableController'
    })
    .state('root.analytics.treemap', {
      url: '/treemap',
      reloadOnSearch: false,
      template: require('../app/states/analytics/treemap/index.pug'),
      controller: 'AnalyticsTreemapController'
    })
    .state('root.analytics.map', {
      url: '/map',
      reloadOnSearch: false,
      template: require('../app/states/analytics/map/index.pug'),
      controller: 'AnalyticsMapController'
    })
    .state('root.analytics.dashboard', {
      url: '/dashboard',
      reloadOnSearch: false,
      template: require('../app/states/analytics/dashboard/index.pug'),
      controller: 'AnalyticsDashboardController'
    })
    .state('root.analytics.list', {
      url: '/list',
      template: require('../app/states/analytics/list/index.pug'),
      controller: 'AnalyticsListController'
    })
    .state('root.analytics.record.custom', {
      url: '/custom',
      template: require('../app/states/analytics/defaultRecord/custom/index.pug'),
      controller: 'AnalyticsRecordCustomController'
    })
    .state('root.analytics.record.loan', {
      url: '/loan',
      template: require('../app/states/analytics/defaultRecord/loan/index.pug'),
      controller: 'AnalyticsRecordLoanController'
    })
    .state('root.analytics.record.company', {
      url: '/company',
      controller: 'AnalyticsRecordCompanyController'
    })
    .state('root.reporting', {
      url: '/reporting',
      template: require('../app/states/reporting/index.pug')
    })
    .state('root.explorer', {
      url: 'explorer',
      template: require('../app/states/explorer/index.pug'),
      controller: 'ExplorerController'
    });

    // modal components are found in widget-modals.  They can't be loaded here

    modalStateProvider.state('root.analytics.record.person.creditReport', {
      data: {
        component: "CreditReport"   
      },
      url: '/creditReport',
    });
    modalStateProvider.state('root.analytics.record.summary.creditReport', {
      data: {
        component: "CreditReport"   
      },
      url: '/creditReport',
    });
    modalStateProvider.state('root.analytics.record.summary.customBucketsEditor', {
      data: {
        component: "CustomBuckets"
      },
      url: '/customBucketsEditor?customBucketType'
    });

  }]);
