const Settings = require('../Settings');
const TableElement = require('./TableElement');
const BushyRowList = require('./BushyRowList');
const AggregationRowList = require('./AggregationRowList');
const TABLE_ROW_LIST_HEIGHT_KEY = 'trlh';

class TableRowList extends TableElement {

  constructor(grid, step, filterContext) {
    super(grid);

    this.count = undefined;
    this.step = step;
    this.filterContext = filterContext;
    
    this.bushyRowList = null;
    this.aggregationRowList = null;
    this.noSummary = false;
  }

  fetchData(force) {
    if (this.count === undefined || force) {
      this.grid.dataProvider.rowCount(this.step, this.filterContext).then(count => {
        if (this.count !== count) {
          if (this.bushyRowList) {
            this.bushyRowList.executePageOut();
            this.bushyRowList = null;
          }
          this.count = count;
        }

        if (this.isPagedIn && !this.bushyRowList) {
          this.buildBushyRowList();
        }
        
        this.grid.layoutChanged();
      });
    }
  }

  getHeight() {
    let defaultHeight = this.getDefaultHeight(),
        resultingHeight = defaultHeight,
        cameFromState = false,
        stateKey = this.getStateKey();

    if (this.bushyRowList) {
      resultingHeight = this.bushyRowList.getHeight();

      if (this.aggregationRowList) {
        resultingHeight += this.aggregationRowList.getHeight();
      }
    }
    else {
      resultingHeight = this.grid.getState(TABLE_ROW_LIST_HEIGHT_KEY, stateKey);
      if (!resultingHeight) {
        if (this.count) {
          resultingHeight = this.count * Settings.ROW_HEIGHT;
        }
        else {
          resultingHeight = defaultHeight;
        }
      }
      else {
        cameFromState = true;
      }
    }

    if (defaultHeight === resultingHeight) {
      this.grid.setState(TABLE_ROW_LIST_HEIGHT_KEY, stateKey, null);
    }
    else if (!cameFromState) {
      this.grid.setState(TABLE_ROW_LIST_HEIGHT_KEY, stateKey, resultingHeight);
    }

    return resultingHeight;
  }

  refresh() {
    this.fetchData(true);
    if (this.bushyRowList) {
      this.bushyRowList.refresh();
    }
    if (this.aggregationRowList) {
      this.aggregationRowList.refresh();
    }
  }

  getStickyOffsetBottom() {
    if (this.bushyRowList) {
      return this.bushyRowList.getStickyOffsetBottom();
    }
    return 0;
  }

  getDefaultHeight() {
    return Settings.ROW_HEIGHT;
  }

  buildAggregationRowList() {
    if (this.step.noSummary) {
      return;
    }
    if (!this.aggregationRowList) {
      this.aggregationRowList = new AggregationRowList(this.grid, this.step, this.filterContext);
      this.aggregationRowList.setElementAbove(this.elementAbove);

      this.aggregationRowList.setStickyHeight(() => this.getHeight());
      this.aggregationRowList.setStickyHeightBottom(() => this.getStickyOffsetBottom());
    }
    return this.aggregationRowList;
  }

  buildBushyRowList() {
    if (!this.bushyRowList) {
      this.bushyRowList = new BushyRowList(this.grid, this.step, this.filterContext, 0, this.count - 1, this.count);
      if (this.step.noSummary) {
        this.bushyRowList.setElementAbove(this.elementAbove);
      }
      else {
        this.bushyRowList.setElementAbove(this.buildAggregationRowList());
      }
    }
    this.bushyRowList.executePageIn();
  }

  setElementAbove(element) {
    super.setElementAbove(element);
    if (this.aggregationRowList) {
      this.aggregationRowList.setElementAbove(this.elementAbove);
    }

  }

  getStateKey() {
    return this.filterContext.hash;
  }

  pageIn() {
    if (this.count === undefined) {
      this.fetchData();
    }
    else {
      this.buildBushyRowList();
    }
  }

  pageOut() {
    if (this.bushyRowList) {
      this.bushyRowList.executePageOut();
    }
    this.bushyRowList = null;

    if (this.aggregationRowList) {
      this.aggregationRowList.executePageOut();
    }
    this.aggregationRowList = null;
  }

  softSetHeight(height) {
    if (!this.grid.getState(TABLE_ROW_LIST_HEIGHT_KEY, this.getStateKey())) {
      this.grid.setState(TABLE_ROW_LIST_HEIGHT_KEY, this.getStateKey(), height);
    }
  }

  viewportChanged() {
    if (this.aggregationRowList) {
      this.aggregationRowList.viewportChanged();
    }
    if (this.bushyRowList) {
      this.bushyRowList.viewportChanged();
    }
    
    super.viewportChanged();
  }

  layoutChanged() {
    super.layoutChanged();

    if (this.aggregationRowList) {
      this.aggregationRowList.layoutChanged();
    }
    if (this.bushyRowList) {
      this.bushyRowList.layoutChanged();
    }
  }

}

module.exports = TableRowList;