const Capabilities = require('../Capabilities');
const ColorGenerator = require('../ColorGenerator');
const ChartFactory = require('./ChartFactory');
const ChartInput = require('./ChartInput');
const ChartJSChart = require('./ChartJSChart');

const BAR_CHART_GROUPING = new ChartInput('grouping', 'Grouping', Capabilities.CAPABILITIES_GROUPING);
const BAR_CHART_Y_AXIS = new ChartInput('yAxis', 'Y-Axis', Capabilities.CAPABILITIES_NUMBER_AGGREGATION);

class BarChart extends ChartJSChart {
  constructor(config) {
    super(config, [BAR_CHART_GROUPING, BAR_CHART_Y_AXIS]);
  }

  title() {
    return 'Bar';
  }

  getConfigDescription(chartInput) {
    let result = this.getConfig(chartInput);
    if (result) {
      if (chartInput === BAR_CHART_GROUPING) {
        return result.groupingTitle;
      }
      return result.title;
    }
  }

  adjustDataForBounds(width, height, data, lastData) {
    let maxValues = Math.max(Math.floor((width - 100) / 20), 3);
    if (data.labels.length <= maxValues+1) {
      return data;
    }
    if (lastData.labels.length === maxValues+1) {
      return lastData;
    }
    return this.truncateData(data, maxValues, this.getConfig(BAR_CHART_Y_AXIS));
  }

  optionsAndData(dataProvider, filterContext, step) {

    let groupingColumn = this.getConfig(BAR_CHART_GROUPING);
    let yAxisColumn = this.getConfig(BAR_CHART_Y_AXIS);

    if (!groupingColumn || !yAxisColumn) {
      return;
    }

    return dataProvider.groupingChartData(groupingColumn, yAxisColumn, filterContext, step.configId, 'bar').then(chartData => {
      chartData = chartData.filter(data => data.value || data.c).map(d => {
        d = Object.assign({}, d); // make a copy of the data
        d.key = String(groupingColumn.formatValue(d.key, true));
        return d;
      });
      let keys = chartData.map(data => data.key);
      let data = chartData.map(data => data.value || 0);

      let hasComparison = false;
      let comparisonData = chartData.map(data => {
        if (data.c) {
          hasComparison = true;
        }
        return data.c;
      });

      let crossFilter = step.crossFilter(),
          crossFilterKey = crossFilter && crossFilter.key && crossFilter.key.toString();

      function getColor(key, isComparison) {
        if (crossFilterKey && crossFilterKey != key.toString()) {
          return 'rgba(128,128,128,0.4)'; 
        }

        if (!hasComparison) {
          return ColorGenerator.getColor(groupingColumn, key, 0.5);
        }
        else if (isComparison) {
          return 'rgba(255, 143, 15, 0.73)';
        }
        else {
          return 'rgba(0, 95, 198, 0.6)';
        }
      }

      return {
        data: {
          labels: keys,
          datasets: [{
            label: yAxisColumn.title,
            data: data,
            backgroundColor: keys.map(key => getColor(key)),
            borderColor: keys.map(key => getColor(key)),
            borderWidth: 1
          },
          hasComparison ? {
            label: yAxisColumn.title,
            data: comparisonData,
            backgroundColor: keys.map(key => getColor(key, true)),
            borderColor: keys.map(key => getColor(key, true)),
            borderWidth: 1
          } : null].filter(a => a)
        },
        options: {
          onClick: function(event, [chartElement]) {
            if (!chartElement) {
              step.adjustCrossFilter();
            }
            else {
              let label = String(chartElement._chart.config.data.labels[chartElement._index]);
              let d = chartData.find(d => d.key == label);
              if (!d || d.key == crossFilterKey) {
                step.adjustCrossFilter();
              }
              else {
                step.adjustCrossFilter(d);
              }
            }
          },
          maintainAspectRatio: false,
          title: { display: false },
          legend: { display: false },
          tooltips: {
            callbacks: {
              title: function(tooltipItem, data) {
                return data.labels[tooltipItem[0].index] + (tooltipItem[0].datasetIndex > 0 ? ' Comparison' : '');
              },
              label: function(tooltipItem, data) {
                return yAxisColumn.formatValue(tooltipItem.yLabel);
              }
            }
          },
          scales: {
            xAxes: [{
              ticks: {
                callback: function(value) {
                  let v = value;
                  return v.length > 15 ? v.substring(0,11) + '...' : v;
                },
                autoSkip: false
              },
              scaleLabel: {
                display: true,
                labelString: groupingColumn.groupingTitle
              },
            }],
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: yAxisColumn.title
              },
              ticks: {
                callback: function(value) {
                  return yAxisColumn.formatValue(value, true);
                },
                beginAtZero: !yAxisColumn.capabilities.has(Capabilities.CAPABILITIES_NORMALIZED_AGGREGATION)
              }
            }]
          }
        },
        type: 'bar'
      };
    });
  }
}

ChartFactory.register('bar', BarChart);