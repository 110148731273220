/* 
 * If an array of percentages that add up to 100% is rounded, the numbers may not add up to 100% (they may 
 * be more or less).  This function tries to ensure that they add up exactly to 100%, assuming the unrounded
 * numbers add up to 100.
 *
 * We're using Largest Remainder Method, Which is basically:
 *
 * 1. Rounding everything down
 * 2. Getting the difference in sum and 100
 * 3. Distributing the difference by adding 1 to items in decreasing order of their decimal parts
 */
 
function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

module.exports = function(arr) {
  if (!arr) {
    return arr;
  }
  const rounded = arr.map(d => isNumber(d) ? Math.round(d,0) : d);
  const roundedSum = rounded.reduce((acc,d) => acc += isNumber(d) ? d : 0,0);
  if (roundedSum === 100) {
    return rounded; // Nothing to do
  } else {
    const integers = arr.map(d =>isNumber(d) ? Math.floor(d) : d);
    const summedIntegers = integers.reduce((acc,d) => acc += isNumber(d) ? d : 0,0);
    const leftover = 100 - summedIntegers;
    if (leftover > arr.filter(d => isNumber(d)).length || leftover < 0) {
      // This was never going to work; we'll just return the rounded numbers (shrug)
      return rounded;
    }
    // Assign the leftovers to the highest fractions in sorted order
    const fractions = arr.map((d,i) => ({idx: i, val: isNumber(d) ? d - Math.floor(d) : d}));
    fractions.sort((a,b) => b.val - a.val);
    for (let i = 0; i < leftover; i++) {
      integers[fractions[i].idx]++;   // Distribute leftovers
    }
    return integers;
  }
}
