const BaseConfig = require('./BaseConfig');
const MAX_COLUMN_EDITOR_CHOICES = 100;

class ColumnEditor extends BaseConfig {
  constructor(grid, elementToCover, currentColumn, resultCallback, multiSelect, removeColumn, currentOpen, optional) {

    super($(`
    <div class="column-editor">
      <div class="input-filter">
        <input type="text" placeholder="search" outlet="inputFilter"/>
      </div>
      <div class="column-choices-wrapper">
        <div class="column-choices" outlet="columnChoices"></div>
      </div>
    </div>`), elementToCover, Math.max(250, elementToCover.outerWidth()), 400);

    this.resultCallback = resultCallback;
    this.currentColumn = currentColumn;
    this.multiSelect = multiSelect;
    this.removeColumn = removeColumn;
    this.currentOpen = currentOpen;
    this.grid = grid;
    this.optional = optional;
    this.focusIndex = 0;
    this._lastVal = '';
    this.outlets.inputFilter.focus();
    setTimeout(() => this.refreshChoices(), 0);

    $(this.outlets.inputFilter).on('keydown', evt => {
      if (evt.key === "ArrowDown") {
        this.focusIndex++;
        if (this.focusIndex >= this._choiceElements.length) {
          this.focusIndex = this._choiceElements.length - 1;
        }
        this.highlightElement();
      }
      else if (evt.key === "ArrowUp") {
        this.focusIndex--;
        if (this.focusIndex < 0) {
          this.focusIndex = 0;
        }
        this.highlightElement();
      }
      else if (evt.key === "PageDown") {
        this.focusIndex += 8;
        if (this.focusIndex >= this._choiceElements.length) {
          this.focusIndex = this._choiceElements.length - 1;
        }
        this.highlightElement();
      }
      else if (evt.key === "PageUp") {
        this.focusIndex -= 8;
        if (this.focusIndex < 0) {
          this.focusIndex = 0;
        }
        this.highlightElement();
      }
      else if (evt.key === "Escape") {
        this.close();
      }
      else if (evt.key === "Enter") {
        let $el = this.highlightElement();
        if ($el) {
          $el.click();
        }
      }
    });
    $(this.outlets.inputFilter).on('keyup paste', evt => {
      let val = $(this.outlets.inputFilter).val();
      if (val === this._lastVal) {
        return;
      }
      this._lastVal = val;
      this.focusIndex = 0;
      clearTimeout(this._debounceTimer);
      this._debounceTimer = setTimeout(() => this.refreshChoices(), 100);
    });
  }

  refreshChoices() {
    clearTimeout(this._loadPage);
    let choices = this.availableChoices();
    if (this.optional) {
      choices.unshift({
        groupingTitle: "None",
        title: "None",
        id: -1
      });
      if (this.currentColumn) {
        this.focusIndex = 1;
      }
    }

    let addedGroupingTitles = new Set();
    $(this.outlets.columnChoices).empty();
    this._choiceElements = [];
    let startIndex = 0;

    var loadPage = () => {
      let choicesToLoad = choices.slice(startIndex, startIndex + MAX_COLUMN_EDITOR_CHOICES);
      let columnsOpen = this.currentOpen ? this.currentOpen() : undefined;
      choicesToLoad.forEach(choice => {
        if (this.multiSelect) {
          let formatted = this.formatChoice(choice)
          if (!addedGroupingTitles.has(formatted)) {
            let $el = "";
            addedGroupingTitles.add(formatted);
            $el = $(`<div class="column-choice">`).text(formatted).appendTo(this.outlets.columnChoices);
            if (columnsOpen.has(choice.id)) {
              $el.addClass('active')
            }
            if (choice.tooltip) {
              $el.attr('title', choice.tooltip);
            }
            $el.on('click', () => {
              $(this._$element).find('[placeholder="search"]').focus();
              if (columnsOpen.has(choice.id)) {
                this.removeColumn(choice);
                this._clickHighlightElement($el);
                columnsOpen.delete(choice.id);
                $el.removeClass('active');
              } else {
                if (choice.id === -1) {
                  this.resultCallback(undefined);
                } else {
                  this.resultCallback(choice);
                }
                this._clickHighlightElement($el);
                columnsOpen.add(choice.id);
                $el.addClass('active');
              }
            });

            this._choiceElements.push($el);
          }
          this.highlightElement(true);
        }
        else {
          let formatted = this.formatChoice(choice);
          if (!addedGroupingTitles.has(formatted)) {
            addedGroupingTitles.add(formatted);
            let $el = $(`<div class="column-choice">`).text(formatted).appendTo(this.outlets.columnChoices);
            $el.on('click', () => {
              if (choice.id === -1) {
                this.resultCallback(undefined);
              } else {
                this.resultCallback(choice);
              }
              this.close();
            });
            this._choiceElements.push($el);
          }
          this.highlightElement(true);
        }
      });

      if (choicesToLoad.length > 0) {
        startIndex += MAX_COLUMN_EDITOR_CHOICES;
        this._loadPage = setTimeout(loadPage, 100);
      }
    };

    loadPage();
  }

  _clickHighlightElement(elem) {
    const ind = this._choiceElements.indexOf(elem);
    this.focusIndex = ind;
    this.highlightElement();
  }

  close(e) {
    super.close(e);
    clearTimeout(this._loadPage);
  }

  formatChoice(choice) {
    return choice.title;
  }

  highlightElement(noNeedToChangeSelected) {
    let $el = this._choiceElements[this.focusIndex];
    if ($el) {
      if (!noNeedToChangeSelected) {
        this._choiceElements.forEach(e => e.removeClass('selected'));
      }
      $el.addClass('selected');
      if ($el[0].scrollIntoViewIfNeeded) {
        $el[0].scrollIntoViewIfNeeded();
      }
      return $el;
    }
  }
}

module.exports = ColumnEditor;