
let chartRegistry = {}, sampleChartRegistry = {};
const ChartFactory = {
  createChart: (type, config) => {
    let ChartClass = chartRegistry[type];
    if (!ChartClass) throw 'NOT_FOUND';
    return new ChartClass(config);  
  },
  register: (type, classDef) => {
    chartRegistry[type] = classDef;
    sampleChartRegistry[type] = new classDef({});
  },
  availableChartTypesForAllCapabilities: capabilities => {
    return Object.keys(sampleChartRegistry).filter(key => {
      // if any inputs have a requirement that we can't fulfill, don't use this chart
      return !sampleChartRegistry[key].chartInputs.find(chartInput => !capabilities.has(chartInput.requiredCapability));
    })
    .map(key => {
      return {
        key,
        label: sampleChartRegistry[key].title()
      };
    });
  },
  availableChartTypesForSingleInput: (possibleChartTypes, capabilities) => {
    // input from above
    return possibleChartTypes.filter(possibleChart => {
      return sampleChartRegistry[possibleChart.key].chartInputs.find(chartInput => capabilities.has(chartInput.requiredCapability));
    });
  }
}

module.exports = ChartFactory;