const Capabilities = require('../Capabilities');
const IntervalValues = require('../Config/IntervalValues');

class GirdGridChart {

  constructor(config, chartInputs) {
    this.config = config || {};
    this.chartInputs = chartInputs;
  }

  render(container, dataProvider, filterContext, step, previousRender) {
    // must immediately return an element
    return $('<div>');
  }

  hasMissingInputs() {
    return !!this.chartInputs.find(input => !this.config[input.id] && !input.optional);
  }

  setConfig(chartInput, column) {
    this.config[chartInput.id] = column;
  }

  getConfig(chartInput) {
    return this.config[chartInput.id];
  }

  getConfigDescription(chartInput) {
    let result = this.getConfig(chartInput);
    if (result) {
      return result.title;
    }
  }

  serializeConfig() {
    return {
      config: this.chartInputs.map(input => {
        let entry = {
          input: input.id
        };
        if (input.requiredCapability === Capabilities.CAPABILITIES_TIME_INTERVAL) {
          entry.interval = this.config[input.id] && this.config[input.id].key;
        } else {
          entry.column = this.config[input.id] && this.config[input.id].key;
        }
        return entry;
      })
    };
  }

  deserializeConfig(grid, config) {
    config.config.forEach(configItem => {
      if (configItem.column) {
        this.setConfig(this.chartInputs.find(input => input.id === configItem.input), grid.findColumnByKey(configItem.column));
      } else if (configItem.interval) {
        this.setConfig(this.chartInputs.find(input => input.id === configItem.input), IntervalValues.find(d => d.key === configItem.interval));
      } else {
        this.setConfig(this.chartInputs.find(input => input.id === configItem.input));
      }
    });
  }

}

module.exports = GirdGridChart