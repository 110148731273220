angular.module('flashlightApp').config(['$provide', function($provide) {
  $provide.decorator('ngSrcDirective', ['$delegate', function($delegate) {
    $delegate.shift();
    return $delegate;
  }])}])
  .directive('ngSrc', ["$window", "$rootScope", "$timeout", function($window, $rootScope, $timeout) {
    return {
      restrict: 'A',
      priority: 99,
      link: (scope, element, attr) => {
        var renderToken = $rootScope.beginRender();
        setTimeout(renderToken, 7000); // give up after 7 seconds
        element.bind('load', () => {
          renderToken();
        });
        // original ng-src functionality
        attr.$observe('ngSrc', val => {
          if (!val) {
            attr.$set('src', null);
            return;
          }
          attr.$set('src', val);
        });
      }
    };
  }]);