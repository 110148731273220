const TableElement = require('./TableElement');
const ELEMENT_STACK_HEIGHT_KEY = 'esh';

class ElementStack extends TableElement {

  constructor(grid, rootElement) {
    super(grid);

    this.rootElement = rootElement;
    this.step = rootElement.step;
    this.filterContext = rootElement.filterContext;

    let stepIndex = this.grid.getIndexOfStep(this.step);
    this.stepIndex = stepIndex;
  }

  buildElements() {
    if (!this.step) {
      this.step = this.grid.getStepDefinition(this.stepIndex);
    }
    if (!this.rootElement) {
      if (this._lastRootElement && this._lastRootElement.step === this.step && this._lastRootElement.filterContext.hash === this.filterContext.hash) {
        this.rootElement = this._lastRootElement;
      }
      else {
        this.rootElement = this.step.createTableElement(this.grid, this.filterContext).rootElement;
      }
    }

    let oldElements = this.elements || [];
    this.elements = [this.rootElement];
    
    let stepIndex = this.stepIndex;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      let nextStep = this.grid.getStepDefinition(++stepIndex);
      if (!nextStep || !(nextStep.type === 'StepDefinitionChart')) {
        break;
      }
      let elementStack = null;
      if (oldElements[stepIndex] && oldElements[stepIndex].step === nextStep && oldElements[stepIndex].filterContext.hash === this.filterContext.hash) {
        this.elements.push(oldElements[stepIndex]);
      }
      else {
        elementStack = nextStep.createTableElement(this.grid, this.filterContext);
        let chartElement = elementStack.rootElement;
        this.elements.push(chartElement);
      }
    }

    this.lastElement = this.elements[this.elements.length - 1];
    this.elements.forEach((element,index) => {
      element.setRowInfo(this.elements.length, index);
      element.setElementStack(this, this.elements[index-1], this.elements[index+1]);
    });

    this.elements.forEach(e => e.setElementAbove(this.elementAbove));

    if (this.secondaryElement) {
      this.secondaryElement.setElementAbove(this.lastElement);
    }
  }

  setDragBottom(value) {
    if (!this.elements) {
      this.buildElements();
    }
    this.elements.forEach(element => element.setDragBottom(value));
  }

  createExpandedElement() {
    // TODO: put a placeholder loader expanded element here
    this.lastElement.getExpandedElement(this.secondaryElement).then(secondaryElement => {
      if (secondaryElement === this.secondaryElement) {
        return;
      }
      if (this.secondaryElement) {
        this.secondaryElement.executePageOut();
        this.secondaryElement = null;
      }
        
      if (this.isPagedIn) {
        this.secondaryElement = secondaryElement;
        if (secondaryElement) {
          this.secondaryElement.setElementAbove(this.lastElement);
        }
        this.grid.layoutChanged();
      }
    });
  }

  pageIn() {
    if (!this.elements) {
      this.buildElements();
    }

    this.elements.forEach(element => {
      if (element) {
        element.executePageIn();
      }
    });

    this.createExpandedElement();
  }

  setElementAbove(element) {
    super.setElementAbove(element);
    if (!this.elements) {
      this.buildElements();
    }

    this.elements.forEach(e => e.setElementAbove(element));
  }

  viewportChanged() {
    if (!this.elements) {
      this.buildElements();
    }

    this.elements.forEach(element => element.viewportChanged());

    if (this.secondaryElement) {
      this.secondaryElement.viewportChanged();
    }
    
    super.viewportChanged();
  }

  pageOut() {
    this.elements.forEach(element => {
      if (element) {
        element.executePageOut();
      }
    });
    
    if (this.secondaryElement) {
      this.secondaryElement.executePageOut();
    }

    super.pageOut();
  }

  refresh() {
    // if this is no longer a chart... we have to do a hard refresh
    let step = this.grid.getStepDefinition(this.stepIndex);
    if (!(step.type === 'StepDefinitionChart')) {
      this.grid.refresh(true);
      return;
    }

    this.elements.forEach(element => {
      if (element) {
        element.executePageOut();
      }
    });
    this._lastRootElement = this.rootElement;
    this.rootElement = null;
    this.step = null;
    this.buildElements();

    this.elements.forEach(element => {
      if (element) {
        element.executePageIn();
      }
    });

    if (this.secondaryElement) {
      this.secondaryElement.refresh();
      this.createExpandedElement();
    }
  }

  layoutChanged() {
    super.layoutChanged();
    
    this.elements.forEach(element => {
      if (element) {
        element.layoutChanged();
      }
    });
    
    if (this.secondaryElement) {
      this.secondaryElement.layoutChanged();
    }
  }

  getStickyOffsetBottom() {
    if (this.secondaryElement) {
      return this.secondaryElement.getStickyOffsetBottom();
    }
    return this.lastElement.getStickyOffsetBottom();
  }

  getHeight() {
    let defaultHeight = this.rootElement.getHeight(),
        resultingHeight = defaultHeight,
        stateKey = this.rootElement.fastHash(),
        cameFromState = false;

    if (this.secondaryElement) {
      resultingHeight += this.secondaryElement.getHeight();
    }
    else {
      resultingHeight = this.grid.getState(ELEMENT_STACK_HEIGHT_KEY, stateKey) || defaultHeight;
      cameFromState = true;
    }

    if (defaultHeight === resultingHeight) {
      this.grid.setState(ELEMENT_STACK_HEIGHT_KEY, stateKey, null);
    }
    else if (!cameFromState) {
      this.grid.setState(ELEMENT_STACK_HEIGHT_KEY, stateKey, resultingHeight);
    }

    return resultingHeight;
  }
}

module.exports = ElementStack;