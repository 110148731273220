const Capabilities = require('../Capabilities');
const Tooltip = require('../Tooltip.js');
const ChartFactory = require('../Charts/ChartFactory');
const ColorGenerator = require('../ColorGenerator.js');
const DragHelper = require('../DragHelper.js');
const ContextMenu = require('../Config/ContextMenu');
const percents100 = require('../../percents100');

const StepDefinitionAggregation = require('../StepDefinition/StepDefinitionAggregation');

function formatNumber(value) {
  return Math.round(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

const WIDTH_KEY = "columnWidth";

class Column {
  constructor(title, options) {
    options = options || {};

    this.id = ++Column.__ID_COUNTER;
    
    this.title = title;
    this.groupingTitle = options.groupingTitle || title;
    this.key = options.key || title;
    this.format = options.format || 'text';
    this.minWidth = options.minWidth || 120;
    this.maxWidth = options.maxWidth || 300;
    this.link = options.link;
    this.capabilities = new Set(options.capabilities || []);
    this.options = options;
    this.tooltip = options.tooltip
    this.order = options.order || this.id;
    this.postRender = options.postRender;

    this.width = this.minWidth;

    this._formatValue = options.formatValue || (value => {
      if (value === undefined || value === null) return '';
      if (this.format === 'number' && typeof value === 'number') {
        return formatNumber(value);
      }
      return value;
    });
    this._formatRange = options.formatRange || (values => {
      return values.map(this._formatValue).join(' - ');
    });
  }

  setGrid(grid) {
    this.grid = grid;
    this.width = grid.getState(WIDTH_KEY, this.key) || this.width;
  }

  formatValue(data, preferShort) {
    if (Array.isArray(data)) {
      return this._formatRange(data);
    }
    if (preferShort && this.options.shortFormatter) {
      return this.options.shortFormatter(data);
    }
    return this._formatValue(data);
  }

  render(element, data, count) {
    let value = data[this.key];

    if (Array.isArray(value)) {
      $(element).addClass('cell-range').text(this.formatValue(value));
      new Tooltip(element);
    }
    else if (value !== undefined && value !== null && typeof value === 'object') {
      if (!value.points && (value.c !== undefined || value.p !== undefined) && (typeof value.c === 'number' || typeof value.p === 'number')) {
        this.renderComparisonCell(element, value);
      }
      else if (value.points) {
        this.renderSparkLine(element, value);
      }
      else if (value.median !== undefined || (value.c && value.c.median !== undefined) || (value.p && value.p.median !== undefined)) {
        this.renderBoxAndWhiskers(element, value);
      }
      else if (value.buckets || (value.c && value.c.buckets) || (value.p && value.p.buckets)) {
        this.renderHistogram(element, value);
      }
      else {
        this.renderSpark(element, value, count);
      }
    }
    else {
      $(element).text(this._formatValue(value));
      new Tooltip(element);
    }

    if (this.postRender) {
      this.postRender(element, value, data);
    }
  }

  renderComparisonCell(element, value) {
    let change = (value.p - value.c) || 0;
    let valueToDisplay = value.p;
    let showDiff = true, showingComparisonOnly = false;
    if (valueToDisplay === null || valueToDisplay === undefined) {
      valueToDisplay = value.c;
      change = 0;
      showDiff = false;
      showingComparisonOnly = true;
    }
    if (value.c === null || value.c === undefined) {
      showDiff = false;
      change = 0;
    }

    let diff = ((change / value.c) * 100) || 0;
    let $el = $(element).empty();
    $el.append($(`<div class="sub-cell-2 sub-cell-2-1 ${showingComparisonOnly ? 'comparison-only':''}">`).text(this._formatValue(valueToDisplay)));

    let diffCell = $(`<div class="sub-cell-2 sub-cell-2-2  ${showingComparisonOnly ? 'comparison-only':''}">`).text(showDiff ? this.formatValue(Math.abs(change), true) : '');
    if (diff < 0) {
      diffCell.addClass('negative negative-' + Math.ceil(-diff / 10));
    }
    else if (diff > 0) {
      diffCell.addClass('positive positive-' + Math.ceil(diff / 10));
    }
    $el.append(diffCell);

    new Tooltip(element, () => {
      return $(`<div class="girdgrid-spark-comparison">        
        ${value.c !== undefined && value.c !== null ? `<h5>Comparison:</h5>
        <h4>${this._formatValue(value.c)}</h4>`:''} 
        ${value.p !== undefined && value.p !== null ? `<h5>Value:</h5>
        <h4>${this._formatValue(value.p)}</h4>`:''}
        ${showDiff ? `<h5>Abs. Change:</h5>
        <h4>${this._formatValue(change)}</h4>
        <h5>% Change:</h5>
        <h4>${Math.round(diff * 100)/100}%</h4>`:''}
      </div>`);
    });
  }

  renderSparkLine(cell, sparkData) {
    if (!sparkData.points) {
      return;
    }

    let allPoints = sparkData.points;
    if (sparkData.c) {
      allPoints = allPoints.concat(sparkData.c.points);
    }

    if (allPoints.length === 0) {
      return;
    }


    // Create coordinates for a sparkline polyline
    if (sparkData.high === undefined) {
      sparkData.high = allPoints.reduce((max,p) => Math.max(max, p));
    }
    if (sparkData.low === undefined) {
      sparkData.low = allPoints.reduce((min,p) => Math.min(min, p));
    }

    var scale = 100 / sparkData.labels.length,
        range = sparkData.high === sparkData.low ? 0 : (24 / (sparkData.high - sparkData.low));

    var points = sparkData.points.map((p, i) => {
      return `${(sparkData.labels.length - 1 - i) * scale},${27-((p - sparkData.low) * range)}`;
    });

    var comparisonPoints = sparkData.c && sparkData.c.points.map((p, i) => {
      return `${(sparkData.labels.length - 1 - i) * scale},${27-((p - sparkData.low) * range)}`;
    });
    
    var $spark = $(`<svg height="30" width="100" viewBox="0 0 100 30" preserveAspectRatio="none">
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%" gradientUnits="userSpaceOnUse" >
          <stop offset="0%" stop-color="#006eda" />
          <stop offset="100%" stop-color="#ffffff" />
        </linearGradient>
      </defs>      
      ${points.length > 0 ? `<polyline points="${points.join(' ')}" style="fill:none;stroke:url(#gradient);stroke-width:3" />` : ''}
      ${comparisonPoints ? `<polyline points="${comparisonPoints.join(' ')}" style="fill:none;stroke:rgb(255, 143, 15);stroke-width:1" />` : ''}
    </svg>`);

    $(cell).addClass('spark-graph-cell').append($spark);

    var $element = $('<div class="value">');
    $element.append($('<div class="spacer">').text(this._formatValue(sparkData.high === undefined ? sparkData.points[0] : sparkData.high)));
    $element.append($('<div class="actual-value">').text(this._formatValue(sparkData.points[0])));
    $(cell).append($element);

    new Tooltip($spark, (toElement, event) => {
      let pct = 1-(event.offsetX / $spark.outerWidth());
      let index = Math.max(Math.floor(pct * sparkData.points.length), 0);
      let formattedValue = this._formatValue(sparkData.points[index]);
      if (sparkData.labels && sparkData.labels[index]) {
        let diff = 0;
        if (sparkData.c) {
          let change = sparkData.points[index] - sparkData.c.points[index];
          diff = ((change / sparkData.c.points[index]) * 100) || 0;
        }
        
        return $(`<div class="girdgrid-spark-comparison">
          <h5>Date:</h5>
          <h4>${sparkData.labels[index]}</h4>
          ${sparkData.c ? `<h5>Comparison:</h5>
          <h4>${this._formatValue(sparkData.c.points[index])}</h4>` : ''}
          ${formattedValue ? `<h5>Value:</h5>
          <h4>${formattedValue}</h4>` : ''}
          ${sparkData.c ? `<h5>Change:</h5>
          <h4>${Math.round(diff * 100)/100}%</h4>` : ''}
        </div>`);
      
      }

      return formattedValue;
    });
  }

  renderHistogram(cell, sparkData) {
    if (sparkData.p) {
      sparkData = sparkData.p;
    }

    if (!sparkData.buckets || sparkData.buckets.length === 0) {
      return;
    }

    // Create coordinates for a sparkline polyline
    sparkData.high = sparkData.buckets.map(d=>{return d.displayValue}).reduce((max,p) => Math.max(max, p));

    var points = sparkData.buckets.map((p) => {
      return `<div class = "histogram-bar-container">
              <div class = "histogram-bar ${p.color||''}" style = "height:${(p.displayValue / sparkData.high)*100}%;"></div>
              </div>`;
    });


    var $spark = $(`<div class ='spark-histogram'>
      ${points.join('')}
    </div>`);

    $(cell).addClass('spark-histogram-cell').append($spark);

    var $element = $('<div class="value">');
    $element.append($('<div class="actual-value">').text(this._formatValue(sparkData.display)));
    $(cell).append($element);

    new Tooltip($element, () => `<table>
                <tr>
                    <th align="right">Median:
                    </th>
                    <td align="left"> ${this.formatValue(sparkData.display)}</td>
                </tr>
              </table>`);
    
    let keys = sparkData.buckets.map(d =>{return d.label})
    let toolbucket = sparkData.buckets.map(d=>{return d.value})

    new Tooltip($spark, (toElement, event) => {
      // event.target.offsetLeft;
      let pct = 1-((event.offsetX + event.target.offsetLeft) / $spark.outerWidth());
      let index = toolbucket.length-Math.ceil(pct * toolbucket.length);
      return `<table>
                <tr>
                    <th align="right">${this.title.replace("_", " ").replace(/\w\S*/g, (txt) => { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); })}:
                    </th>
                    <td align="left"> ${this.formatValue(keys[index])}</td>
                </tr>
                <tr>
                    <th align="right">Count:</th>
                    <td align="left">${toolbucket[index]}</td>
                </tr>
              </table>`;
    });
  }

  renderBoxAndWhiskers(cell, sparkData) {
    if (sparkData.c || sparkData.p) {
      sparkData = sparkData.p;
    }

    // Create coordinates for a sparkline polyline
    if (sparkData.high === undefined) {
      sparkData.high = sparkData.upper_range;
    }
    if (sparkData.low === undefined) {
      sparkData.low = sparkData.lower_range;
    }

    var scalar = 100 / (sparkData.high - sparkData.low);
    function scaleX(x) {
      return ((x - sparkData.low) * scalar) + '%';
    }
    
    let boxWidth = (sparkData.upper_box - sparkData.lower_box)*scalar;

    var $spark = $(`<div class='spark-box-and-whiskers'>
      <div class="spark-box-and-whiskers-box ${boxWidth <= 5 ? 'narrow-box' : ''}" style="left: ${scaleX(sparkData.lower_box)}; width: ${boxWidth}%;"></div>
      <div class="spark-box-and-whiskers-whisker lower-whisker" style="left: ${scaleX(sparkData.lower_range)}; width: ${(sparkData.lower_box - sparkData.lower_range)*scalar}%;"></div>
      <div class="spark-box-and-whiskers-whisker upper-whisker" style="left: ${scaleX(sparkData.upper_box)}; width: ${(sparkData.upper_range - sparkData.upper_box)*scalar}%;"></div>
      ${boxWidth > 5 ? `<div class="spark-box-and-whiskers-median" style="left: ${scaleX(sparkData.median)};"></div>
      <div class="spark-box-and-whiskers-mean" style="left: ${scaleX(sparkData.mean)};"></div>` : ''}
    </div>`);
    
    $(cell).addClass('spark-graph-cell').append($spark);

    var $element = $('<div class="value">');
    $element.append($('<div class="spacer">').text(this._formatValue(sparkData.high === undefined ? sparkData.mean : sparkData.high)));
    $element.append($('<div class="actual-value">').text(this._formatValue(sparkData.mean)));
    $(cell).append($element);

    new Tooltip($(cell), () => {
      return `<table>
      <tr>
          <th align="right">3rd Percentile:</th>
          <td align="right"> ${this._formatValue(sparkData.lower_range)}</td>
      </tr>
      <tr>
          <th align="right">Lower Quartile:</th>
          <td align="right">${this._formatValue(sparkData.lower_box)}</td>
      </tr>
      <tr>
          <th align="right">Mean:</th>
          <td align="right">${this._formatValue(sparkData.mean)}</td>
      </tr>
      <tr>
          <th align="right">Median:</th>
          <td align="right">${this._formatValue(sparkData.median)}</td>
      </tr>
      <tr>
          <th align="right">Upper Quartile:</th>
          <td align="right">${this._formatValue(sparkData.upper_box)}</td>
      </tr>
      <tr>
          <th align="right">97th Percentile:</th>
          <td align="right">${this._formatValue(sparkData.upper_range)}</td>
      </tr>
    </table>`;
    });
  }

  renderSpark(cell, sparkData, count) {

    let totalSparkDataKeySet = new Set();

    var processDataSet = (d,subCount,ignoreOther) => {
      let sparkDataKeys = Object.keys(d);
      let total = sparkDataKeys.reduce((total, key) => total + d[key], 0);
      if (!ignoreOther) {
        const other = subCount - total;
        if (other > 0) {
          total += other;
          if (d.hasOwnProperty('Other')) {    // Edge case where the category already contains 'Other'
            const idx = sparkDataKeys.indexOf('Other');
            sparkDataKeys[idx] = '"Other"';
            d['"Other"'] = d.Other;
          }
          sparkDataKeys.push('Other');
          d.Other = other;
        }        
      }
      return sparkDataKeys.reduce((acc, key) => {
        totalSparkDataKeySet.add(key);
        acc[key] = d[key] / total * 100;
        return acc;
      }, {});
    };

    let dataSets = [];
    if (sparkData.c || sparkData.p) {
      dataSets.push(processDataSet(sparkData.p || {}, count.p, this.options.countWeighted || this.options.hideMissing));
      dataSets.push(processDataSet(sparkData.c || {}, count.c, this.options.countWeighted || this.options.hideMissing));

      if (totalSparkDataKeySet.size <= 1) {
        dataSets = [dataSets[0]];
      }
    }
    else {
      dataSets.push(processDataSet(sparkData, count, this.options.countWeighted || this.options.hideMissing));
    }

    let totalSparkDataKeyArray = [...totalSparkDataKeySet];

    let $targetElement = $(cell);
  
    if (totalSparkDataKeyArray.length === 1) {
      $targetElement = $(`<div class="girdgrid-spark-bar"></div>`).appendTo(cell);
      $targetElement.append($(`<div style="background-color: ${ColorGenerator.getColor(this, totalSparkDataKeyArray[0])};" class="girdgrid-spark-bar-item girdgrid-spark-bar-item-with-text"></div>`).text(totalSparkDataKeyArray[0]));
    }
    else {
      let contents = dataSets.map(d => `
        <div class="girdgrid-spark-bar">
          ${totalSparkDataKeyArray.map(key => !d[key] ? '' : `
            <div spark-key="${encodeURIComponent(key)}" class="girdgrid-spark-bar-item" style="width:${d[key]}%; background-color: ${ColorGenerator.getColor(this, key)};"></div>
          `).join('')}
        </div>
        `).join('');

      if (dataSets.length > 1) {
        $targetElement = $(`<div class="girdgrid-spark-bar-with-comparison">${contents}</div>`).appendTo(cell);
      }
      else {
        $targetElement = $(contents).appendTo(cell);
      }
    }

    new Tooltip($targetElement, (toElement) => {
      let $tipContents = $(`<div class="girdgrid-spark-bar-tooltip">`);

      // find the key we are focusing on
      let focusedKey = $(toElement).attr('spark-key');

      if (dataSets.length > 1) {
        let $el = $(`<div class="girdgrid-spark-bar-tooltip-row">`);
        $el.append($(`<div class="girdgrid-spark-bar-tooltip-color">`));
        $el.append($(`<div class="girdgrid-spark-bar-tooltip-label">`));
        $el.append($(`<div class="girdgrid-spark-bar-tooltip-percent">`).text("Primary"));
        $el.append($(`<div class="girdgrid-spark-bar-tooltip-percent">`).text("Comparison"));
        $el.appendTo($tipContents);
      }

      // Percentages can add up to more or less than 100% when rounding.  Here, we are adjusting to 100%
      const pctSets = dataSets.map(d => {
        const pcts = Object.values(d);
        const keys = Object.keys(d);
        const correctedPcts = percents100(pcts);
        const set = {};
        for (let i = 0; i < keys.length; i++) {
          set[keys[i]] = correctedPcts[i];
        }
        return set;
      })
      
      totalSparkDataKeyArray.forEach(key => {
        
        let $el = $(`<div class="girdgrid-spark-bar-tooltip-row ${key === focusedKey ? 'focused-key' : ''}">`);
        $el.append($(`<div class="girdgrid-spark-bar-tooltip-color" style="background-color: ${ColorGenerator.getColor(this, key)};">`));
        $el.append($(`<div class="girdgrid-spark-bar-tooltip-label">`).text(key));
        pctSets.forEach(d => {
          $el.append($(`<div class="girdgrid-spark-bar-tooltip-percent">`).text(d[key] !== undefined ? `${d[key]}%` : ''));          
        })
        $el.appendTo($tipContents);
      });

      return $tipContents;
    });

    $(cell).addClass('spark-cell');
  }

  createCell(data, left, isGroupingCell, filterContext, tableRow, count) {
    let result = $(`<div class="cell">`);
    let target = result;
    this.styleCell(result, left);
    
    var canGroupBy = !(!this.capabilities.has(Capabilities.CAPABILITIES_GROUPING) || (tableRow.step && tableRow.step.column === this));
    var availableCharts = ChartFactory.availableChartTypesForSingleInput(this.grid.availableCharts(), this.capabilities);

    if (this.link) {
      target = $('<a>').appendTo(result);
      // update link on mousedown
      target.on('mousedown', () => {
        target.attr('href', this.link(data[this.key], data, filterContext));
      });
      target.attr('href', this.link(data[this.key], data, filterContext));
    }
    else if (this.options.actions || canGroupBy || availableCharts.length > 0) {
      result.addClass('touchable');
    }

    this.render(target, data, count);

    if (!this.link) {
      ContextMenu.attachToElement(target, (isLeftClick) => {
        if (isGroupingCell && isLeftClick && this.options.handleLeftClickGrouping) {
          this.options.handleLeftClickGrouping(data[this.key], data);
          return [];
        }

        return (this.options.actions && this.options.actions(data[this.key]) || [])
          .concat([
          !canGroupBy ? null : {
            label: 'Group By',
            action: () => {
              let myIndex = this.grid.getIndexOfStep(tableRow.step) + 1;
              if (tableRow.step && tableRow.step.column === this.grid.atomicItem) {
                myIndex--;
              }

              let newStep = new StepDefinitionAggregation(this, tableRow.aggregationsConfig());
              let newSteps = this.grid.stepDefinitions.slice(0, myIndex).concat(newStep).concat(this.grid.stepDefinitions.slice(myIndex));

              this.grid.setStepDefinitions(newSteps);
              this.grid.refresh();
              tableRow.setExpanded(true);
              this.grid.afterAddStepDefinition(newStep);
            }
          },
          {
            label: 'Add Chart',
            action: availableCharts.map(availableChart => ({
              label: availableChart.label,
              action: () => {
                let myIndex = this.grid.getIndexOfStep(tableRow.step) + 1;
                if (tableRow.step && tableRow.step.column === this.grid.atomicItem) {
                  myIndex--;
                }

                const StepDefinitionChart = require('../StepDefinition/StepDefinitionChart');
                let newStep = new StepDefinitionChart(availableChart.key);
                let newSteps = this.grid.stepDefinitions.slice(0, myIndex).concat(newStep).concat(this.grid.stepDefinitions.slice(myIndex));
                
                let chartInputToSet = newStep.chart().chartInputs.find(chartInput => this.capabilities.has(chartInput.requiredCapability));
                if (chartInputToSet) {
                  newStep.chart().setConfig(chartInputToSet, this);
                }

                this.grid.setStepDefinitions(newSteps);
                this.grid.refresh();
                this.grid.afterAddStepDefinition(newStep);
                tableRow.setExpanded(true);
              }
            }))
          }
        ].filter(a => a && (!Array.isArray(a.action) || a.action.length > 0)))
      }, true, tableRow && tableRow._fetchedData && tableRow._fetchedData.filterContext);
    }

    return result;
  }

  createHeader($parent, left, aggregationsConfig) {
    let result = $(`<div class="cell measuring cell-header" draggable="true">`).appendTo($parent);
    this.styleCell(result, left);

    //
    // This whole thing is kinda wonky, but the effect is quite nice.
    // Basically, it's nice to have column headers have the text be bottom heavy.  Browsers
    // naturally wrap being top heavy... which isn't what we want.  It's better to have:
    //
    //      Avg.
    // Debt CRPM
    //
    // than...
    //
    // Avg. Debt
    //      CRPM
    //
    // this also lets us measure the actual space used to better position the sort controls


    let maxWidth = 0;
    let titleText = this.title.replace(/_/g, ' ');
    let titlePieces = titleText.split(' ');

    let rowPieces = [], lastText = '', nextLabel = $(`<div class="cell-header-label">`).appendTo(result);
    for (let i = titlePieces.length - 1; i >= 0; i--) {
      rowPieces = [titlePieces[i]].concat(rowPieces);
      let newText = rowPieces.join(' ');
      nextLabel.text(newText);
      if (nextLabel[0].scrollWidth > nextLabel[0].offsetWidth) {
        nextLabel.text(lastText);
        nextLabel = $(`<div class="cell-header-label">`).text(titlePieces[i]).insertBefore(nextLabel);
        lastText = titlePieces[i];
        rowPieces = [titlePieces[i]];
        maxWidth = Math.max(nextLabel[0].offsetWidth, maxWidth);
      }
      else {
        lastText = newText;
        maxWidth = Math.max(nextLabel[0].offsetWidth, maxWidth);
      }
    }
    
    if (this.tooltip) {
      new Tooltip(result, () => this.tooltip);
    }

    result.removeClass('measuring');
    
    $(`<div class="cell-header-grip">`).appendTo(result).on('mousedown', (event) => {
      if (event.button !== 0) {
        return;
      }

      let startWidth = this.width;
      DragHelper.doEWResizeHelper(event, this.width - this.minWidth, this.maxWidth - this.width, delta => {
        this.width = startWidth + delta;
        aggregationsConfig.aggregationPositions[this.id] = -1;
        aggregationsConfig.resetAggregationPositions();
      }, () => {
        // wish there was a less dramatic refresh option
        // but we have to rebuild stuff in case the height of the column headers
        // changes
        this.grid.refresh();
        if (this.width !== this.minWidth) {
          this.grid.setState(WIDTH_KEY, this.key, this.width);
        }
        else {
          this.grid.setState(WIDTH_KEY, this.key, null);
        }
      });
    })

    let sortControls = $(`<div class="sort-controls">&#9660;</div>`);
    switch (this.format) {
      case 'number':
        sortControls.css('right', maxWidth + 15);
        break;
      default:
        sortControls.css('left', maxWidth + 15);
        break;
    }
    sortControls.appendTo(result);
    return result;
  }

  styleCell(element, left) {
    element.css({
      width: this.width,
      left: left
    });
    element.addClass(`cell-${this.format}`);
    if (this.link) {
      element.addClass('cell-link');
    }
  }
}

Column.__ID_COUNTER = 0;

module.exports = Column;