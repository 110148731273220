const Capabilities = require('../Capabilities');
const ColumnEditor = require('./ColumnEditor');

class StaticValuesEditor extends ColumnEditor {
  constructor(grid, elementToCover, currentColumn, resultCallback, multiSelect, removeColumn, currentOpen, requiredCapability, formatColumn, optional = false, options) {
    super(grid, elementToCover, currentColumn, resultCallback, multiSelect, removeColumn, currentOpen, optional);
    this.requiredCapability = requiredCapability || Capabilities.CAPABILITIES_LABEL;
    this.formatColumn = formatColumn || (column => column.title);
    this.options = options;
  }

  formatChoice(choice) {
    return this.formatColumn(choice);
  }

  availableChoices() {
    let filter = $(this.outlets.inputFilter).val().toLowerCase();
    let columns = this.options;

    if (this.currentColumn) {
      columns.unshift(this.currentColumn);
    }
    return this.grid.filterAndSortCallback(columns, filter);
  }  
}

module.exports = StaticValuesEditor;