const ContextMenu = require('../Config/ContextMenu');
const GroupingColumnEditor = require('../Config/GroupingColumnEditor');
const Settings = require('../Settings');
const StickyRow = require('./StickyRow');
const Tooltip = require('../Tooltip.js');

class AggregationRowList extends StickyRow {

  constructor(grid, step) {
    super(grid);
    this.step = step;
  }

  
  elementClassNames() {
    return `aggregation-row-header`;
  }

  getHeight() { return Settings.ROW_HEIGHT-2; }
  createDropTarget() {}
  removeDropTarget() {}

  pageIn() {
    super.pageIn();

    let draggable = this.grid.getIndexOfStep(this.step) < this.grid.stepDefinitions.length - 1;

    this._$contents = $(`<div class="aggregation-row-header-info">`).appendTo(this._$dom);
    if (draggable) {
      this._$grip = $(`<div class="row-header-grip" draggable="true">⣿</div>`).appendTo(this._$contents);
    }
    this._$label = $(`<div class="aggregation-row-header-label">`).text(this.step.title());
    this._$contents.append(this._$label);
    this._$sortIndicator = $('<div class="sort-indicator"><div class="sort-indicator-up">&#9650;</div><div class="sort-indicator-down">&#9660;</div></div>').appendTo(this._$contents);
    
    this._$rightContents = $(`<div class="aggregation-row-header-info">`).appendTo(this._$rightDom);

    this._$label.append($('<div class="cog">&#9881;</div>'));

    if (this.step.column && this.step.column.tooltip) {
      new Tooltip(this._$label, () => this.step.column.tooltip);
    }

    if (draggable) {
      this._$grip.on('dragstart', event => {
        event.originalEvent.dataTransfer.setData('stepid', this.step.id);
        if (this.step.column) {
          event.originalEvent.dataTransfer.setData('columnid', this.step.column.id);
        }
        this.grid.dragStepHint(this.step);
        this.grid.setStepDefinitions(this.grid.stepDefinitions.filter(s => s !== this.step));
        this.grid.refresh();
      });
    }

    ContextMenu.attachToElement(this._$dom, () => ([
      this.step.column === this.grid.atomicItem ? null : {
        label: 'Remove',
        action: () => {
          this.grid.setStepDefinitions(this.grid.stepDefinitions.filter(s => s !== this.step));
          this.grid.refresh();
        } 
      },
      {
        label: 'Sort Ascending',
        action: () => this.sortAsc() 
      },
      {
        label: 'Sort Descending',
        action: () => this.sortDesc()
      },
      ... ((this.step.column.options && this.step.column.options.actions) ? this.step.column.options.actions() : []),
      {
        label: 'Add Chart Above',
        action: this.grid.availableCharts().map(availableChart => ({
          label: availableChart.label,
          action: () => {
            const StepDefinitionChart = require('../StepDefinition/StepDefinitionChart');
            let newStep = new StepDefinitionChart(availableChart.key);
            let chartInputToSet = newStep.chart().chartInputs.find(chartInput => this.step.column.capabilities.has(chartInput.requiredCapability));
            if (chartInputToSet) {
              newStep.chart().setConfig(chartInputToSet, this.step.column);
            }

            let myIndex = this.step ? this.grid.getIndexOfStep(this.step) : 0;
            let newSteps = this.grid.stepDefinitions.slice(0, myIndex).concat(newStep).concat(this.grid.stepDefinitions.slice(myIndex));

            this.grid.setStepDefinitions(newSteps);
            this.grid.refresh();
            this.grid.afterAddStepDefinition(newStep);
          }
        }))
      }
    ].filter(a=>a)));

    this._$label.on('click', ev => {
      new GroupingColumnEditor(this.grid, this._$label, this.step.column, resultingColumn => {
        if (resultingColumn !== this.step.column) {
          // require late to handle loops
          const StepDefinitionAggregation = require('../StepDefinition/StepDefinitionAggregation');
          let myIndex = this.step ? this.grid.getIndexOfStep(this.step) : 0;
          let newStep = new StepDefinitionAggregation(resultingColumn, this.step.aggregationsConfig);
          let newSteps = this.grid.stepDefinitions.slice(0, myIndex).concat(newStep);

          if (this.step.column === this.grid.atomicItem) {
            newSteps = newSteps.concat(this.step);
          }
          else if (this.grid.atomicItem !== resultingColumn) {
            newSteps = newSteps.concat(this.grid.stepDefinitions.slice(myIndex+1));
          }

          this.grid.setStepDefinitions(newSteps);
          this.grid.refresh();
          this.grid.afterAddStepDefinition(newStep);
        }
      },false);
    });

    this._$sortIndicator.on('click', () => {
      if (!this.step.sort || this.step.sort.direction === 1) {
        this.sortAsc();
      }
      else {
        this.sortDesc();
      }
    });

    this.attachDragEvents(this._$contents, 'isDragging');
    this.attachDragEvents(this._$rightDom, 'isDraggingRight');

    this.refresh();
  }

  sortAsc() {
    this.step.sort = {
      direction: -1,
      timestamp: new Date().getTime()
    };
    this.grid.refresh();
  }

  sortDesc() {
    this.step.sort = {
      direction: 1,
      timestamp: new Date().getTime()
    };
    this.grid.refresh();
  }

  refresh() {
    if (!this._$contents) {
      return;
    }

    this._$contents.removeClass('sort-up sort-down');
    if (this.step.sort) {
      // check to see if this is "fresher" than any aggregations sort
      let sortTimestamp = this.step.sort.timestamp;

      if (!this.step.aggregationsConfig.hasFresherSort(sortTimestamp)) {
        if (this.step.sort.direction > 0) {
          this._$contents.addClass('sort-down');
        }
        else {
          this._$contents.addClass('sort-up'); 
        }
      }
    }
  }

  setExpanded() {}

  pageOut() {
    super.pageOut();
    this._$contents.remove();
    this._$rightContents.remove();
    this._$contents = null;
    this._$rightContents = null;
  }
}

module.exports = AggregationRowList;