angular.module('flashlightApp').controller('RegisterController', ["$rootScope", "$scope", "$state", "$http", "$timeout", function($rootScope, $scope, $state, $http, $timeout) {
  $timeout(()=> $('.auth input').on('input', ()=> $scope.errorMessage = ''));
  $scope.form = {};
  $scope.register = function () {
    $scope.registerInProgress = true;
    return $http.post('/auth/register', {registration:$scope.form,token:$rootScope.token}).then(() => {
      $scope.registerInProgress = false;
      $state.go('root.register-success');
    }, err => {
      $scope.registerInProgress = false;
      const xflash = err.data.xflash;
      if (xflash) $scope.errorMessage = Object.keys(xflash).map(d => xflash[d])[0];
      else $scope.errorMessage = err.data.message;
    });
  };
}]);
