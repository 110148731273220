const Settings = require('../Settings');
const StickyRow = require('./StickyRow');

class TableRow extends StickyRow {

  constructor(grid, step, filterContext, index) {
    super(grid);
    this.step = step;
    this.filterContext = filterContext;
    this._hash = (filterContext.hash ? filterContext.hash + '_' : '') + index;
    this.index = index;

    this.isDragging = false;
    this.isDraggingRight = false;
    this.expanded = false;
    this._$dom = null;
    this._$contents = null;

    this.id = ++TableRow.__ID_COUNTER;
  }

  aggregationsConfig() {
    return this.step.aggregationsConfig;
  }

  setExpandedStateChangeCallback(expandedStateChangeCallback) {
    this.expandedStateChangeCallback = expandedStateChangeCallback;
  }

  setExpanded(expanded, dontNotify) {
    if (this.expanded !== expanded) {
      this.expanded = expanded;

      if (this._$dom && this._$toggle) {
        if (expanded) {
          this._$dom.addClass('table-row-expanded');
          this._$rightDom.addClass('table-row-expanded');
          this._$toggle.addClass('expanded');
        }
        else {
          this._$dom.removeClass('table-row-expanded');
          this._$rightDom.removeClass('table-row-expanded');
          this._$toggle.removeClass('expanded');
        }
      }

      if (dontNotify !== true && this.expandedStateChangeCallback) {
        this.expandedStateChangeCallback(this.expanded);
      }
    }
  }

  fetchData() {
    if (!this.data) {
      this.data = this.grid.dataProvider.rowData(this.step, this.filterContext, this.index);
    }
    return this.data;
  }

  hashFromData(data) {
    if (!data) {
      return this._hash;
    } 
    return (this.filterContext.hash || '') + ((this.step && this.step.column) ? data[this.step.column.key] : ''); // todo: make better
  }

  hash() {
    return this.fetchData().then(data => {
      return this.hashFromData(data);
    });
  }

  fastHash() {
    return this._hash;
  }

  elementClassNames() {
    return `table-row ${this.index % 2 === 0 ? 'even-row' : 'odd-row'}`;
  }

  getExpandedElement(currentElement) {
    return this.fetchData().then(data => {
      if (!data) {
        return;
      }

      let stepDefinition = this.grid.getStepDefinition(this.grid.getIndexOfStep(this.step) + 1);
      if (!stepDefinition) {
        return null;
      }
      if (currentElement && currentElement.step == stepDefinition) {
        return currentElement;
      }
      let expandedElement = stepDefinition.createTableElement(this.grid, data.filterContext);
      if (currentElement && expandedElement.softSetHeight) {
        expandedElement.softSetHeight(currentElement.getHeight());
      }
      return expandedElement;
    })
  }

  shouldStick() {
    return this.expanded;
  }

  zIndex() {
    return this.grid.zindexOfStep(this.step)+1;
  }

  pageIn() {
    super.pageIn();

    if (this.expandedStateChangeCallback) {
      this._$toggle = $(`<div class="table-row-toggle">&#9660;</div>`)
        .click(() => this.expandedStateChangeCallback(!this.expanded))
        .appendTo(this._$dom);

      this._$dom.addClass('has-toggle');
      if (this.expanded) {
        this._$toggle.addClass('expanded');
        this._$dom.addClass('table-row-expanded');
        this._$rightDom.addClass('table-row-expanded');
      }
    }

    this._$contents = $(`<div class="table-row-contents">`).appendTo(this._$dom);
    this._$contents.append($(`<div class="table-row-loading">Loading...</div>`));

    this.attachDragEvents(this._$contents, 'isDragging');
    this.attachDragEvents(this._$rightDom, 'isDraggingRight');

    this.updateDom();
  }

  createDropTarget() {
    if (!this._$dropTarget && (!this.step || this.expandedStateChangeCallback)) {
      this._$dropTarget = $(`<div class="table-row-drop-target">`).appendTo(this._$contents);
      this._$dropTargetRight = $(`<div class="table-row-drop-target">`).appendTo(this._$rightDom);
    }
  }
  removeDropTarget() {
    if (this._$dropTarget && !this.isDragging && !this.isDraggingRight) {
      this._$dropTarget.remove();
      this._$dropTargetRight.remove();
      this._$dropTarget = null;
      this._$dropTargetRight = null;
    }
  }

  updateDom() {
    if (this._$dom) {
      this.fetchData().then(data => {
        if (!data) {
          return;
        }
        this._fetchedData = data;
        if (this._$dom) {

          if (this.step) {
            this._$contents.text('').append(this.step.column.createCell(data, 0, true, this.filterContext, this));
          }
          else {
            // TODO: this doesn't belong here
            this._$contents.text('').append($(`<div class="cell">Totals</div>`));
          }

          this._$rightDom.empty();
          this._aggregationsRenderToken = this.aggregationsConfig().render(this._$rightDom, data, this.filterContext, this);
        }
      });
    }
  }

  refresh() {
    let oldHash;
    if (this.expanded) {
      oldHash = this.hashFromData(this._fetchedData);
    }
    this.aggregationsConfig().destroy(this._aggregationsRenderToken);
    this.data = null;
    this.updateDom();
    if (this.expanded) {
      this.hash().then(newHash => {
        if (newHash !== oldHash) {
          this.setExpanded(false);
          this.setExpanded(true); // rebuild child...
        }
      });
    }
  }

  pageOut() {
    super.pageOut();
    this.aggregationsConfig().destroy(this._aggregationsRenderToken);
    this._$toggle = null;
    this._aggregationMap = null;
  }

  getHeight() {
    return Settings.ROW_HEIGHT + ((this.isDragging||this.isDraggingRight) && (!this.step || this.expandedStateChangeCallback) ? Settings.ROW_HEIGHT : 0);
  }
}

TableRow.__ID_COUNTER = 0;

module.exports = TableRow;
