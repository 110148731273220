const DragHelperWidth = 100;

const DragHelper = {


  doNSResizeHelper: (startEvent, allowedTop, allowedBottom, cb, doneCb) => {

    startEvent.preventDefault();
    startEvent.stopPropagation();

    let grip = $(`<div>`).appendTo(document.body);
    let startY = startEvent.clientY;
    grip.css({
      position: 'absolute',
      height: DragHelperWidth,
      left: 0,
      width: '100vw',
      background: 'rgba(128,128,128,0)',
      'z-index': 20000,
      cursor: 'ns-resize'
    });

    let delta = 0, lastDeltaUpdate = 0;

    $(document.body).on('mouseup.doNSResizeHelper', () => {
      grip.remove();
      $(document.body).off('mouseup.doNSResizeHelper');
      $(document.body).off('mousemove.doNSResizeHelper');
      cb(delta);
      doneCb && doneCb();
    })

    $(document.body).on('mousemove.doNSResizeHelper', evt => {
      grip.css('top', evt.clientY - DragHelperWidth/2);
      delta = evt.clientY - startY;
      if (delta > allowedBottom) {
        delta = allowedBottom;
      }
      if (-delta > allowedTop) {
        delta = -allowedTop;
      }

      if (lastDeltaUpdate < new Date().getTime() - 20) {
        cb(delta);
        
        // in case event loop gets slow, don't handle updates until Xms after event loop kicks off again
        setTimeout(() => {
          lastDeltaUpdate = new Date().getTime();
        }, 1);
      }
      evt.stopPropagation();
      evt.preventDefault();
    })

  },

  doEWResizeHelper: (startEvent, allowedLeft, allowedRight, cb, doneCb) => {

    startEvent.preventDefault();
    startEvent.stopPropagation();

    let grip = $(`<div>`).appendTo(document.body);
    let startX = startEvent.clientX;
    grip.css({
      position: 'absolute',
      left: startEvent.clientX - DragHelperWidth/2,
      width: DragHelperWidth,
      top: 0,
      height: '100vh',
      background: 'rgba(128,128,128,0)',
      'z-index': 20000,
      cursor: 'ew-resize'
    });

    let delta = 0, lastDeltaUpdate = 0;

    $(document.body).on('mouseup.doEWResizeHelper', () => {
      grip.remove();
      $(document.body).off('mouseup.doEWResizeHelper');
      $(document.body).off('mousemove.doEWResizeHelper');
      cb(delta);
      doneCb && doneCb();
    })

    $(document.body).on('mousemove.doEWResizeHelper', evt => {
      grip.css('left', evt.clientX - DragHelperWidth/2);
      delta = evt.clientX - startX;
      if (delta > allowedRight) {
        delta = allowedRight;
      }
      if (-delta > allowedLeft) {
        delta = -allowedLeft;
      }

      if (lastDeltaUpdate < new Date().getTime() - 20) {
        cb(delta);
        
        // in case event loop gets slow, don't handle updates until Xms after event loop kicks off again
        setTimeout(() => {
          lastDeltaUpdate = new Date().getTime();
        }, 1);
      }
      evt.stopPropagation();
      evt.preventDefault();
    })

  }
}

module.exports = DragHelper;