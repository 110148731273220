/*

Sample Inputs:

{
  columns: ["Column A", "Column B", "Column C"],
  rows: [
    [1,2,"Some string"],
    [3,4,"Some string2"]
  ]
}

{
  columns: ["Column A", "Column B", "Column C"],
  groups: ["Group 1", "Group 2"],
  columnValues: [10,20,"Thing"],
  rows: [
    {
      value: "Some Group 1 Name", 
      columnValues: [1,2,"Some string"],
      rows: [
        {
          value: "Some group 2 name",
          columnValues: [3,4,"Some string 2"],
          rows: [
            [1,2,"Some string"],
            [3,4,"Some string2"]
          ]
        },
        {
          value: "Some other group 2 name",
          columnValues: [3,4,"Some string 2"],
          rows: [
            [1,2,"Some string"],
            [3,4,"Some string2"]
          ]
        }
      ]
    },
    {
      value: "Some other Group 2 Name"
      rows: [
        ...
      ]
    }
  ]
}

*/

const DataProvider = require('./DataProvider');
const StepDefinitionAggregation = require('./StepDefinition/StepDefinitionAggregation');
const Column = require('./Column/Column');
const AggregationsConfig = require('./Column/AggregationsConfig');

class StaticDataProvider extends DataProvider {
  
  constructor(data) {
    super();
    if (data) {
      this.setData(data);
    }
  }

  toColumn(c, options) {
    if (typeof c === 'string') {
      return new Column(c, options);
    }
    else {
      return new Column(c.title, Object.assign({}, options, c));
    }
  }

  setData(data) {
    this.root = data;
    this.columns = data.columns.slice(1).map(column => this.toColumn(column, {
      format: 'text',
      capabilities: ['aggregation','label']
    }));
    this.groupingColumns = [].concat(data.groups, data.columns[0]).filter(a=>a).map(column => this.toColumn(column, {
      format: 'text',
      capabilities: ['grouping']
    }));
    this.aggregationsConfig = new AggregationsConfig(this.columns);
    this.steps = this.groupingColumns.map(groupingColumn => new StepDefinitionAggregation(groupingColumn, this.aggregationsConfig));
    this.steps[0].noSummary = true;
  }

  totals(aggregationsConfig, filterContext) {
    let rootColumnValues = this.root.columnValues || [];
    return Promise.resolve({
      aggregations: this.columns.reduce((acc, c, i) => {
        acc[c.key] = rootColumnValues[i];
        return acc;
      }, {}),
      filterContext: {
        indices: [],
        hash: 'root'
      }
    });
  }

  rowCount(stepDefinition, filterContext) {
    return Promise.resolve(this._findNode(filterContext).rows.length);
  }

  rowData(stepDefinition, filterContext, index) {
    let node = this._findNode(filterContext);
    let row = node.rows[index];
    let indices = (filterContext.indices || []).concat(index);
    if (Array.isArray(row)) {
      return Promise.resolve({
        [stepDefinition && stepDefinition.column.key]: row[0],
        aggregations: this.columns.reduce((acc, c, i) => {
          acc[c.key] = row[i + 1];
          return acc;
        }, {}),
        filterContext: {
          indices,
          hash: String(index)
        }
      });
    }
    else {
      return Promise.resolve({
        [stepDefinition.column.key]: row.value,
        aggregations: row.columnValues && this.columns.reduce((acc,c,i) => {
          acc[c.key] = row.columnValues[i];
          return acc
        }, {}) || {},
        filterContext: {
          indices,
          hash: 'h' + indices.join('-')
        }
      });
    }
  }

  _findNode(filterContext) {
    let node = this.root;
    let indices = filterContext.indices;
    if (indices) {
      for (var i = 0; i < indices.length; i++) {
        node = node.rows[indices[i]];
      }
    }
    return node;
  }

  groupingChartData(grouping, aggregationColumn, filterContext, chartType) {return {}}
  scatterChartData(dimensionColumn1, dimensionColumn2, filterContext) {return {}}
}

module.exports = StaticDataProvider;