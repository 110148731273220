angular.module('flashlightApp').controller('ResetPasswordController', ["$rootScope", "$scope", "$state", "$http", "$timeout", function($rootScope, $scope, $state, $http, $timeout) {
  $timeout(()=> $('.auth input').on('input', ()=> $scope.errorMessage = ''));
  $scope.form = {};
  $scope.resetPassword = function () {
    $scope.resetInProgress = true;
    return $http.post('/auth/reset_password', {username:$scope.form.username,token:$rootScope.token}).then(() => {
      $scope.resetInProgress = false;
      $state.go('root.reset-password-success');
    }, err => {
      $scope.resetInProgress = false;
      var xflash = err.data.xflash;
      if (xflash) $scope.errorMessage = Object.keys(xflash).map(d => xflash[d])[0];
      else $scope.errorMessage = err.data.message;
    });
  };
}]);
