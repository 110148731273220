
class ChartInput {
  constructor(id, name, requiredCapability, optional = false) {
    this.id = id;
    this.name = name;
    this.requiredCapability = requiredCapability;
    this.optional = optional;
  }
}

module.exports = ChartInput;