const COLOR_LOOKUPS = {};
const COLORS = ["196,76,102", "42,125,181", "196,158,106", "58,148,148", "118,78,196", "196,122,49", "128,158,175", "24,92,138", "137,172,106", "39,123,34", "193,118,118", "175,20,72", "195,147,85", "196,98,0", "115,167,165", "82,47,118", "196,196,118", "186,138,21"];

const ColorGenerator = {
  getColor: (column, value, opacity) => {
    opacity = opacity || 0.7;
    let region = column.key;
    let lookup = COLOR_LOOKUPS[region];
    if (!lookup) {
      lookup = {};
      COLOR_LOOKUPS[region] = lookup;
    }
    let color = lookup[value];
    if (!color) {
      color = COLORS[Object.keys(lookup).length % COLORS.length];
      lookup[value] = color;
    }
    return `rgba(${color}, ${opacity})`;
  }
}

module.exports = ColorGenerator;