module.exports = {
  CAPABILITIES_GROUPING: 'grouping',
  CAPABILITIES_LABEL: 'label',
  CAPABILITIES_NUMBER_LABEL: 'number_label',
  CAPABILITIES_AGGREGATION: 'aggregation',
  CAPABILITIES_NUMBER_AGGREGATION: 'number_aggregation',
  CAPABILITIES_NORMALIZED_AGGREGATION: 'normalized_aggregation',
  CAPABILITIES_SUMMING_AGGREGATION: 'summing_aggregation',
  CAPABILITIES_TIME_SERIES: 'time_series',
  CAPABILITIES_TIME_INTERVAL: 'time_interval'
};
