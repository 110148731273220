const TableElement = require('./TableElement');
const EXPANDED_STATE_KEY = 'exp';
const EXPANDED_HEIGHT_STATE_KEY = 'exph';

const PADDING_BELOW_EXPANDED = 15, THRESHOLD_PERCENT = 0.15;

class ExpandableElement extends TableElement {

  constructor(grid, rootElement, isLastElement, startExpanded) {
    super(grid);

    this.rootElement = rootElement;
    this.rootElement.setExpandedStateChangeCallback(expanded => this.setExpanded(expanded, true));

    this.isLastElement = isLastElement;
    this.expanded = false;
    this.expandedElement = null;
    this.fastHash = this.rootElement.fastHash();
  
    let savedState = this.grid.getState(EXPANDED_STATE_KEY, this.fastHash);
    if (!savedState) {
      savedState = false;
    }

    this.refreshExpandedStateFromLongHash(startExpanded);

    if (savedState) {
      this.expanded = true;
      this.rootElement.setExpanded(true);
      this.createExpandedElement();
    }

    if (startExpanded) {
      this.setExpanded(true);
    }
  }

  refreshExpandedStateFromLongHash(startExpanded) {
    this.rootElement.hash().then(hash => {
      let savedLongHashState = this.grid.getState(EXPANDED_STATE_KEY, hash);
      if (!savedLongHashState) {
        savedLongHashState = !!startExpanded;
      }

      if (savedLongHashState !== this.expanded) {
        this.expanded = savedLongHashState;
        this.rootElement.setExpanded(savedLongHashState);

        if (savedLongHashState) {
          this.createExpandedElement();
        }
        else {
          if (this.expandedElement) {
            this.expandedElement.executePageOut();
            this.expandedElement = null;
          }
        }
      }
    });
  }

  setElementAbove(element) {
    super.setElementAbove(element);
    this.rootElement.setElementAbove(element);
  }

  viewportChanged() {
    if (this.rootElement) {
      this.rootElement.viewportChanged();
    }

    if (this.expandedElement) {
      this.expandedElement.viewportChanged();
    }
    
    super.viewportChanged();
  }

  pageIn() {
    if (this.rootElement) {
      this.rootElement.executePageIn();
    }
    if (this.expanded) {
      if (this.expandedElement) {
        this.expandedElement.executePageIn();
      }
      else {
        this.createExpandedElement();
      }
    }
  }

  refresh() {
    this.rootElement && this.rootElement.refresh();
    if (this.expandedElement) {
      this.expandedElement.refresh();
      this.createExpandedElement();
    }
    this.refreshExpandedStateFromLongHash(false);

  }

  createExpandedElement() {

    // TODO: right now this is the magic that causes structural changes to appear in the table...
    // this is obviously not sufficient.  i need more coffee.

    // TODO: put a placeholder loader expanded element here
    this.rootElement.getExpandedElement(this.expandedElement).then(expandedElement => {
      if (expandedElement === this.expandedElement) {
        return;
      }
      if (this.expandedElement) {
        this.expandedElement.executePageOut();
        this.expandedElement = null;
      }
        
      if (this.expanded && this.isPagedIn) {
        this.expandedElement = expandedElement;
        if (this.expandedElement) {
          this.expandedElement.setElementAbove(this.rootElement);
        }
        this.grid.layoutChanged();
      }
      else if (this._lastReportedHeight) {
        this.grid.layoutChanged();
      }
    });
  
  }

  setExpanded(expanded, isFromUser) {
    if (this.expanded === expanded) {
      return;
    }

    this.expanded = expanded;

    if (!this.expanded) {
      let wasStuck = this.rootElement.isStuck();
      this.rootElement.setExpanded(false);
      this.grid.setState(EXPANDED_STATE_KEY, this.fastHash, this.expanded);
      
      if (wasStuck) {
        this.grid.scrollTo(this.rootElement.getTop() - this.rootElement.getStickyOffsetTop() + this.rootElement.getHeight(), null, true);
      }

      if (this.expandedElement) {
        this.expandedElement.executePageOut();
        this.expandedElement = null;
      }
    }
    else {
      this.rootElement.setExpanded(true);
      this.grid.setState(EXPANDED_STATE_KEY, this.fastHash, this.expanded);
      this.createExpandedElement();
    }
    
    this.rootElement.hash().then(hash => this.grid.setState(EXPANDED_STATE_KEY, hash, expanded));
    this.grid.layoutChanged();

    let thresholdHeight = ((this.grid.viewport.bottom - this.grid.viewport.top) * THRESHOLD_PERCENT);
    if (expanded && isFromUser && this.rootElement.getTop() > this.grid.viewport.bottom - thresholdHeight) {
      this.grid.smoothScrollTo(this.grid.viewport.top + thresholdHeight * 2);
    }
  }

  pageOut() {
    if (this.expandedElement) {
      this.expandedElement.executePageOut();
    }
    if (this.rootElement) {
      this.rootElement.executePageOut();
    }
  }

  layoutChanged() {
    super.layoutChanged();

    if (this.rootElement) {
      this.rootElement.layoutChanged();
    }
    if (this.expandedElement) {
      this.expandedElement.layoutChanged();
      this.rootElement.setStickyHeight(() => this.getHeight() - (!this.isLastElement ? PADDING_BELOW_EXPANDED : 0));
      this.rootElement.setStickyHeightBottom(() => {
        if (!this.expandedElement) {
          return 0;
        }
        return this.expandedElement.getStickyOffsetBottom();
      });
    }
    else {
      this.rootElement.setStickyHeight(null);
      this.rootElement.setStickyHeightBottom(null);
    }
  }

  getStickyOffsetBottom() {
    if (this.expanded && this.expandedElement) {
      return this.expandedElement.getStickyOffsetBottom() + this.rootElement.getStickyOffsetBottom();
    }
    return this.rootElement.getStickyOffsetBottom();
  }

  getHeight() {
    let rootHeight = this.rootElement.getHeight(),
        height = rootHeight,
        cameFromState = false;

    if (this.expanded) {
      if (this.expandedElement) {
        // expandedElement.getHeight() will call into bushyrowlist which will call to getHeight,
        // which will include the spacer... blah
        height = rootHeight + this.expandedElement.getHeight()
      }
      else if (this.fastHash) {
        height = this.grid.getState(EXPANDED_HEIGHT_STATE_KEY, this.fastHash) || rootHeight;
        cameFromState = true;
      }
    }
    
    if (this.fastHash) {
      if (height === rootHeight) {
        this.grid.setState(EXPANDED_HEIGHT_STATE_KEY, this.fastHash, null);
      }
      else if (!cameFromState) {
        this.grid.setState(EXPANDED_HEIGHT_STATE_KEY, this.fastHash, height);
      }

      this.grid.setState(EXPANDED_STATE_KEY, this.fastHash, this.expanded);
    }

    if (this.expanded) {
      if (this._lastReportedHeight && height !== this._lastReportedHeight) {
        this.grid.layoutChanged();
      }
      this._lastReportedHeight = height;
    }
    else {
      this._lastReportedHeight = null;
    }

    return height + ((!this.isLastElement && this.expanded) ? PADDING_BELOW_EXPANDED : 0);
  }
}

module.exports = ExpandableElement;