angular.module('flashlightApp').controller('SetPasswordController', ["$rootScope", "$scope", "$http", "$location", "$window", "$stateParams", "$timeout", "growl", "$state", function($rootScope, $scope, $http, $location, $window, $stateParams, $timeout, growl, $state) {
  $timeout(()=> $('.auth input').on('input', ()=> $scope.errorMessage = ''));
  $scope.form = { resetToken: $stateParams.resetToken, token: $rootScope.token };
  $scope.setPassword = function() {
    if ($scope.form.password !== $scope.form.password2) {
      growl.warning('Passwords must match.');
      $('input[type="password"]').eq(0).focus();
      return;
    }
    $scope.setInProgress = true;
    return $http.post('/auth/set_password', $scope.form).then(() => {
      // TODO: fix growl
      $window.location.pathname = '/?growl=Successfully set password&growlType=success';
    }, err => {
      if (err.data.message === 'INVALID_RESET_TOKEN') {
        growl.warning('Password already set or token has expired');
        $state.go('root.login');
        return;
      }
      $scope.setInProgress = false;
      var xflash = err.data.xflash;
      if (xflash) $scope.errorMessage = Object.keys(xflash).map(d => xflash[d])[0];
      else $scope.errorMessage = err.data.message;
    });
  };
}]);
