const Capabilities = require('../Capabilities');
const ChartFactory = require('./ChartFactory');
const ColorGenerator = require('../ColorGenerator');
const ChartInput = require('./ChartInput');
const ChartJSChart = require('./ChartJSChart');


const HISTORY_CHART_DATA = new ChartInput('time_series', 'Time Series', Capabilities.CAPABILITIES_TIME_SERIES);
const HISTORY_CHART_GROUPING_DATA = new ChartInput('grouping', 'Grouping Field', Capabilities.CAPABILITIES_GROUPING,true);
const HISTORY_CHART_TIME_INTERVAL = new ChartInput('time_interval', 'Time Interval', Capabilities.CAPABILITIES_TIME_INTERVAL);

class HistoryChart extends ChartJSChart {
  constructor(config) {
    super(config, [HISTORY_CHART_DATA, HISTORY_CHART_GROUPING_DATA, HISTORY_CHART_TIME_INTERVAL]);
  }

  title() {
    return 'History';
  }

  getConfigDescription(chartInput) {
    let result = this.getConfig(chartInput);
    if (result) {
      return result.title;
    }
  }

  render(container, dataProvider, filterContext, step, previousRender) {
    if (previousRender && previousRender._chart) {
      delete previousRender._chart;
    }
    return ChartJSChart.prototype.render.call(this, container, dataProvider, filterContext, step, previousRender);
  }

  optionsAndData(dataProvider, filterContext, step) {

    let dataColumn = this.getConfig(HISTORY_CHART_DATA);
    let groupingColumn = this.getConfig(HISTORY_CHART_GROUPING_DATA);
    let interval = this.getConfig(HISTORY_CHART_TIME_INTERVAL);

    if (!dataColumn) {
      return;
    }

    return dataProvider.historyChartData(dataColumn, filterContext, step.configId, groupingColumn, interval).then(({labels,datasets}) => {
      let options = {
        maintainAspectRatio: false,
        title: {
          display: true,
          text: dataColumn.title
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              return `${data.datasets[tooltipItem.datasetIndex].label}: ${dataColumn.formatValue(tooltipItem.yLabel)}`;
            }
          }
        },
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              parser: 'YYYY-MM-DD',
              unit: interval.title.toLowerCase()
            },
            scaleLabel: {
              display: false
            },
          }],
          yAxes: [{
            scaleLabel: {
              display: false,
              labelString: dataColumn.title
            },
            ticks: {
              callback: function (value) {
                return dataColumn.formatValue(value, true);
              }
            },
          }]
        }
      };
      if (groupingColumn) {
        options.legend = {
          display: true,
          labels: {
            filter: (item, data) => ! / Comparison$/.test(item.text),
            boxWidth: 12,
            fontSize: 11
          }
        };
      } else {
        options.legend = { display: false };
        options.scales.xAxes.ticks = { autoSkip: false };
      }

      return {
        data: {
          labels,
          datasets: datasets.map((d,i) => {
            let title, color;
            if (groupingColumn) {
              color = ColorGenerator.getColor(dataColumn, d.title, 0.9)
              title = groupingColumn.formatValue(d.title, true);
              if (d.comparison) {
                title += ` Comparison`;
              }
              if (i === datasets.length - 1) {
                title += ` - ${groupingColumn.title}`;
              }
            } else {
              if (i === 0) {
                color = 'rgba(0,110,218,1)';
              } else {
                color = 'rgba(255, 143, 15, 0.73)';
              }
              title = dataColumn.title;
              if (d.comparison) {
                title += ` Comparison`;
              }
            }
            let ds = {
              label: title,
              data: d.points.slice(),
              borderWidth: 1,
              backgroundColor: color,
              borderColor: color,
              fill: false
            };
            if (groupingColumn && d.comparison) {
              ds.borderDash = [5, 5];
            }
            return ds;
          })
        },
        options,
        type: 'line'
      };
    });
  }
}

ChartFactory.register('history', HistoryChart);