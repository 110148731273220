agGrid.initialiseAgGridWithAngular1(angular);
angular
  .module('flashlightApp', [
    'ngProgress',
    'ngAnimate',
    'ngclipboard',
    'ui.select',
    'ngSanitize',
    'ui.router',
    'ui.bootstrap',
    'ui.sortable',
    'angularFileUpload',
    'angular-growl',
    'agGrid',
    'angular-mousetrap',
    'angularUtils.directives.dirPagination',
    'schemaForm',
    'ngMaterial',
    'ngMessages',
    'ngRoute',
    'ngFilesizeFilter',
    'treeControl',
    
    'ui.bootstrap.contextMenu',
    'ordinal',
    'selectize'
  ]);

//
// It's useful for our tile service to know what directives have been registered.
// This builds a set of known directives.
//
const m = angular.module('flashlightApp');
const originalDirective = m.directive;
m.registeredDirectives = new Set();
m.directive = function(directiveName) {
  m.registeredDirectives.add(directiveName.toLowerCase());
  return originalDirective.apply(m, arguments);
};