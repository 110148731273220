angular.module('flashlightApp').controller('LoginController', ["$rootScope", "$scope", "$http", "$window", "$stateParams", "$timeout", "stateParams2QueryParams", "$state", "growl", "$location", function($rootScope, $scope, $http, $window, $stateParams, $timeout, stateParams2QueryParams, $state, growl, $location) {
  $timeout(()=> $('.auth input').on('input', ()=> $scope.errorMessage = ''));
  $scope.form = {};

  $scope.sendCode = (voice) => {
    $scope.submitInProgress = true;
    return $http.post('/auth/send_code', {user:$scope.form, token:$rootScope.token, voice:voice })
      .then(() => {
        $scope.submitInProgress = false;
        growl.success('An authorization code has been sent to your mobile device. Please enter it here.', {ttl:60000});
      }, err => {
        $scope.submitInProgress = false;
        var xflash = err.data.xflash;
        if (xflash) $scope.errorMessage = Object.keys(xflash).map(d => xflash[d])[0];
        else $scope.errorMessage = err.data.message;
      });
  };

  $scope.setAuth = function(authMethod, authenticationMethods) {
    if (authenticationMethods) {
      $scope.authMethod = authenticationMethods.pop(); // last item is duplicated - "lastUsedAuth"
      $scope.authenticationMethods = authenticationMethods.slice(); // save this clone for later
    } else { // changing auth method
      $scope.authMethod = authMethod;
    }
    $scope.mfaMap = $scope.authenticationMethods.reduce((o,d) => { o[d]=true; return o; }, {});
    $scope.mfa = $scope.authenticationMethods.filter(d => d !== $scope.authMethod);
    $timeout(() => $('input.auth-input')[0].focus(),1000);
  };

  $scope.login = function() {
    // identify with fullstory
    $scope.submitInProgress = true;
    $http.post('/auth/login', {user:$scope.form, token: $rootScope.token})
      .then(() => {
        // tell all other shared workers to close, so they reconnect and get the valid session
        try {
          $window.$sharedWorker.port.postMessage('close');
        }
        catch (e) { }

        afterLogin();
      }, err => {
        $scope.submitInProgress = false;
        if (err && err.data && err.data.message === 'CHECK_EMAIL_CERT_SENT') {
          $state.go('root.check-email-cert-sent');
          return;
        }
        if (err && err.data && String(err.data.message).indexOf('MFA_REQUIRED')>-1) {
          if ($scope.mfa)
            $scope.errorMessage = 'Invalid authentication code';
          var mfa = err.data.message.replace('MFA_REQUIRED_','').split('_');
          $scope.setAuth(null, mfa);
          return;
        }
        // no INVALID_TOKEN error visible to user
        if (err && err.data && err.data.message === 'INVALID_TOKEN') {
          return;
        }
        const xflash = err.data.xflash;
        if (xflash) $scope.errorMessage = Object.keys(xflash).map(d => xflash[d])[0];
        else $scope.errorMessage = err.data.message;
      });
  };

  function afterLogin() {
    if ($stateParams.dest) {
      var dest = decodeURIComponent($stateParams.dest);
      if (!/^\//.test(dest) && !/^localhost/.test(dest) &&
          !/^http:\/\/localhost/.test(dest) &&
          !(new RegExp('^'+$window.location.hostname)).test(dest) &&
          !(new RegExp('^https:\\/\\/'+$window.location.hostname)).test(dest)) {
        throw new Error('Not following external redirect: ' + dest);
      }
      $window.location.href = decodeURIComponent($stateParams.dest).replace('/#/', '/');
      if ($window.seleniumActive)
        $window.location.reload();
      return;
    }
    $window.location.pathname = stateParams2QueryParams('/navigator/view',$stateParams);
  }

  // watch for us getting logged in
  function checkForLogin() {
    if (localStorage.sessionExpires && new Date(localStorage.sessionExpires) - new Date() > 0) {
      $http.get('/api/rev')
        .then(afterLogin)
        .catch(() => setTimeout(checkForLogin, 5000));
    }
    else {
      setTimeout(checkForLogin, 5000);
    }
  }
  checkForLogin();
}]);
