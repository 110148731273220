const ContextMenu = require('../Config/ContextMenu');
const Capabilities = require('../Capabilities');
const AGGREGATIONS_CONFIG_KEY = 'AggregationsConfig';
const ChartFactory = require('../Charts/ChartFactory');

class AggregationsConfig {

  constructor(aggregations) {
    this._aggregations = aggregations;
    this.aggregationPositions = {};
    this._renderedResults = {};
    this._width = 0;
    this.resetAggregationPositions();
    }

  aggregations() {
    return this._aggregations;
  }

  size() {
    return this._aggregations.length;
  }

  hasFresherSort(timestamp) {
    return !!this._aggregations.find(aggregation => aggregation.sort && aggregation.sort.timestamp > timestamp);
  }

  insertColumn(column, front) {
    this._aggregations = this._aggregations.filter(agg => agg.id !== column.id);
    if (front) {
      this._aggregations.unshift(column);
    }
    else {
      this._aggregations.push(column);
    }
  }

  removeColumn(column) {
    this._aggregations = this._aggregations.filter(agg => agg.id !== column.id);
  }

  getAggregationsSet() {
    return new Set(this._aggregations.map(column => column.id));
  }

  serializeConfig() {
    return this._aggregations.map(a => {
      let result = { key: a.key };
      if (a.sort) {
        result.sort = a.sort;
      }
      return result;
    });
  }

  static deserializeConfig(grid, config) {
    let key = JSON.stringify(config);
    return grid.memoize(key, () => new AggregationsConfig(config.map(a => {
      let column = grid.findColumnByKey(a.key);
      if (!column) {
        return null;
      }
      if (a.sort) {
        column.sort = a.sort;
      }
      else {
        column.sort = null;
      }
      return column;
    }).filter(a => a)));
  }

  resetAggregationPositions(gapX, gapWidth) {
    this._aggregations.reduce((left, aggregation) => {

      if (gapX !== undefined) {
        if (left <= gapX && left + aggregation.width > gapX) {
          left += gapWidth || 150;
        }
      }

      if (this.aggregationPositions[aggregation.id] !== left) {
        this.aggregationPositions[aggregation.id] = left;
        Object.keys(this._renderedResults).map(key => {
          let $element = this._renderedResults[key][aggregation.id];
          if ($element) {
            aggregation.styleCell($element, left)
          }
        });
      }
      return left + aggregation.width;
    }, 20);
    this._width = this._aggregations.reduce((size, aggregation) => size + aggregation.width, 0);
  }

  width() {
    return this._width;
  }

  render($target, rowData, filterContext, tableRow) {
    let renderResult = {}, renderResultId = ++AggregationsConfig.__RENDER_ID;

    this._renderedResults[renderResultId] = renderResult;

    this._aggregations.forEach(aggregation => {
      let $element = aggregation.createCell(rowData.aggregations, this.aggregationPositions[aggregation.id], false, filterContext, tableRow, rowData.count);
      renderResult[aggregation.id] = $element;
      $target.append($element);
    });

    return renderResultId;
  }

  renderHeaders(grid, $target) {
    $target = $(`<div class='header-wrapper'>`).appendTo($target);

    let renderResult = {}, renderResultId = ++AggregationsConfig.__RENDER_ID, lastSort = null;

    this._renderedResults[renderResultId] = renderResult;

    this._aggregations.forEach(aggregation => {
      let $element = aggregation.createHeader($target, this.aggregationPositions[aggregation.id], this);
      $element.on('click', () => {
        if (!(aggregation.options && aggregation.options.hideSorts)) {
          aggregation.sort = {
            direction: aggregation.sort ? -1 * aggregation.sort.direction : 1,
            timestamp: new Date().getTime()
          };
          grid.refresh();
        }
      });

      if (aggregation.sort && (!lastSort || aggregation.sort.timestamp > lastSort.sort.timestamp)) {
        lastSort = aggregation;
      }

      this.attachDragEvents(grid, $element, aggregation);
      renderResult[aggregation.id] = $element;
    });
    
    if (lastSort) {
      if (lastSort.sort.direction > 0) {
        renderResult[lastSort.id].addClass('sort-down');
      }
      else {
        renderResult[lastSort.id].addClass('sort-up'); 
      }
    }

    $target.on('drop', event => {
      event.preventDefault();

      let id = parseInt(event.originalEvent.dataTransfer.getData('columnid'));
      if (!id) {
        return;
      }

      this._aggregations = this._aggregations.filter(agg => agg.id !== id);
      this._aggregations.push(grid.findColumn(id));

      this.aggregationPositions[id] = event.clientX - $target.offset().left;
      this._aggregations.sort((a,b) => this.aggregationPositions[a.id] - this.aggregationPositions[b.id]);

      this.resetAggregationPositions();
      grid.setDragging(AGGREGATIONS_CONFIG_KEY, false);
      grid.refresh();
    });

    $target.on('dragenter', event => event.preventDefault());

    $target.on('dragover', event => {
      if (event.originalEvent.dataTransfer.types.indexOf('columnid') < 0) {
        event.originalEvent.dataTransfer.dropEffect = "none";
        return;
      }

      if (!grid.checkDragCapability(event, Capabilities.CAPABILITIES_LABEL)) {
        return;
      }
      
      let id = grid.dragColumnHintId();
      
      event.preventDefault();
      event.stopPropagation();
      event.originalEvent.dataTransfer.dropEffect = "move";

      let position = event.clientX - $target.offset().left;
      if (id) {
        this.aggregationPositions[id] = position;
      }
      this.resetAggregationPositions(position, grid.dragColumnHintWidth());
      grid.setDragging(AGGREGATIONS_CONFIG_KEY, true);
      
      return true;
    });

    $target.on('dragleave', event => {
      
      let offset = $target.offset(),
          width = $target.outerWidth(),
          height = $target.outerHeight();

      if (event.clientX <= offset.left || event.clientX >= offset.left + width||
          event.clientY <= offset.top || event.clientY >= offset.top + height) {

        let id = grid.dragColumnHintId();
        if (id) {
          this.aggregationPositions[id] = -1;
        }
        this.resetAggregationPositions();
        grid.setDragging(AGGREGATIONS_CONFIG_KEY, false);
      }
    
    });

    $(document.body).off('dragenter.aggregations-header').on('dragenter.aggregations-header', event => event.preventDefault());
    $(document.body).off('dragover.aggregations-header').on('dragover.aggregations-header', event => {
      let id = grid.dragColumnHintId();
      if (id) {
        this.aggregationPositions[id] = -1;
      }
      this.resetAggregationPositions();
      
      event.preventDefault();
      event.originalEvent.dataTransfer.dropEffect = "none";
      return false;
    });

    return renderResultId;
  }



  attachDragEvents(grid, $element, aggregation) {
    let originalLeft = null, originalOffsetX = 0, allAggregationElements = null;

    $element.on('dragstart', event => {
      grid.dragColumnHintId(aggregation.id);
      grid.dragColumnHintWidth(aggregation.width);

      this._aggregations = this._aggregations.filter(agg => agg.id !== aggregation.id);
      event.originalEvent.dataTransfer.setData('columnid', aggregation.id);

      // // make sure we don't get the header twice - we are actually moving this guy
      var i = new Image(1,1);
      i.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z8BQDwAEhQGAhKmMIQAAAABJRU5ErkJggg==";
      event.originalEvent.dataTransfer.setDragImage(i, 0, 0);
      

      originalLeft = this.aggregationPositions[aggregation.id];   // eslint-disable-line no-unused-vars
      originalOffsetX = event.offsetX;// - originalLeft;
      allAggregationElements = Object.keys(this._renderedResults).map(key => this._renderedResults[key][aggregation.id]).filter(a => a);
      allAggregationElements.forEach(it => it.addClass('dragging'));
      grid.setDragging('AGGREGATION', true);
    });

    $element.on('dragend', event => {
      allAggregationElements.forEach(it => it.removeClass('dragging'));
      this.resetAggregationPositions();
      grid.refresh();
      grid.setDragging('AGGREGATION', false);
    });

    $element.on('drag', event => {
      if (event.originalEvent.x === 0) {
        return;
      }

      if (this.aggregationPositions[aggregation.id] < 0) {
        // allAggregationElements.forEach(it => it.hide());
        allAggregationElements.forEach(it => aggregation.styleCell(it, 50000));
        return;
      }

      // allAggregationElements.forEach(it => aggregation.styleCell(it.show(), this.aggregationPositions[aggregation.id] - originalOffsetX));
      allAggregationElements.forEach(it => aggregation.styleCell(it, this.aggregationPositions[aggregation.id] - originalOffsetX));
    });

    const aggregationIndex = this._aggregations.findIndex(a => a.id === aggregation.id);
    ContextMenu.attachToElement($element, () => ([
      {
        label: 'Remove',
        action: () => {
          this._aggregations = this._aggregations.filter(agg => agg.id !== aggregation.id);
          grid.refresh();
        } 
      },
      (this._aggregations.length > aggregationIndex + 1) ? {
        label: 'Remove All To Right',
        action: () => {
          this._aggregations = this._aggregations.slice(0, aggregationIndex + 1);
          grid.refresh();
        }
      } : null,
      !(aggregation.options && aggregation.options.hideSorts) ? {
        label: 'Sort Ascending',
        action: () => {
          aggregation.sort = {
            direction: -1,
            timestamp: new Date().getTime()
          };
          grid.refresh();
        }
      } : null,
      !(aggregation.options && aggregation.options.hideSorts) ? {
        label: 'Sort Descending',
        action: () => {
          aggregation.sort = {
            direction: 1,
            timestamp: new Date().getTime()
          };
          grid.refresh();
        }
      } : null,
      ... ((aggregation.options && aggregation.options.actions) ? aggregation.options.actions() : []),
      {
        label: 'Add Chart',
        action: ChartFactory.availableChartTypesForSingleInput(grid.availableCharts(), aggregation.capabilities).map(availableChart => ({
          label: availableChart.label,
          action: () => {
            const StepDefinitionChart = require('../StepDefinition/StepDefinitionChart');
            let newStep = new StepDefinitionChart(availableChart.key);
            let chartInputToSet = newStep.chart().chartInputs.find(chartInput => aggregation.capabilities.has(chartInput.requiredCapability));
            if (chartInputToSet) {
              newStep.chart().setConfig(chartInputToSet, aggregation);
            }

            let newSteps = [newStep].concat(grid.stepDefinitions);

            grid.setStepDefinitions(newSteps);
            grid.refresh();
            grid.afterAddStepDefinition(newStep);
          }
        }))
      }
    ].filter(a => a && (!Array.isArray(a.action) || a.action.length > 0))));

  }

  destroy(token) {
    delete this._renderedResults[token];
  }

}

AggregationsConfig.__RENDER_ID = 0;

module.exports = AggregationsConfig;