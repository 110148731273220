const Settings = require('../Settings');
const AggregationColumnEditor = require('../Config/AggregationColumnEditor');
const StickyRow = require('./StickyRow');
const TotalsElement = require('./TotalsElement');
const ExpandableElement = require('./ExpandableElement');

class HeaderElement extends StickyRow {

  constructor(grid) {
    super(grid);
    this._height = 0;
  }

  setExpandedStateChangeCallback(expandedStateChangeCallback) {
    this.expandedStateChangeCallback = expandedStateChangeCallback;
  }

  hash() {
    return Promise.resolve(123);
  }
  fastHash() {
    return 'header_fast_hash';
  }

  setExpanded(isExpanded) {
    // ignore
  }

  elementClassNames() {
    return `table-header`;
  }

  shouldStick() {
    return true;
  }

  zIndex() {
    return 1000;
  }

  getExpandedElement(currentElement) {
    if (currentElement) {
      return Promise.resolve(currentElement);
    }
    let firstAggregationStep = this.grid.stepDefinitions.find(step => step.aggregationsConfig);
    if (!firstAggregationStep) {
      return Promise.resolve();
    }

    if (firstAggregationStep.noSummary) {
      return Promise.resolve(firstAggregationStep.createTableElement(this.grid, {}));
    }

    let totals = new TotalsElement(this.grid, firstAggregationStep.aggregationsConfig, {});
    let expandableTotals = new ExpandableElement(this.grid, totals, true, true);
    return Promise.resolve(expandableTotals);
  }

  layoutChanged() {
    super.layoutChanged();

    if (this._$rightDom) {
      let cells = this._$rightDom.find('.cell').css('padding-top', 5);
      let HEADER_HEIGHT = 27;
      let rows = 1;
      
      let baseHeight = Math.max(rows * HEADER_HEIGHT + (Settings.ROW_HEIGHT - HEADER_HEIGHT), Settings.ROW_HEIGHT)
      let newHeight = cells.css('height','auto').toArray().reduce((maxHeight, cell) => Math.max($(cell).outerHeight(), maxHeight), baseHeight);
      if (newHeight !== this._height) {
        this._height = newHeight;
        this.grid.layoutChanged();
      }

      cells.toArray().forEach(cell => {
        let cellOuterHeight = $(cell).outerHeight();
        if (cellOuterHeight < newHeight) {
          $(cell).css('padding-top', newHeight - cellOuterHeight + 5);
        }
      });

      this._$rightDom.css('height', newHeight);
      this._$dom.css({
        'height': newHeight,
        'paddingTop': newHeight - baseHeight + 9
      });
      this._$addAggregation.css({
        'height': newHeight
      });
      this._$leftAddAggregation.css({
        'height': newHeight,
        'left': this.grid._lastLeftSize
      });
    }
  }

  
  leftSize() {
    return 0;
  }

  minLeftSize() {
    return 0;
  }

  aggregationsConfig() {
    let step = this.grid.stepDefinitions.find(step => step.aggregationsConfig);
    return step && step.aggregationsConfig;
  }

  refresh() {
    super.refresh();
    let aggregations = this.aggregationsConfig();
    if (aggregations) {
      aggregations.destroy(this._aggregationsRenderToken);
    }
    this.setupDom();
  }

  pageIn() {
    super.pageIn();
    if (!this._$addAggregation) {
      this._$addAggregation = $(`<div class="add-aggregation"><i>+</i></div>`).appendTo(this.grid.fullPane.stickyContainer);

      this._$addAggregation.on('click', () => {
        new AggregationColumnEditor(this.grid, this._$addAggregation, null, resultingColumn => {
          this.grid.insertAggregationColumn(resultingColumn);
        }, true, resultingColumn => {
          this.grid.removeAggregationColumn(resultingColumn)
        }, () => this.grid.getAggregationsSet());
      });

      this._$leftAddAggregation = $(`<div class="add-aggregation left-add-aggregation"><i>+</i></div>`).appendTo(this.grid.fullPane.stickyContainer);

      this._$leftAddAggregation.on('click', () => {
        new AggregationColumnEditor(this.grid, this._$leftAddAggregation, null, resultingColumn => {
          this.grid.insertAggregationColumn(resultingColumn, true);
        }, true, resultingColumn => {
          this.grid.removeAggregationColumn(resultingColumn)
        }, () => this.grid.getAggregationsSet());
      });
    }
    this.setupDom();
  }

  setupDom() {
    this._$rightDom.empty();
    let aggregations = this.aggregationsConfig();
    if (aggregations) {
      aggregations.resetAggregationPositions();
      this._aggregationsRenderToken = aggregations.renderHeaders(this.grid, this._$rightDom);
    }

    this.layoutChanged();
  }

  pageOut() {
    super.pageOut();
    if (this._$addAggregation) {
      this._$addAggregation.remove();
      this._$addAggregation = null;
      this._$leftAddAggregation.remove();
      this._$leftAddAggregation = null;
    }
    let aggregations = this.aggregationsConfig();
    if (aggregations) {
      aggregations.destroy(this._aggregationsRenderToken);
    }
  }

  getHeight() {
    return Math.max(Settings.ROW_HEIGHT, this._height);
  }
}

module.exports = HeaderElement;