const ProblemMessageLimit = 4096;

const GirdGrid = require('utilities/girdgrid');

angular.module('flashlightApp').controller('RootController', ["$stateParams", "$rootScope", "customer", "$log", "$http", "$state", "growl", "$window", "$location", "Mousetrap", "token", "$timeout", "$scope", "$compile", function($stateParams, $rootScope, customer, $log, $http, $state, growl, $window, $location, Mousetrap, token, $timeout, $scope, $compile) {
  if ($stateParams.landscape)
    $window.document.querySelector('html').classList.add('landscape');
  $rootScope.appTitle = $rootScope.appTitle || 'TheNumber';
  $rootScope.certDisabled = customer && customer.cert_disabled && !customer.cert_disabled.error;
  $rootScope.org = customer && customer.org;
  $rootScope.hasExecutiveSummary = customer && customer['report.data.has_executive_summary'];
  $rootScope.orgsArray = customer && customer.orgs;
  $rootScope.orgs = customer && customer.orgs && !customer.orgs.error && customer.orgs.reduce((o,d) => {o[d.key]=d;return o;},{});
  $rootScope.orgsById = customer && customer.orgs && !customer.orgs.error && customer.orgs.reduce((o,d) => {o[d._id]=d;return o;},{});
  $rootScope.primaryOrg = customer && customer.orgs && !customer.orgs.error && customer.orgs.filter(d => !d.demo)[0];
  $rootScope.users_handles = customer && customer['users.handles'] || [];
  $rootScope.user = customer && customer.user;
  $rootScope.superuser = customer && customer.user && customer.user.superuser;
  $rootScope.admin = customer && customer.user && customer.user.admin;
  $rootScope.sales = customer && customer.user && customer.user.sales;
  $rootScope.token = token;
  $rootScope.rev = customer && customer.rev;
  $rootScope.portfolioCount = customer && customer.portfolio_count;
  $rootScope.showBetaBanner = customer && customer.user && !customer.user.showErrors && !customer.user.superuser;

  // org param control
  if (!$stateParams.org && customer && customer.org)
    $timeout(() => $state.go('.', { org: customer.org.key }, { notify: false }));
  $rootScope.$stateParams = $stateParams;
  var orgChange;
  $rootScope.$watchCollection('$stateParams', (newVal) => {
    if (orgChange && orgChange !== newVal.org)
      $window.location.reload();
    orgChange = newVal.org;
  });

  // LOL vmRoot
  $window.vmRoot = this;
  vmRoot.customer = $rootScope.customer = customer;
  vmRoot.rev = customer && customer.rev;
  vmRoot.branchname = customer && customer.branchname;
  vmRoot.version = customer && customer.version;
  vmRoot.year = new Date().getFullYear();
  var ref = String(customer&&customer.user&&customer.user.username||'').toLowerCase();
  var org = customer&&customer.org&&customer.org.key||'';
  _paq.push(['setUserId', ref]);
  _paq.push(['setCustomVariable', 1, 'Org', org, 'visit']);
  _paq.push(['trackPageView']);

  if (window.Raven && $rootScope.user && $rootScope.user.username) {
    window.Raven.setUserContext({
      email: ref
    });
  }

  if ($rootScope.org && !$rootScope.org.showSCurveCharts) {
    GirdGrid.ChartFactory.register('sCurve', function() { this.chartInputs = [{ requiredCapability: 'doesnotexist' }]; }); 
  }

  Mousetrap.bind(['ctrl+c','command+c','ctrl+e','command+e'], function() {
    if ($state.current.name !== 'root.analytics.table') return;
    var text = "";
    if (typeof window.getSelection !== undefined)
      text = window.getSelection().toString();
    else if (typeof document.selection !== undefined && document.selection.type === "Text")
      text = document.selection.createRange().text;
    if (!text) {
      $('.clip')
        .attr('data-clipboard-text', vmRoot.copy)
        .click();
      growl.success('Copied to clipboard');
    }
  });

  if ($state.current.data && $state.current.data.growl) {
    const msg = $state.current.data.growl;
    const msgType = $state.current.data.growlType;
    $state.current.data.growl = null;
    $state.current.data.growlType = null;
    $timeout(() => {
      growl[msgType](msg,{ttl:5000});
    }, 100);
  }
  if ($stateParams.growlCode) {
    if (String($stateParams.growlCode) === '2')
      growl.error('The content you tried to view is inaccessible. Please contact help@thenumber.com if you believe this is an error. Otherwise, please reset your bookmark to be '+$window.location.hostname, { ttl: -1 });
  }

  vmRoot.logReptiles = function (key, reptileData) {
    console.log('{ ' + key + ' : ' + reptileData + ' }');
  };
  vmRoot.prototypes = [];

  // not logged in
  if (!customer && $rootScope.authRoutes.indexOf($state.current.name) <= -1) {
    let destQuery;
    if (!$rootScope.originalLocationHash || $rootScope.originalLocationHash === '#/') {
      destQuery = '';
    } else {
      const dest = encodeURIComponent($rootScope.originalLocationHref);
      let url = new URL($rootScope.originalLocationHref.replace(/\/#\//g, '/'));
      url.searchParams.set('dest', dest);
      destQuery = '?' + url.searchParams.toString();
    }
    $window.location.href = '/login' + destQuery;
  // user in session no longer exists
  } else if (customer && customer.user && customer.user.error) {
    if ($window.location.hash.indexOf('#/login') !== 0)
      $window.location.href = '/login?growlCode=2';
    return;
  // inacessible org
  } else if (customer && customer.org && customer.org.error) {
    $window.location.href = '/navigator/view?growlCode=2';
    return;
  // inacessible org
  } else if (customer && customer.user && !customer.user.error && $stateParams.org && !$rootScope.orgs[$stateParams.org]) {
    $window.location.href = '/navigator/view?growlCode=2';
    return;
  } else { // logged in
    $window.document.title = $rootScope.appTitle;
    if ($state.current.name === 'root' && customer && customer.portfolio_list && customer.portfolio_list.length === 1)
      $state.go('root.analytics.table', { portfolio: customer.portfolio_list[0].key });
    else if ($state.current.name === 'root')
      $state.go('root.analytics.table');
  }
  
  $rootScope.reportProblem = function(tileName) {
    $scope.tileName = tileName;
    const modalEl = require('../../../app/states/analytics/defaultRecord/summary/problemForm.pug');
    $scope.issueModal = $compile(modalEl())($scope);
    $scope.issueModal.modal("show");
  };

  $rootScope.sendProblem = function(tileName) {
    $scope.transmitting = true;
    if (!this.issue || this.issue.trim() === '') {
      $scope.issueModal.modal('hide');
      return;
    }
    let comment, api;
    let text = this.issue.trim().substring(0,ProblemMessageLimit);
    if ($stateParams.key && $stateParams.portfolio) {
      comment = `@thenumber There is a problem with the data on ${$rootScope.selectedTab ? $rootScope.selectedTab : ''} "${tileName}": ${text}`;
      api = '/api/userdata/comments';
    } else {
      comment = `@thenumber An issue has been reported: ${text}`;
      api = '/api/reportIssue';
    }
    let newCommentForm = { token: $rootScope.token, comment: comment, url: $window.location.href };

    return $http.post(api, newCommentForm)
      .then(() => {
        $scope.issueModal.modal('hide');
        growl.success('Problem submitted');
        $rootScope.$emit('commentsChanged');
        $scope.transmitting = false;
      }, err => {
        console.log(err);
        growl.error(err.message);
        $scope.transmitting = false;
      });
  };

  $rootScope.logout = () => $http.get('/auth/logout').then(() => {
    localStorage.sessionExpires = null;
    $window.location.pathname = '/login';
  }, 
  (err) => {
    localStorage.sessionExpires = null;
    $window.location.pathname = '/login';
  });
}]);
