import {polyfill} from "mobile-drag-drop";
require("mobile-drag-drop/default.css");

angular.module('flashlightApp').run(['$rootScope', '$state', '$window', '$location', '$stateParams', 'growl', '$interval', '$http', '$timeout', 'Mousetrap', '$q', 'PortfolioProgressService', 'SearchSelectService', function($rootScope, $state, $window, $location, $stateParams, growl, $interval, $http, $timeout, Mousetrap, $q, PortfolioProgressService, SearchSelectService) {
  // escape also clicks body to close ui popups
  $window.document.onkeydown = function(evt) {
    evt = evt || $window.event;
    if (evt.keyCode === 27 || (evt.key === "Escape" || evt.key === "Esc"))
      $('body').click();
  };
  
  // growl for internet explorer
  var ua = $window.navigator && $window.navigator.userAgent;
  if (ua && (ua.indexOf('MSIE')>-1||ua.indexOf('Trident')>-1) && !$window.hasYelledAboutIE) {
    $window.hasYelledAboutIE = true;
    growl.error('Your browser’s rendering runtimes interfere with the non-blocking nature of this application. For optimal results consider using a modern browser, such as Chrome or Firefox.', { ttl: -1 });
  }
  $rootScope.originalLocationHash = $window.location.hash;
  $rootScope.originalLocationHref = $window.location.href;

  // inject styles with addStyleString()
  var styleNode = document.createElement('style');
  document.body.appendChild(styleNode);
  $window.addStyleString = function(str) {
    styleNode.innerHTML = str;
  };
  $rootScope.showFilterInScenarios = true;
  
  $rootScope.pendingRequests = {};

  // define app routes TODO: refactor this
  $rootScope.appRoutes = ['root','root.plugin-auth','root.settings','root.settings.auth','root.notifications', 'root.navigator.view','root.admin','root.admin.add-user','root.admin.bulk-add-users','root.admin.api-access','root.admin.security','root.admin.user-management','root.admin.add-org','root.admin.org-management', 'root.admin.premarket-edit', 'root.admin.data-enhancements','root.upload','root.pivot','root.pivot_promo','root.explorer'];
  // define routes that bypass authorization check
  $rootScope.authRoutes = ['root.login','root.logout','root.set-password','root.register','root.register-success','root.check-email-cert-sent','root.reset-password','root.set-passsword','root.verify','root.reset-password-success','root.premarket'];

  polyfill({
    holdToDrag: 400
  });

  $rootScope.$stateParams = $stateParams;
  $rootScope.$state = $state;
  $rootScope.$window = $window;
  $rootScope.$location = $location;
  $rootScope.moment = moment;
  $rootScope.theme = '';

  SearchSelectService.register('single_record', params => {
    $state.go('root.analytics.record.summary', params);
  });
  SearchSelectService.register('goto_custom_property', params => {
    $state.go("root.analytics.record.property", 
      {
        type:'custom', 
        customPrototype: encodeURIComponent(JSON.stringify({
              type: 'property',
              street_address: params.street_address,
              city: params.city,
              state: params.state,
              zip: params.zip
            }))
      });
  });
  SearchSelectService.register('goto_portfolio', params => {
    PortfolioProgressService.check();
    if ($state.params.preset) {
      delete params.filter;
      $state.go('root.pivot', params, { reload: true });
    } else {
      delete params.preset;
      $state.go('root.analytics.list', params);
    }
  });
  SearchSelectService.register('show_bond', params => {
    PortfolioProgressService.check();
    if ($state.params.preset) {
      delete params.filter;
      $state.go('root.pivot', params, { reload: true });
    } else {
      delete params.preset;
      $state.go('root.analytics.list', params);
    }
  });

  let layoutChangedTimeout = null;
  $rootScope.layoutChanged = () => {
    clearTimeout(layoutChangedTimeout);
    layoutChangedTimeout = setTimeout(() => {
      $rootScope.$emit('layoutChanged');
    }, 1);
  };

  setInterval(() => {
    if (!$rootScope.sessionExpires) return;
    var expired = new Date($rootScope.sessionExpires) - new Date() < 0;
    if (expired) {
      localStorage.sessionExpires = null;
      window.location.reload();
    }
  }, 5000);


  $rootScope.$on('$stateChangeSuccess', function(e,toState) {
    window.scrollTo(0,0);
    _paq.push(['setDocumentTitle', toState.name.replace(/^root\./,'')]);
    _paq.push(['setCustomUrl', window.location.hash.substr(1)]);
    _paq.push(['trackPageView']);
  });

  $rootScope.$on('$stateChangeError', authorizationCheck);

  $rootScope.$on('$stateChangeStart', function(e,toState,toParams,fromState,fromParams) {
    $rootScope.lastFromState = fromState;
    Object.keys($rootScope.pendingRequests).forEach(d => $rootScope.pendingRequests[d].resolve());
    $rootScope.pendingRequests = {};

    // reset injected styles
    $window.addStyleString('');

    // clear open tooltips
    $('.d3-tip').remove();

    // close open dropdowns
    $('body').trigger('click');

    // copy $stateParams
    for (var param in fromParams) {
      if (!toParams[param] && param !== 'filter') toParams[param] = fromParams[param];
      if (typeof toParams[param] === 'string') // trim strings -- use " " to clear a stateParam!
        toParams[param] = toParams[param].trim();
    }

    if ([].concat($rootScope.authRoutes,$rootScope.appRoutes).indexOf(toState.name) < 0) {
      if (toState.name.indexOf('root.extra') > -1)
        return;
      const validStatesWithoutPortfolio = [
        'root.navigator.view',
        'root.navigator.edit',
        'root.navigator.edit.org',
        'root.navigator.edit.org_custom',
        'root.navigator.edit.org_api_controls'
      ];
      if (angular.isUndefined(toParams.portfolio) && toParams.type !=='custom' && !validStatesWithoutPortfolio.includes(toState.name)) {
        e.preventDefault();
        $state.go('root.navigator.view');
      }
    }
  });


  $rootScope.getPropertyLink = function(propertyid) {
    if (!propertyid) return null;
    return $state.href('root.analytics.record.property', { type: 'custom', customPrototype: '{%22propertyid%22:' + propertyid +'}' });
  };
  $rootScope.goToPropertyId = function(propertyid) {
    var dataPath = `root.analytics.record.property`;
    let newParams = Object.assign({}, $state.params);
    newParams.type = 'custom';
    newParams.customPrototype = '{%22propertyid%22:' + propertyid +'}';
    $state.transitionTo(dataPath, newParams, {reload: dataPath});
  };
  $rootScope.getPersonLink = function(person, address) {
    if (!(person && address)) {
      return null;
    }
    return $state.href('root.analytics.record.person', { 
      type: 'custom', 
      customPrototype: JSON.stringify({
        'type': 'person',
        'fullname': person.name,
        'address': address.street_address,
        'city': address.city,
        'state': address.state,
        'zip': address.zip      
      })
    });
  };
  $rootScope.showErrors = function(error) {
    let xflash = error.data && error.data.xflash || error.value && error.value.xflash || error.values && error.values.xflash;
    if (xflash && Array.isArray(xflash) && xflash.length > 0) {
      Object.values(xflash).forEach(msg => {
        if (typeof msg === "string") {
          growl.error(msg);
        } else if (typeof msg === "object" && msg.message) {
          if (Object.prototype.hasOwnProperty.call(msg,'schemaPath')) {      // Ajv validation ErrorObject
            if (msg.keyword === 'additionalProperties') {
              growl.error(`data${msg.instancePath} ${msg.message} (${msg.params.additionalProperty})`);
            } else {
              growl.error(`data${msg.instancePath} ${msg.message}`);
            }
          } else {
            growl.error(msg.message);
          }
        }
      });
    } else if (xflash && typeof xflash === "object") {
      for (const [key,value] of Object.entries(xflash)) {
        growl.error(`${key}: ${value}`);
      }
    } else {
      let msg = error.message || error.msg || (error.data && error.data.message) || (error.value && error.value.message);
      if (msg) {
        growl.error(msg);
      } else {
        growl.error("Unknown error occurred");
      }
    }
  };


  function authorizationCheck(e, toState) {
    if (toState.name === 'root') {
      return;
    }
    if ($rootScope.sessionExpires && new Date($rootScope.sessionExpires) - new Date() < 0) {
      e.preventDefault();
      $state.go('login');
      growl.error('You have been logged out due to inactivity. Please log in again');
      return;
    }
    if ($window.privateScriptsLoaded && !$rootScope.user && $rootScope.authRoutes.indexOf(toState.name) < 0) {
      e.preventDefault();
      $state.go('login');
      if ($rootScope.ipwhitelist)
        growl.error('Unauthorized Access. Error Code: 0209. Please contact help@thenumber.com or (646)883-4644.', {ttl: -1});
      else
        growl.error('Unauthorized access. Please ensure your security package is installed.', {ttl:-1});
    }
  };
}]);
