const Settings = require('./Settings');
const DragHelper = require('./DragHelper.js');
const ExpandableElement = require('./TableElement/ExpandableElement');
const Pane = require('./Pane');
const Viewport = require('./Viewport');
const Scroller = require('./Scroller');
const HeaderElement = require('./TableElement/HeaderElement');
const StepDefinition = require('./StepDefinition/StepDefinition');
const StepDefinitionAggregation = require('./StepDefinition/StepDefinitionAggregation');
const StepDefinitionChart = require('./StepDefinition/StepDefinitionChart');
const ChartConfig = require('./Config/ChartConfig');

const ChartFactory = require('./Charts').ChartFactory;

const LEFT_SIDE_WIDTH = 'leftSideWidth';
const MAX_REFRESH_TIME = 200;

class GirdGrid {
  constructor(element, dataProvider, atomicItem, options) {
    this._lastTop = 0;
    this._lastLeft = 0;
    this._lastHeight = 700;
    this._lastSetHeight = 700;
    this.atomicItem = atomicItem;
    this.element = element;
    this.element.addClass('gird-grid');
    this.options = options || {};
    this._onChangeHandler = this.options.onChange || (() => {});

    this.afterLayoutChanged = [];
    this._isDragging = {};
    this.columns = {};
    this.columnsByKey = {};
    this.scroll = new Pane(this, 'scroll-pane', true);
    this.leftPane = new Pane(this, 'left-pane');
    this.rightPane = new Pane(this, 'right-pane');
    this.fullPane = new Pane(this, 'full-pane');

    this.panes = [this.scroll, this.leftPane, this.rightPane, this.fullPane];

    let scrollCaptureVisible = false, preventDefaultUntil = 0;
    this.scroll.element.css('left', 10000);
    this.element.on('wheel', 
      (event) => { 
        // if (Math.abs(event.originalEvent.deltaY) <= Math.abs(event.originalEvent.deltaX)) {
        //   return;
        // }

        // TODO: it'd be great to prevent accidental backs in chrome
        if (!preventDefaultUntil || preventDefaultUntil > new Date().getTime()) {
          event.preventDefault();
          event.stopPropagation();
        }
        
        if (!scrollCaptureVisible) {
          this.scroll.element.css('left', 0);
          scrollCaptureVisible = true;
          preventDefaultUntil = new Date().getTime() + 50;
        }

        clearTimeout(this._hideScrollTimeout);
        this._hideScrollTimeout = setTimeout(() => {
          this.scroll.element.css('left', 10000);
          scrollCaptureVisible = false;
          preventDefaultUntil = 0;
        }, 400);

      })

    this.setSize({width: 700, height: 700});

    this.dataProvider = dataProvider;

    this.stepDefinitions = [];
    this.stepDefinitionsOrderLookup = {};
    this.stepDefinitionsInsetAmounts = {};

    this.viewport = new Viewport();
    this.rootTableElement = null;

    this.states = {};
    this._memoize = {};

    this.horizontalScroller = new Scroller(this, 'horizontal', left => this.scrollTo(this._lastTop, left, true));
    this.verticalScroller = new Scroller(this, 'vertical', top => this.scrollTo(top, this._lastLeft, true));
    this.filterAndSortCallback = (arr, filter) => !filter ? arr : arr.filter(column => column.title.toLowerCase().indexOf(filter) >= 0);
    this.groupingFilterAndSortCallback = (arr, filter) => !filter ? arr : arr.filter(column => column.groupingTitle.toLowerCase().indexOf(filter) >= 0);
  }

  serializeConfig() {
    return {
      stepDefinitions: this.stepDefinitions.map(s => s.serializeConfig())
    }
  }

  memoize(key, cb) {
    return this._memoize[key] || (this._memoize[key] = cb());
  }
 
  setCustomFilterAndSortCallback(cb) {
    this.filterAndSortCallback = cb;
  }

  setCustomGroupingFilterAndSortCallback(cb){
    this.groupingFilterAndSortCallback = cb;
  }

  deserializeConfig(config, state) {
    if (this.rootTableElement) {
      this.rootTableElement.executePageOut();
      this.rootTableElement = null;
    }

    if (config) {
      this._memoize = {};
      if (config.stepDefinitions && config.stepDefinitions.length > 0) {
        this.setStepDefinitions(config.stepDefinitions.map(config => StepDefinition.deserializeConfig(this, config)).filter(f => f));
      }
    }
    if (state) {
      this.states = state.state;
      this.afterLayoutChanged.push(() => {
        this.scrollTo(state.top, state.left, true);
      });

      // let the columns reload info from the grid
      Object.keys(this.columns).forEach(columnId => this.columns[columnId].setGrid(this));
    }
    this.refresh(true);
  }

  sortedColumns() {
    if (!this._sortedColumns) {
      this._sortedColumns = Object.keys(this.columns).map(columnId => this.columns[columnId]); 
      this._sortedColumns.sort((a,b) => a.order - b.order);
    }
    return this._sortedColumns;
  }

  availableCharts() {
    if (!this._availableCharts) {
      var allCapabilities = new Set();
      this.sortedColumns().forEach(c => [...c.capabilities].forEach(cap => allCapabilities.add(cap)));
      this._availableCharts = ChartFactory.availableChartTypesForAllCapabilities(allCapabilities);
    }
    return this._availableCharts;
  }

  serializeState() {
    return {
      state: this.states,
      top: this._lastTop,
      left: this._lastLeft,
      height: this._lastHeight
    } 
  }

  propagateCrossFilters() {
    this.dataProvider.crossFilters = this.stepDefinitions.map(s => s.crossFilter()).filter(a => a);
    this.refresh();
  }

  getState(nameSpace, key) {
    var stateSpace = this.states[nameSpace];
    if (stateSpace) {
      return stateSpace[key];
    }
    return null;
  }

  setState(nameSpace, key, value) {
    var stateSpace = this.states[nameSpace];
    if (!stateSpace) {
      stateSpace = {};
      this.states[nameSpace] = stateSpace;
    }

    if (!value) {
      value = undefined;
    }
    let currentValue = stateSpace[key];

    if (value !== currentValue) {
      if (value) {
        stateSpace[key] = value;
      }
      else {
        delete stateSpace[key];
      }
      this._triggerChange();
    }
  }

  _triggerChange(configChanged) {
    this._onChangeHandler(!!configChanged);
  }

  isDragging() {
    return this._isDraggingFlag;
  }

  setDragging(key, isDragging) {
    if (isDragging && this._isDragging[key]) {
      return;
    }
    if (!isDragging && !this._isDragging[key]) {
      return;
    }

    if (isDragging) {
      this._isDragging[key] = true;
    }
    else {
      delete this._isDragging[key];
    }

    if (Object.keys(this._isDragging).length > 0) {
      // we're dragging
      this._isDraggingFlag = true;
      clearTimeout(this._isDraggingTimeout);
      this.element.addClass('mid-drag');
    }
    else {
      this._isDraggingTimeout = setTimeout(() => {
        this._isDraggingFlag = false;
        this.element.removeClass('mid-drag');
        this.setSize();
      }, 100);
    }
  }

  findColumn(id) {
    return this.columns[id];
  }
  findColumnByKey(key) {
    return this.columnsByKey[key];
  }

  registerColumn(column) {
    this._sortedColumns = null;
    this._availableCharts = null;
    this.columns[column.id] = column;
    this.columnsByKey[column.key] = column;
    column.setGrid(this);
  }

  dragStepHint(step) {
    if (step) {
      this._dragStepHint = step;
      this._dragHintId = null;
    }
    return this._dragStepHint;
  }

  dragColumnHintId(id) {
    if (id) {
      this._dragHintId = id;
      this._dragStepHint = null;
    }
    return this._dragHintId;
  }

  dragColumnHintWidth(width) {
    if (width) {
      this._dragHintWidth = width;
    }
    return this._dragHintWidth;
  }

  checkDragCapability(event, capability) {
    let id = this.dragColumnHintId();

    if (id) {
      let column = this.findColumn(id);
      if (column) {
        if (!column.capabilities.has(capability)) {
          event.stopPropagation();
          event.preventDefault();
          event.originalEvent.dataTransfer.dropEffect = "none";
          return false;
        }
      }
    }

    return true;
  }

  baseLeftSize() {
    if (this.stepDefinitions) {
      return this.stepDefinitions.reduce((size, step) => Math.max(size, step.leftSize(this) + this.stepDefinitionsInsetAmounts[step.id]), 0) 
    }
    return 100;
  }

  setSize(size) {
    if (size) {
      this.size = size;
      this._lastSetHeight = Math.min(size.height, this._lastHeight);
      
      this.element.css({
        width: size.width,
        height: this._lastSetHeight
      });
    }

    if (this.stepDefinitions) {
      let leftSize = this.getState(LEFT_SIDE_WIDTH, LEFT_SIDE_WIDTH);
      if (!leftSize) {
        leftSize = 1 + (this.rootTableElement ? this.rootTableElement.rootElement.leftSize() : 0);
        leftSize = Math.max(leftSize, this.baseLeftSize());
      }
      else {
        leftSize = Math.max(leftSize, (this.rootTableElement ? this.rootTableElement.rootElement.minLeftSize() : 0));
      }
      let rightSize = this.size.width - leftSize;
      let rightScrollSize = this.stepDefinitions.reduce((size, step) => Math.max(size, step.rightSize(this) + Settings.ROW_HEIGHT*2), rightSize);
      rightScrollSize = Math.max(rightSize, rightScrollSize);

      this._lastLeftSize = leftSize;
      this.leftPane.setWidth(leftSize);
      this.rightPane.setWidth(rightSize);

      this.horizontalScroller.setSize(this.size.width - leftSize, rightScrollSize);

      this.horizontalScroller.element.css('left', leftSize);
      if (this.rootTableElement) {
        if (!this.stepDefinitions || !this.stepDefinitions[0] || !this.stepDefinitions[0].noSummary) {
          this.verticalScroller.element.css('top', this.rootTableElement.rootElement.getHeight() + Settings.ROW_HEIGHT + 2);
        }
        else {
          this.verticalScroller.element.css('top', this.rootTableElement.rootElement.getHeight() + 1); 
        }
      }

      this.rightPane.setScrollWidth(rightScrollSize);
      this.scroll.setScrollWidth(rightScrollSize + leftSize);
    }

    this.layoutChanged();
  }

  buildLeftSideGrip() {
    let result = $(`<div class="grip">`);

    result.on('mousedown', (event) => {
      if (event.button !== 0) {
        return;
      }

      let startWidth = this._lastLeftSize;
      DragHelper.doEWResizeHelper(event, Math.max(startWidth - 150, 0), 600 - startWidth, delta => {
        this.setState(LEFT_SIDE_WIDTH, LEFT_SIDE_WIDTH, startWidth + delta);
        this.setSize();
      }, () => {
        // ?
      });
    })

    return result;
  }

  updateViewport() {
    this.viewport.top = this._lastTop;
    this.viewport.bottom = this.viewport.top + this.size.height;

    let time = new Date().getTime();
    if (!this._lastUpdateViewport) {
      this._lastUpdateViewport = time - MAX_REFRESH_TIME;
    }

    clearTimeout(this._updateViewportTimer);
    if (time >= this._lastUpdateViewport + MAX_REFRESH_TIME) {
      this.rootTableElement && this.rootTableElement.viewportChanged();
      this._lastUpdateViewport = time;
    }
    else {
      this._updateViewportTimer = setTimeout(() => {
        this.rootTableElement && this.rootTableElement.viewportChanged();
      }, MAX_REFRESH_TIME - (time - this._lastUpdateViewport));
    }

  }

  insertAggregationColumn(column, front) {
    let firstAggregationStep = this.stepDefinitions.find(step => step.aggregationsConfig);
    firstAggregationStep.aggregationsConfig.insertColumn(column, front);
    this.refresh(false, () => this.scrollTo(this._lastTop, front ? 0 : 100000));
  }

  removeAggregationColumn(column) {
    let firstAggregationStep = this.stepDefinitions.find(step => step.aggregationsConfig);
    firstAggregationStep.aggregationsConfig.removeColumn(column);
    this.refresh();
  }

  getAggregationsSet() {
    let firstAggregationStep = this.stepDefinitions.find(step => step.aggregationsConfig);
    return firstAggregationStep.aggregationsConfig.getAggregationsSet();
  }

  insertStepDefinition(stepDefinition) {
    this.setStepDefinitions([stepDefinition].concat(this.stepDefinitions));
    this.refresh();
    this.afterAddStepDefinition(stepDefinition);
  }

  afterAddStepDefinition(newStepDefinition, $dom) {
    if (newStepDefinition instanceof StepDefinitionChart && newStepDefinition.chart().hasMissingInputs()) {
      new ChartConfig(newStepDefinition.chart(), this, {}, newStepDefinition, $dom || this.element);
    }
  }

  setStepDefinitions(stepDefinitions, clearColumns = false) {
    if (clearColumns) {
      this.columns = {};
    }
    this._sortedColumns = null;
    var hasSeenColumns = new Set();
    this.stepDefinitions = stepDefinitions = stepDefinitions.filter(step => {
      if (step.column) {
        if (hasSeenColumns.has(step.column.groupingTitle)) {
          return false;
        }
        hasSeenColumns.add(step.column.groupingTitle);
      }
      return true;
    });

    this.stepDefinitionsOrderLookup = stepDefinitions.reduce((acc, step, index) => {
      if (step.aggregationsConfig) {
        step.aggregationsConfig.aggregations().forEach(aggregation => {
          this.columns[aggregation.id] = aggregation;
          aggregation.setGrid(this);
        });
      }
      if (step.column) {
        this.columns[step.column.id] = step.column;
        step.column.setGrid(this);
      }
      acc[step.id] = index;
      return acc;
    }, {});

    this.stepDefinitionsInsetAmounts = {};
    stepDefinitions.reduce((acc, step) => {
      this.stepDefinitionsInsetAmounts[step.id] = acc;
      return acc + step.insetAmount();
    }, 0);

    this.dataProvider.crossFilters = this.stepDefinitions.map(s => s.crossFilter()).filter(a => a);

    this.setSize(this.size);
    this._triggerChange(true);
  }

  clearCrossFilter(crossFilter) {
    if (!crossFilter) {
      return;
    }
    
    this.stepDefinitions.forEach(s => {
      if (s.configId === crossFilter.configId) {
        s.adjustCrossFilter(null);
      }
    })
  }

  zindexOfStep(stepDefinition) {
    return 500 - this.getIndexOfStep(stepDefinition) * 10;
  }

  getStepDefinition(stepIndex) {
    return this.stepDefinitions[stepIndex];
  }

  getIndexOfStep(stepDefinition) {
    if (!stepDefinition) {
      return -1;
    }
    return this.stepDefinitionsOrderLookup[stepDefinition.id];
  }

  getInsetAmount(stepDefinition) {
    if (!stepDefinition) {
      return 0;
    }
    return this.stepDefinitionsInsetAmounts[stepDefinition.id];
  }

  getNextTableElement(step, filterContext) {
    let stepIndex = this.getIndexOfStep(step);
    let nextStep = this.stepDefinitions[stepIndex + 1];
    if (nextStep) {
      return nextStep.createTableElement(this, filterContext);
    }
  }

  render() {
    if (this.rootTableElement) {
      this.rootTableElement.executePageOut();
    }
    this.rootTableElement = this.createHeaderAndSummaryElement();
    this.updateViewport();
    this.setSize(this.size);
  }

  refresh(hard, cb) {
    clearTimeout(this._refreshTimeout);
    this._refreshTimeout = setTimeout(() => {
      if (hard) {
        if (this.rootTableElement) {
          this.rootTableElement.executePageOut();
        }
        this.render();
        cb && cb();
        return;
      }
      this.stepDefinitions.forEach(step => {
        if (step.aggregationsConfig) {
          step.aggregationsConfig.resetAggregationPositions();
        }
      });
      this.rootTableElement.refresh();
      this.setSize(this.size);
      this._triggerChange(true);
      cb && cb();
    }, 100);
  }

  createHeaderAndSummaryElement() {
    return new ExpandableElement(this, new HeaderElement(this), true, true);
  }

  scrollTo(top, left, performImmediate) {
    if (left === null) {
      left = this._lastLeft;
    }

    if (top == this._lastTop && left == this._lastLeft) {
      return;
    }

    clearTimeout(this._scrollToTimer);

    if (performImmediate) {
      this._handleScrollTo(top, left);
      return;
    }

    this._scrollToTimer = setTimeout(() => {
      this._handleScrollTo(top, left);
    }, 0);
  }

  smoothScrollTo(top) {
    this.scroll.scrollContainer.animate({scrollTop: `${top}px`, duration: 1000});
  }

  getStepDefinitionForDrop(originalEvent) {
    let stepId = parseInt(originalEvent.dataTransfer.getData('stepid'));
    if (stepId) {
      let result = this.stepDefinitions.find(step => step.id == stepId);
      if (!result) {
        if (this._dragStepHint && this._dragStepHint.id == stepId) {
          return this._dragStepHint;
        }
      }
      return result;
    }

    let columnId = parseInt(originalEvent.dataTransfer.getData('columnid'));
    if (!columnId) {
      return null;
    }
    let column = this.findColumn(columnId);
    if (!column) {
      return null;
    }
    let firstAggregationStep = this.stepDefinitions.find(step => step.aggregationsConfig);
    return new StepDefinitionAggregation(column, firstAggregationStep.aggregationsConfig);
  }

  _handleScrollTo(top, left) {
    this._lastTop = top;
    this._lastLeft = left;
    this.panes.forEach(pane => pane.scrollTo(top, left));
    this.horizontalScroller.setPosition(left);
    this.verticalScroller.setPosition(top);
    this.updateViewport();
    this._triggerChange();
  }

  layoutChanged() {
    clearTimeout(this._layoutChangedTimer);
    this._layoutChangedTimer = setTimeout(() => {
      if (this.rootTableElement) {
        let height = this.rootTableElement.getHeight() + 3 + 10; // for scroll bars
        this._lastHeight = height; // ugh
        this.panes.forEach(pane => pane.setHeight(height));

        this.verticalScroller.setSize(this.size.height, this._lastHeight);

        this.rootTableElement.layoutChanged();
        this._lastUpdateViewport = 0;
        this.updateViewport();

        if (Math.min(this.size.height, this._lastHeight) !== this._lastSetHeight) {
          this.setSize(this.size);         
        }

        if (this.afterLayoutChanged) {
          this.afterLayoutChanged.forEach(d => d());
          this.afterLayoutChanged = [];
        }
      }
    }, 0);
  }
}

module.exports = GirdGrid;
