const TableRow = require('./TableRow');

class TotalsElement extends TableRow {

  constructor(grid, aggregationsConfig, filterContext) {
    super(grid, null, filterContext, 1);
    this._aggregationsConfig = aggregationsConfig;
  }

  aggregationsConfig() {
    return this._aggregationsConfig;
  }

  fetchData() {
    if (!this.data) {
      this.data = this.grid.dataProvider.totals(this.aggregationsConfig(), this.filterContext);
    }
    return this.data;
  }

  hash() {
    return Promise.resolve('totals');
  }

  fastHash() {
    return 'totals';
  }

  elementClassNames() {
    return `totals-row`;
  }

  setExpandedStateChangeCallback(expandedStateChangeCallback) {
    // eat it
  }

  getExpandedElement(currentElement) {
    if (currentElement && currentElement.step === this.grid.getStepDefinition(0)) {
      return Promise.resolve(currentElement);
    }
    return Promise.resolve(this.grid.getStepDefinition(0).createTableElement(this.grid, this.filterContext));
  }


}

module.exports = TotalsElement;