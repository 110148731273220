const Capabilities = require('./Capabilities');

module.exports = Object.assign({
  grid: require('./GirdGrid'),
  Column: require('./Column/Column'),
  TableElements: require('./TableElement'),
  StaticDataProvider: require('./StaticDataProvider'),
  DataProvider: require('./DataProvider'),
  AggregationsConfig: require('./Column/AggregationsConfig'),
  StepDefinitionChart: require('./StepDefinition/StepDefinitionChart'),
  StepDefinitionAggregation: require('./StepDefinition/StepDefinitionAggregation'),
  ChartFactory: require('./Charts/ChartFactory'),
  ChartPrototype: require('./Charts/Chart'),
  ChartInput: require('./Charts/ChartInput'),
  Settings: require('./Settings')
}, Capabilities);