const Settings = require('../Settings');
const AggregationsConfig = require('../Column/AggregationsConfig');
const StepDefinition = require('./StepDefinition');
const TableRowList = require('../TableElement/TableRowList');
const DEFINITION_TYPE_AGGREGATION = 'AGGREGATION';

class StepDefinitionAggregation extends StepDefinition {
  
  constructor(column, aggregationsConfig, sort, isDeserialized) {
    super();
    this.column = column;
    this.aggregationsConfig = aggregationsConfig;
    this.sort = sort;

    if (!isDeserialized && !sort && column && column.grid && !(column.grid.atomicItem === column)){
      this.sort = {
        direction: -1,
        timestamp: new Date().getTime()
      };
    }
  }

  createTableElement(grid, filterContext) {
    return new TableRowList(grid, this, filterContext);
  }

  leftSize(grid) { 
    let hasNextStep = !!grid.getStepDefinition(grid.getIndexOfStep(this) + 1);
    if (hasNextStep) {
      return this.column.width + Settings.ROW_HEIGHT;
    }
    return this.column.width;
  }

  rightSize(grid) {
    return this.aggregationsConfig.width();
  }

  title() {
    return this.column.groupingTitle;
  } 

  serializeConfig() {
    return {
      type: DEFINITION_TYPE_AGGREGATION,
      aggregations: this.aggregationsConfig.serializeConfig(),
      column: this.column.key,
      sort: this.sort
    }
  }
}

StepDefinition.registerStepDefinitionDeserializer(DEFINITION_TYPE_AGGREGATION, (grid, config) => {
  let column = grid.findColumnByKey(config.column);
  if (!column) {
    return null;
  }

  return new StepDefinitionAggregation(
    column, 
    AggregationsConfig.deserializeConfig(grid, config.aggregations),
    config.sort,
    true)
});

module.exports = StepDefinitionAggregation;