const Settings = require('../Settings');
const Capabilities = require('../Capabilities');
const TableElement = require('./TableElement');

class StickyRow extends TableElement {

  constructor(grid) {
    super(grid);
  }

  setStickyHeight(height) {
    this._stickyHeight = height;
  }

  setStickyHeightBottom(bottom) {
    this._stickHeightBottom = bottom;
  }

  getStickyOffsetTop() {
    let stickyBottom = super.getStickyOffsetTop();
    if (this._stickyHeight) {
      return stickyBottom + this.getHeight();
    }
    return stickyBottom;
  }

  viewportChanged() {
    if (this._stickyHeight) {
      this.positionElement();
    }
    super.viewportChanged();
  }

  hash() {
    throw 'NOT_IMPLEMENTED';
  }

  isVisible() {
    if (this._stickyHeight) {
      let top = this.getTop(), bottom = this.getBottom() + this._stickyHeight();
      return (top < this.grid.viewport.bottom + Settings.SCREEN_OVERFLOW_BUFFER && bottom > this.grid.viewport.top - Settings.SCREEN_OVERFLOW_BUFFER);
    }
    return super.isVisible();
  }

  shouldStick() {
    return true;
  }

  positionElement() {
    if (this._$dom) {

      let top = this.getTop(),
          targetContainer = 'container';

      if (this._stickyHeight && this.shouldStick()) {

        let myHeight = this.getHeight();
        let stickyTop = this.getStickyOffsetTop() - myHeight;
        let stickyBottom = this._stickHeightBottom();
        let totalHeight = this._stickyHeight();

        if (top + totalHeight - stickyBottom - myHeight < this.grid.viewport.top + stickyTop) {
          top += totalHeight - stickyBottom - myHeight;
        }
        else if (top - stickyTop < this.grid.viewport.top) {
          top = stickyTop;
          targetContainer = 'stickyContainer';
        }
      }

      this._executePosition(top, targetContainer);
    }
  }

  elementClassNames() {
    return "";
  }

  pageIn() {
    this._lastTop = null;
    this._$dom = $(`<div class="${this.elementClassNames()} table-element">`);
    this._$rightDom = $(`<div class="${this.elementClassNames()} table-element">`);
    this._$grip = this.grid.buildLeftSideGrip().appendTo(this._$dom);
    this.positionElement();
  }

  pageOut() {
    this._$dom.remove();
    this._$dom = null;
    this._$rightDom.remove();
    this._$rightDom = null;

    this._lastTop = null;
    this._lastTargetContainer = null;
  }

  zIndex() {
    return 1;
  }

  _executePosition(top, targetContainer) {
    if (!this.isPagedIn) {
      return;
    }

    if (this._lastTop !== top) {
      this._lastTop = top;
      this._$dom.css({
        top: top,
        left: this.leftPadding(),
        'z-index': this.zIndex()
      });
      this._$rightDom.css({
        top: top,
        left: 0,
        'z-index': this.zIndex()
      });
    }

    if (this._lastTargetContainer != targetContainer) { 
      this._lastTargetContainer = targetContainer;
      this._$dom.appendTo(this.grid.leftPane[targetContainer]);
      this._$rightDom.appendTo(this.grid.rightPane[targetContainer]);
    }
  }

  isStuck() {
    let top = this.getTop();
    if (this._stickyHeight) {

      let myHeight = this.getHeight();
      let stickyTop = this.getStickyOffsetTop() - myHeight;
      let stickyBottom = this._stickHeightBottom();
      let totalHeight = this._stickyHeight();

      if (top + totalHeight - stickyBottom < this.grid.viewport.top + stickyTop) {
        return true;
      }
      else if (top - stickyTop < this.grid.viewport.top) {
        return true;
      }
    }
    return false;
  }

  layoutChanged() {
    super.layoutChanged();
    this.positionElement();
  }

  getStickyOffsetBottom() {
    return this.getHeight();
  }

  attachDragEvents($el, draggingKey) {
    let cancel = null;
    $el.on('drop', event => {
      this.isDragging = false;
      this.isDraggingRight = false;

      this.removeDropTarget();
      event.preventDefault();
      this.grid.setDragging('row' + this.id + 'isDragging', false);
      this.grid.setDragging('row' + this.id + 'isDraggingRight', false);
      
      let newStepDefinition = this.grid.getStepDefinitionForDrop(event.originalEvent);
      if (!newStepDefinition) {
        return;
      }      

      this.setExpanded(true);
      let myIndex = this.step ? this.grid.getIndexOfStep(this.step) : 0;
      if (this.expandedStateChangeCallback) {
        myIndex ++;
      }
      this.grid.setStepDefinitions(this.grid.stepDefinitions.slice(0, myIndex).concat(newStepDefinition).concat(this.grid.stepDefinitions.slice(myIndex)));
      this.grid.refresh();
      this.grid.afterAddStepDefinition(newStepDefinition);
    });

    $el.on('dragenter', event => {
      if (!this.grid.checkDragCapability(event, Capabilities.CAPABILITIES_GROUPING)) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      clearTimeout(cancel);
      cancel = setTimeout(() => {
        this[draggingKey] = true;
        this.createDropTarget();
        this.grid.layoutChanged();
        this.grid.setDragging('row' + this.id + draggingKey, true);
      },1);
    });
    $el.on('dragleave', event => {
      clearTimeout(cancel);
      this[draggingKey] = false;
      this.removeDropTarget();
      this.grid.layoutChanged();
      this.grid.setDragging('row' + this.id + draggingKey, false);
    });

    $el.on('dragenter', event => event.preventDefault());
    $el.on('dragover', event => {
      if (!this.grid.checkDragCapability(event, Capabilities.CAPABILITIES_GROUPING)) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();
      event.originalEvent.dataTransfer.dropEffect = "move";
      return true;
    });
  }
}

module.exports = StickyRow;