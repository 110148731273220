const QUERY_PARAMETER_ORDER = ['skip','key','type','bucketingField','colorField','sizeField','aggregateBy','topLabel',
'org','portfolio','filter','pull_credit','customPrototype','growl','growlType',
'growlCode','live_versium','landscape','min_match_score','min_match_quality','sortModel','sortDir','code',
'multiPortfolio','allPortfolios','selected','print','preset','state','scenario_config',
'scenario_index','tranche','tranche_index','group','pricing_date','focus','board','termsOfService', 'board_preset', 
'board_state','session_ref', 'force_factor_date', 'remitFactorDate', 'poolTrancheFactorDate'];
const PARAMS_ORDER_LOOKUP = QUERY_PARAMETER_ORDER.reduce((acc, param, index) => {
  acc[param] = index + 1;
  return acc;
}, {});

angular.module('flashlightApp')
  .constant('moment', moment)
  .constant('FlashlightQueryParameters', QUERY_PARAMETER_ORDER)
  .constant('stateParams2QueryParams', function(url, $stateParams, data) {
    let firstQuestionMark = url.indexOf('?');
    let urlParams = new URLSearchParams();
    if (firstQuestionMark >= 0) {
      urlParams = new URLSearchParams(url.slice(firstQuestionMark + 1));
      url = url.slice(0, firstQuestionMark);
    }

    Object.keys($stateParams).forEach(key => {
      if (data && data[key] !== undefined) {
        return;
      }

      if (typeof $stateParams[key] === 'object' || $stateParams[key] === undefined) {
        return;
      }

      if (urlParams.has(key)) {
        return;
      }

      urlParams.set(key, $stateParams[key]);
    });

    let urlParamsKeys = [...urlParams.keys()];
    urlParamsKeys.sort((a,b) => {
      let aIndex = PARAMS_ORDER_LOOKUP[a] || 10000,
          bIndex = PARAMS_ORDER_LOOKUP[b] || 10000;
      if (aIndex < bIndex) return -1;
      if (aIndex > bIndex) return 1;
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });

    //build a new sorted url search params
    let finalUrlParams = new URLSearchParams();
    urlParamsKeys.forEach(key => {
      finalUrlParams.append(key, urlParams.get(key));
    });

    let result, queryString = finalUrlParams.toString();
    if (queryString !== '') {
      result = url + '?' + queryString;
    }
    else {
      result = url;
    }
    return result;
  })
  .constant('createFilter', function($stateParams, row, bucketField) {
    var filter;
    try { filter = $stateParams.filter && JSON.parse($stateParams.filter); }
    catch(e) { }
    if (typeof filter !== 'object' || !filter.length) filter = [];
    var query = {};
    if (row.type === "string" && $stateParams.filter !== "{}") {
      query[bucketField] = {
        $eq: row.band,
      };
      query[bucketField].bandDisplay = String(row.bandDisplay).replace(/\//g, '.');
      filter.push(query);
      return JSON.stringify(filter);
    }

    if (row.type === "string") {
      query[bucketField] = {
        $eq: row.band,
      };
    }  else {
      query[bucketField] = {
        $lte: row.upperBound,
        $gt: row.lowerBound,
      };
    }
    query[bucketField].bandDisplay = String(row.bandDisplay).replace(/\//g, '.');
    filter.push(query);
    return JSON.stringify(filter);
  });
