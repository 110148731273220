const Settings = require('../Settings');
const TableRow = require('./TableRow');
const TableElement = require('./TableElement');
const ExpandableElement = require('./ExpandableElement');
const BUSHY_FACTOR = 4;
const BUSHY_ROW_HEIGHT_KEY = 'brh';

class BushyRowList extends TableElement {

  constructor(grid, step, filterContext, lowerBounds, upperBounds, totalCount) {
    super(grid);

    this.step = step;
    this.totalCount = totalCount;
    this.filterContext = filterContext;
    this.lowerBounds = lowerBounds;
    this.upperBounds = upperBounds;
    
    this.childrenElements = null;
  }

  pageIn() {
    if (!this.childrenElements) {
      this.childrenElements = [];

      if (this.upperBounds - this.lowerBounds > BUSHY_FACTOR) {
        let steps = BUSHY_FACTOR;
        while (steps * BUSHY_FACTOR < this.upperBounds - this.lowerBounds) {
          steps *= BUSHY_FACTOR;
        }
        for (let i = this.lowerBounds; i <= this.upperBounds; i += steps) {
          this.childrenElements.push(new BushyRowList(this.grid, this.step, this.filterContext, i, Math.min(this.upperBounds, i + steps - 1), this.totalCount));
        }
      }
      else if (!this.grid.getStepDefinition(this.grid.getIndexOfStep(this.step) + 1)) {
        for (let i = this.lowerBounds; i <= this.upperBounds; i++) {
          this.childrenElements.push(new TableRow(this.grid, this.step, this.filterContext, i, this.totalCount));
        }
      }
      else {
        for (let i = this.lowerBounds; i <= this.upperBounds; i++) {
          let rowElement = new TableRow(this.grid, this.step, this.filterContext, i);
          this.childrenElements.push(new ExpandableElement(this.grid, rowElement, i === this.totalCount - 1, this.step.noSummary));
        }
      }

      let elementAbove = this.elementAbove;
      this.childrenElements.forEach(childElement => {
        childElement.setElementAbove(elementAbove);
        elementAbove = childElement;
      });
    }
    else {
      this.childrenElements.forEach(childElement => childElement.executePageIn());
    }

    this.viewportChanged();
  }

  getStickyOffsetBottom() {
    if (this.childrenElements && this.childrenElements.length > 0) {
      return this.childrenElements[this.childrenElements.length - 1].getStickyOffsetBottom();
    }
    return 0;
  }

  pageOut() {
    this.childrenElements.forEach(childElement => childElement.executePageOut());
    this.childrenElements = null;
  }

  viewportChanged() {
    if (this.childrenElements) {
      this.childrenElements.forEach(childElement => childElement.viewportChanged());
    }

    super.viewportChanged();
  }

  setElementAbove(element) {
    super.setElementAbove(element);

    if (this.childrenElements) {
      this.childrenElements[0].setElementAbove(element);
    }
  }

  getStateKey() {
    return `${this.filterContext.hash}_${this.lowerBounds}_${this.upperBounds}`;
  }

  getHeight() {
    let defaultHeight = this.getDefaultHeight(),
        resultingHeight = defaultHeight,
        stateKey = this.getStateKey(),
        cameFromState = false;

    if (this.childrenElements) {
      resultingHeight = this.childrenElements.reduce((total, row) => total + row.getHeight(), 0);
    }
    else {
      resultingHeight = this.grid.getState(BUSHY_ROW_HEIGHT_KEY, stateKey) || defaultHeight;
      cameFromState = true;
    }

    if (defaultHeight === resultingHeight) {
      this.grid.setState(BUSHY_ROW_HEIGHT_KEY, stateKey, null);
    }
    else if (!cameFromState) {
      this.grid.setState(BUSHY_ROW_HEIGHT_KEY, stateKey, resultingHeight);
    }

    return resultingHeight;
  }

  refresh() {
    if (this.childrenElements) {
      this.childrenElements.forEach(child => child.refresh());
    }
  }

  getDefaultHeight() {
    return (this.upperBounds - this.lowerBounds + 1) * Settings.ROW_HEIGHT;
  }

  layoutChanged() {
    super.layoutChanged();
    
    if (this.childrenElements) {
      this.childrenElements.forEach(childElement => childElement.layoutChanged());
    }
  }
}

module.exports = BushyRowList;