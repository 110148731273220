const AggregationColumnEditor = require('./AggregationColumnEditor');
const Capabilities = require('../Capabilities');
const GroupingColumnEditor = require('./GroupingColumnEditor');
const StaticValuesEditor = require('./StaticValuesEditor');
const BaseConfig = require('./BaseConfig');
const IntervalValues = require('./IntervalValues');

class ChartConfig extends BaseConfig {
  constructor(chart, grid, filterContext, step, elementToCover) {
    super($(`
    <div class="chart-config">
      <div class="chart-config-close" outlet="close">+</div>
      <div class="chart-options" outlet="chartOptions"></div>
      <div class="chart-outlet" outlet="chart"></div>
    </div>`), elementToCover, Math.max(450, $(elementToCover).outerWidth()), 450, true);

    this.hasChanged = false;
    this.chart = chart;
    this.grid = grid;
    this.filterContext = filterContext;
    this.step = step;

    $(this.outlets.close).on('click', () => this.close());

    this.refreshChart();
  }

  close(el) {
    super.close(el);
    if (this.hasChanged) {
      this.grid.refresh();
    }
  }

  buildInputs() {
    let existingIds = new Set();
    $(this.outlets.chartOptions).empty();
    this.chart.chartInputs.forEach(input => {
      let $el = $(`<div class="chart-config-input"></div>`);

      let currentColumn = this.chart.getConfig(input);
      if (!currentColumn && !input.optional) {
        if (input.requiredCapability === Capabilities.CAPABILITIES_TIME_INTERVAL) {
          currentColumn = IntervalValues[2];    // Month
        } else {
          currentColumn = this.grid.sortedColumns().find(column => !existingIds.has(column.groupingTitle) && column.capabilities.has(input.requiredCapability));
        }
        this.hasChanged = true;
        this.chart.setConfig(input, currentColumn);
      }
      if (currentColumn) {
        existingIds.add(currentColumn && currentColumn.groupingTitle);
      }

      $el.append($("<label>").text(input.name + ": "));
      let dropdown = $(`<div class="chart-config-dropdown">`);
      $el.append(dropdown);

      let makeChoice = choice => {
        if (choice !== currentColumn) {
          this.hasChanged = true;
          this.step.adjustCrossFilter(null);
          this.chart.setConfig(input, choice);
          this.refreshChart();
        }
      };

      if (input.requiredCapability === Capabilities.CAPABILITIES_GROUPING) {
        dropdown.on('click', () => new GroupingColumnEditor(this.grid, dropdown, currentColumn, makeChoice, false, undefined, undefined, undefined, input.optional));
        dropdown.text(currentColumn && currentColumn.groupingTitle);
      }
      else if (input.requiredCapability === Capabilities.CAPABILITIES_NUMBER_LABEL) {
        dropdown.on('click', () => new AggregationColumnEditor(this.grid, dropdown, currentColumn, makeChoice, false, undefined, undefined, input.requiredCapability, input => input.groupingTitle, input.optional));
        dropdown.text(currentColumn && currentColumn.groupingTitle);
      }
      else if (input.requiredCapability === Capabilities.CAPABILITIES_TIME_INTERVAL) {
        dropdown.on('click', () => new StaticValuesEditor(this.grid, dropdown, currentColumn, makeChoice, false, undefined, undefined, input.requiredCapability, undefined, input.optional, IntervalValues));
        dropdown.text(currentColumn && currentColumn.title);
      }
      else {
        dropdown.on('click', () => new AggregationColumnEditor(this.grid, dropdown, currentColumn, makeChoice, false, undefined, undefined, input.requiredCapability, undefined, input.optional));
        dropdown.text(currentColumn && currentColumn.title);
      }

      $(this.outlets.chartOptions).append($el);
    });
  }

  refreshChart() {
    this.buildInputs();
    this.chartElement = this.chart.render(this.outlets.chart, this.grid.dataProvider, this.filterContext, this.step, this.chartElement);
  }
}

module.exports = ChartConfig;