const BaseConfig = require('./BaseConfig');

class ContextMenu extends BaseConfig {
  constructor(ev, menu, elementToCover, elementChosenCb, stepFilterContext) {
    super($(`
    <div class="girdgrid-context-menu">
    </div>`), elementToCover, 150, 'auto');

    this.ev = ev;
    this.elementChosenCb = elementChosenCb;
    this.menu = menu;
    this.stepFilterContext = stepFilterContext;
    this.populate();
  }

  populate() {
    this._$element.empty();
    this.menu.forEach(menuItem => {
      let $el = $(`<div class="girdgrid-context-menu-item">`).text(menuItem.label);

      if (Array.isArray(menuItem.action)) {
        let $expand = $(`<div class="girdgrid-context-menu-expand">▼</div>`).appendTo($el);
        $el.on('click', ev => {
          new ContextMenu(ev, menuItem.action, $expand, () => {
            if (this.elementChosenCb) {
              this.elementChosenCb();
            }
            this.close();
          });
        });
      }
      else {
        $el.on('click', () => {
          if (this.elementChosenCb) {
            this.elementChosenCb();
          }
          menuItem.action(this.stepFilterContext);
          this.close();
        });
      }
      this._$element.append($el);
    });
  }

  position() {
    if (this._$elementToCover) {
      super.position();
    }
    else if (this.ev) {
      this._$element.show().css({
        left: this.ev.pageX,
        top: this.ev.pageY
      });
    }
    else {
      this._$element.hide();
    }
  }

  static attachToElement(element, cb, includeClick, stepFilterContext) {
    element.on('contextmenu' + (includeClick ? ' click' : ''), ev => {
      let menu = cb(ev.type === 'click');
      if (menu && menu.length > 0) {
        $(document.body).trigger('mousemove');// kill any tooltips
        element.addClass('active');
        ev.preventDefault();
        ev.stopPropagation();
        new ContextMenu(ev, menu, undefined, undefined, stepFilterContext);
      }
    });
  }
}

module.exports = ContextMenu;