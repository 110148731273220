class Scroller {
  constructor(grid, orientation, callback) {
    this.max = 1;
    this.visibleSize = 1;
    this.value = 0;
    this.clickOffset = 0;
    this.grid = grid;
    this.callback = callback;
    this.orientation = orientation;
    this.element = $(`<div class="scroller scroller-${orientation}">`).appendTo(grid.element);
    this.wrapper = $(`<div class="scroller-wrapper">`).appendTo(this.element);
    this.grip = $(`<div class="scroller-grip">`).appendTo(this.wrapper);

    this.element.on('mousedown', event => {
      this.handleEvent(event, true);
      $(document.body).on('mousemove.scroller', event => this.handleEvent(event));
      $(document.body).on('mouseup.scroller', () => {
        $(document.body).off('mousemove.scroller').off('mouseup.scroller');
      });
    });
  }

  handleEvent(event, isClick) {
    event.preventDefault();
    let targetOffset = {left:0,top:0};
    if (isClick) {
      this.clickOffset = (1-this.barSize) * Math.max(this.element.outerWidth(), this.element.outerHeight()) / 2;
    }

    if (event.target != this.element[0]) {
      targetOffset = $(event.target).offset();

      // this likely clicked the "grip"
      if (isClick && event.target === this.grip[0]) {
        this.clickOffset = Math.max(event.offsetX, event.offsetY);
      }

      let elementOffset = $(this.element).offset();
      targetOffset.left -= elementOffset.left;
      targetOffset.top -= elementOffset.top;
    }

    let target = 0;
    if (this.orientation === 'horizontal') {
      target = (event.offsetX + targetOffset.left - this.clickOffset) / (this.element.outerWidth() * this.barSize);
    }
    else {
      target = (event.offsetY + targetOffset.top - this.clickOffset) / (this.element.outerHeight() * this.barSize);
    }

    this.callback(Math.min(Math.max(target,0) * (this.max - this.visibleSize), this.max - this.visibleSize));
  }

  setSize(visibleSize, max) {
    this.visibleSize = visibleSize;
    this.max = max;
    this.setPosition(this.value);
  }

  setPosition(value) {
    this.value = value;
    if (this.visibleSize >= this.max) {
      this.element.addClass('scroller-full');
    }
    else {
      this.element.removeClass('scroller-full');

      let barSize = this.visibleSize / this.max * 100;
      let difference = 0;

      if (barSize < 5) { // 5% minimum size?
        difference = 5 - barSize;
        barSize = 5;
      }
      let position = value / this.max * (100 - difference);
      this.barSize = (100-barSize) / 100;

      if (this.orientation === 'horizontal') {
        this.grip.css({
          left: `${position}%`,
          width: `${barSize}%`
        });
      }
      else {
        this.grip.css({
          top: `${position}%`,
          height: `${barSize}%`
        }); 
      }
    }
  }
}

module.exports = Scroller;