class DataProvider {

  get crossFilters() { return []; }
  set crossFilters(newValue) { }
  
  rowCount(stepDefinition, filterContext) {

  }

  rowData(stepDefinition, filterContext, index) {
    
  }

  groupingChartData(grouping, aggregationColumn, filterContext, configId) {

  }
  
  scatterChartData(dimensionColumn1, dimensionColumn2, filterContext, configId) {

  }

  historyChartData(tsColumn, filterContext, configId) {
    
  }

  sCurveChartData(bucketColumn, yAxis, color, filterContext, configId) {
    
  }

}

module.exports = DataProvider;