var tooltipElements = [], showTooltipTimeout = 0, hideTooltipTimeout = 0;
function removeTooltipElements() {
  tooltipElements.forEach(el => el.remove());
  tooltipElements = [];
}

class Tooltip {
  constructor(element, contentGenerator) {
    this.element = $(element)[0];
    this.contentGenerator = contentGenerator;
    if (!this.contentGenerator) {
      this.contentGenerator = () => {
        if (this.element.scrollWidth > this.element.offsetWidth) {
          return $("<div>").text($(this.element).text());
        }
        return null;
      };
    }

    $(element).off('mousemove.tooltip').on('mousemove.tooltip', e => this.createElement(e));
  }

  createElement(e) {
    clearTimeout(showTooltipTimeout);
    showTooltipTimeout = setTimeout(() => {
      removeTooltipElements();

      let $hoverContents = this.contentGenerator(e.toElement, e);
      if (!$hoverContents) {
        return null;
      }

      let $hover = $(`<div class="girdgrid-hover">`);
      $hover.append($hoverContents);
      tooltipElements.push($hover);

      $hover.appendTo(document.body);
      if (e.pageX < (0.5 * window.innerWidth)) {
        $hover.css({
          top: e.pageY - ($hover.outerHeight() / 2),
          right: null,
          left: e.pageX + 15
        });
      }
      else {
        $hover.css({
          top: e.pageY - ($hover.outerHeight() / 2),
          left: null,
          right: window.innerWidth - e.pageX + 10
        });
      }


      clearTimeout(hideTooltipTimeout);
      let remove = () => {
        clearTimeout(hideTooltipTimeout);
        hideTooltipTimeout = setTimeout(removeTooltipElements, 100);
        $(document.body).off('mousemove scroll wheel', remove);
      };
      showTooltipTimeout = setTimeout(() => {
        $(document.body).on('mousemove scroll wheel', remove);
      }, 1);
    }, tooltipElements.length === 0 ? 100 : 10);

  }
}

module.exports = Tooltip;