function importAll(r) {
  r.keys().forEach(r);
}
require('./public/preparePromise');
require('./public/index.js');
require('./public/run.js');
require('utilities/filters');
require('utilities/utils');
importAll(require.context('./public/', false, /\.js$/, 'sync'));
importAll(require.context('./public/states/auth/', true, /\.js$/, 'sync'));

importAll(require.context('./assets/img/', false, /thenumber-.+\.png$/, 'sync'));

// make sure all pdfs show up
importAll(require.context('./assets/', true, /\.pdf$/));

require('./public/services/SearchSelectService');
require('./public/services/PortfolioProgressService');
require('./public/states/root/controller.js');

// styles
require('./public/index.scss');

window.CodeMirror = require("codemirror/lib/codemirror.js");
require("codemirror/lib/codemirror.css");
require("codemirror/mode/javascript/javascript.js");
require("codemirror/mode/haxe/haxe.js");
require("codemirror/theme/monokai.css");
require("codemirror/keymap/sublime.js");
require("codemirror/addon/scroll/simplescrollbars.js");
require("codemirror/addon/scroll/simplescrollbars.css");
require("codemirror/addon/search/search.js");
require("codemirror/addon/search/searchcursor.js");
require("codemirror/addon/edit/closebrackets.js");
require("codemirror/addon/edit/matchbrackets.js");
require("codemirror/addon/fold/foldcode.js");
require("codemirror/addon/fold/foldgutter.js");
require("codemirror/addon/fold/brace-fold.js");
require("codemirror/addon/fold/foldgutter.css");

window.clues = require('clues');
window.reptile = require('./public/reptiles-client.js');

console.log('public.js loaded');