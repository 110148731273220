
function isPremarket() {
  if (document.location.href.indexOf('no_premarket') >= 0) {
    return false;
  }
  return document.location.host.startsWith('premarket') || document.location.href.indexOf('premarket_deals') >= 0;
}


/*global gtag */
if (isPremarket()) {

  // this is stupid as hell for like 7 reasons.
  // 1. Our index.html is static, but we need this to be on the page only for premarketing.... 
  // 2. document.write only works while the page is being loaded.  After that it'll clear everything
  // 3. These things load sooooo many external resources
  // 4. document.write was messing up some linter somewhere and I couldn't turn it off.
  // 5. So this is a way to confuse the linter, but still do what we wanted to do.
  // 6. I ran out of reasons
  // 7. But it's still really stupid.
  let writeKey = 'write';
  document[writeKey](`<script async src="https://www.googletagmanager.com/gtag/js?id=AW-674757902"></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'AW-674757902');
  </script>
  	
  <script type="text/javascript"> _linkedin_partner_id = "492033"; window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id); </script><script type="text/javascript"> (function(){var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})(); </script> <noscript> <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=492033&fmt=gif" /> </noscript>
  `);
}


angular.module('flashlightApp').controller('LoginPremarketController', ['$rootScope', '$scope', '$http', '$window', '$stateParams', '$timeout', 'stateParams2QueryParams', '$state', 'growl', '$location', function($rootScope, $scope, $http, $window, $stateParams, $timeout, stateParams2QueryParams, $state, growl, $location) {
  if (isPremarket()) {
    $scope.contactEmail = 'premarket@thenumber.com';
  }
  $timeout(()=> $('.auth input').on('input', ()=> $scope.errorMessage = ''));
  $scope.form = {
    premarket_code: $stateParams.code
  };

  $scope.submitInProgress = false;
  $scope.errCode = null;
  $scope.repeatedErrors = false;
  $scope.unsupportedBrowser = /MSIE|Trident\//.test($window.navigator.userAgent);

  $scope.loginDisabled = () => {
    if (!$scope.form || !$scope.form.premarket_code || !$scope.form.username) {
      return true;
    }
    if ($scope.showCode && (!$scope.form.authentication_code || !$scope.form.authentication_code.trim())) {
      return true;
    }
    return !$scope.form.premarket_code.trim() || !$scope.form.username.trim() || ($scope.tosList && $scope.tosList.some(t => !t.agree));
  };

  $scope.touLink = pdfKey => require(`../../../../assets/${pdfKey}.pdf`);

  $scope.submitClick = () => {
    if ($scope.submitInProgress) {
      return;
    }
    else if ($scope.repeatedErrors) {
      $scope.login(true);
    }
    else {
      $scope.login();
    }
  };
  $scope.submitLabel = () => {
    if ($scope.submitInProgress) {
      return 'PLEASE WAIT';
    }
    else if ($scope.repeatedErrors) {
      return 'REQUEST NEW VERIFICATION CODE';
    }
    else if ($scope.tosList) {
      return 'CONTINUE';
    }
    else {
      return 'LOGIN';
    }
  };
  $scope.showIcon = () => {
    if ($scope.submitInProgress) {
      return false;
    }
    else if ($scope.repeatedErrors) {
      return false;
    }
    else {
      return true;
    }
  };

  $scope.login = function(request_new_code = false) {
    // identify with fullstory
    $scope.submitInProgress = true;
    $scope.errors = null;
    $scope.errorCode = null;
    $scope.showCode = false;
    $scope.repeatedErrors = false;
    $scope.form.tos = $scope.form.agreeTos ? $scope.tosKey : null;
    if (request_new_code) {
      $scope.form.authentication_code = undefined;
    }
    if ($scope.tosList) {
      if ($scope.tosList.find(tosItem => !tosItem.agree)) {
        $scope.submitInProgress = false;
        return;
      }
      else {
        $scope.form.tos = $scope.tosList.map(t => t.key);
      }
    }

    setTimeout(() => {
      if (gtag) {
        gtag('event', 'login_attempt', {
          'send_to': 'AW-674757902',
          'value': $scope.form.username
        });
      }
    }, 1);

    $http.post('/auth/premarket_login', { user: $scope.form, token: $rootScope.token })
      .then(result => {
        $scope.submitInProgress = false;
        if (result.data.status === 'CODE_SENT') {
          $scope.showCode = true;
        }
        else {
          afterLogin();
          if (gtag) {
            gtag('event', 'login_success', {
              'send_to': 'AW-674757902',
              'value': $scope.form.username
            });
          }
        }
      })
      .catch(err => {
        $scope.submitInProgress = false;
        if (err && err.data) {
          let data = err.data;
          $scope.errorCode = data.message;
          if (data.xflash) {
            if ($scope.errorCode === 'MISSING_TOS') {
              $scope.tosList = err.data.xflash; 
            } else {
              $scope.errors = err.data.xflash;
            }
          }
          if ($scope.errorCode === 'MISSING_TOS') {
            $scope.showTos = true;
          }
          else if ($scope.errorCode === 'INVALID_VERIFICATION_CODE') {
            $scope.showCode = true;
          }
          else if ($scope.errorCode === 'REPEATED_INVALID_VERIFICATION_CODES') {
            $scope.errorCode = 'INVALID_VERIFICATION_CODE';
            $scope.repeatedErrors = true;
            $scope.showCode = false;
          }
          else if ($scope.errorCode === 'NON_CORPORATE_EMAIL') {
            $scope.errors = ['Please use your corporate email address.'];
            $scope.repeatedErrors = true;
          } 
          else {
            growl.error('A problem has occurred.  Please contact premarket@thenumber.com for help resolving the issue.');
          }
        }
      });
  };

  function afterLogin() {
    if ($stateParams.dest) {
      var dest = decodeURIComponent($stateParams.dest);
      if (!/^\//.test(dest) && !/^localhost/.test(dest) &&
          !/^http:\/\/localhost/.test(dest) &&
          !(new RegExp('^'+$window.location.hostname)).test(dest) &&
          !(new RegExp('^https:\\/\\/'+$window.location.hostname)).test(dest)) {
        throw new Error('Not following external redirect: ' + dest);
      }
      let targetUrl = decodeURIComponent($stateParams.dest);
      let url = new URL(targetUrl.replace(/\/#\//g, '/'));
      url.searchParams.set('code', $scope.form.premarket_code);
      $window.location.href = url.toString();
      if ($window.seleniumActive)
        $window.location.reload();
      return;
    }
    $stateParams.code = $scope.form.premarket_code;
    $window.location.href = stateParams2QueryParams('/navigator/view',$stateParams);
  }
}]);
