export function throttle(callback, delay = 250, firstDelay = 0) {
  let lastExecutionTime = 0;
  let executionTimer = null;
  return () => {
    const time = new Date().getTime();
    if (time - lastExecutionTime > delay) {
      clearTimeout(executionTimer);
      executionTimer = setTimeout(() => {
        callback();
        lastExecutionTime = new Date().getTime();
        executionTimer = null;
      }, firstDelay);
    } else if (!executionTimer) {
      executionTimer = setTimeout(() => {
        callback();
        lastExecutionTime = new Date().getTime();
        executionTimer = null;
      }, delay - (time - lastExecutionTime));
    }
  };
}

export function debounce(callback, timeout = 250) {
  let lastExecutionTime = 0;
  return function debounced(...args) {
    const context = this;
    const time = new Date().getTime();
    if (time - lastExecutionTime > timeout) {
      lastExecutionTime = new Date().getTime();
      callback.apply(context, args);
    }
  };
}

angular.module("flashlightApp").factory("UtilService", [
  "$http",
  function UtilService($http) {
    let supportedBucketingFields = [];
    $http.post("/api/report.data/supportedBucketingFields").then((res) => {
      supportedBucketingFields = res.data["report.data.supportedBucketingFields"];
    });

    return {
      availableBucketFields: () => supportedBucketingFields,
      debounce,
      throttle
    };
  }
]);
