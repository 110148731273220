const Capabilities = require('../Capabilities');
const ColumnEditor = require('./ColumnEditor');

class GroupingColumnEditor extends ColumnEditor {
  constructor(grid, elementToCover, currentColumn, resultCallback, multiSelect, skipAtomicItem, removeColumn, currentOpen, optional = false) {
    super(grid, elementToCover, currentColumn, resultCallback, multiSelect, removeColumn, currentOpen, optional);

    this.skipAtomicItem = skipAtomicItem;
  }

  availableChoices() {
    let filter = $(this.outlets.inputFilter).val().toLowerCase();
    let columns = this.grid.sortedColumns()
      .filter(column => column.capabilities.has(Capabilities.CAPABILITIES_GROUPING));

    if (!this.skipAtomicItem && this.grid.atomicItem) {
      columns.unshift(this.grid.atomicItem);
    }
    if (this.currentColumn) {
      columns.unshift(this.currentColumn);
    }

    return this.grid.groupingFilterAndSortCallback(columns, filter);
  }  

  formatChoice(choice) {
    return choice.groupingTitle;
  }
}

module.exports = GroupingColumnEditor;