const ContextMenu = require('../Config/ContextMenu');
const Capabilities = require('../Capabilities');
const DragHelper = require('../DragHelper.js');
const TableElement = require('./TableElement');
const StepDefinitionChart = require('../StepDefinition/StepDefinitionChart');
const ChartConfig = require('../Config/ChartConfig');

const CHART_EDGE_SIZE = 35;
const CHART_DEFAULT_HEIGHT = 300;
const CHART_MINIMUM_HEIGHT = 150;
const CHART_MAXIMUM_HEIGHT = 400;
const CHART_HEIGHT_KEY = 'chh';

class ChartElement extends TableElement {

  constructor(grid, step, filterContext) {
    super(grid);

    this.step = step;
    this.filterContext = filterContext;
    this.chart = this.step.chart();

    this.numberItemsPerRow = 1;
    this.rowIndex = 0;
  }

  getHeight() {
    return Math.max(...this.elementStack.elements.map(el => el.specifiedHeight() || 0)) || CHART_DEFAULT_HEIGHT;
  }

  specifiedHeight() {
    return this.grid.getState(CHART_HEIGHT_KEY, this.step.configId) || null;
  }

  setRowInfo(numberItemsPerRow, rowIndex) {
    this.numberItemsPerRow = numberItemsPerRow;
    this.rowIndex = rowIndex;
  }

  refresh() {
    this.executePageOut();
    this.executePageIn();
  }

  setElementStack(elementStack, chartBefore, chartAfter) {
    this.elementStack = elementStack;
    this.chartBefore = chartBefore;
    this.chartAfter = chartAfter;
  }

  pageIn() {
    this._$dom = $('<div class="chart-element table-element">');
    this._$grip = $(`<div class="chart-element-grip" draggable="true">⣿</div>`).appendTo(this._$dom);
    this._$config = $(`<div class="chart-element-config-button">&#9881;</div>`).appendTo(this._$dom);

    this._$config.on('click', () => {
      new ChartConfig(this.chart, this.grid, this.filterContext, this.step, this._$dom);
    });

    this.chartElement = this.chart.render(this._$dom, this.grid.dataProvider, this.filterContext, this.step, this.chartElement);
    this.createDragTargets();

    ContextMenu.attachToElement(this._$dom, () => ([
      {
        label: 'Remove',
        action: () => {
          this.grid.setStepDefinitions(this.grid.stepDefinitions.filter(s => s !== this.step));
          this.grid.refresh();
        } 
      },
      {
        label: 'Configure',
        action: () => {
          this._$config.trigger('click');
        }
      }
    ].filter(a=>a)));

    this._$grip.on('dragstart', event => {
      event.originalEvent.dataTransfer.setData('stepid', this.step.id);
      this.grid.dragStepHint(this.step);
      this.grid.setStepDefinitions(this.grid.stepDefinitions.filter(s => s !== this.step));
      this.grid.refresh();
    });

    this._$dom.on('dragleave', event => {
      // this bullshit sucks
      let offset = this._$dom.offset(),
          width = this._$dom.outerWidth(),
          height = this._$dom.outerHeight();

      if (event.clientX <= offset.left || event.clientX >= offset.left + width||
          event.clientY <= offset.top || event.clientY >= offset.top + height) {

        this._$dom.removeClass('mid-drag');
        
        this.setDragRight(false);
        this.setDragBottom(false);
        this.setDragLeft(false);
      }
    });

    this._$dom.on('drop', event => {
      this._$dom.removeClass('mid-drag');
      let newStepDefinition = this.grid.getStepDefinitionForDrop(event.originalEvent);
      let myIndex = 0;
      
      if (this._dragBottom) {
        let targetElement = this;
        while (targetElement.chartAfter) {
          targetElement = targetElement.chartAfter;
        }
        myIndex = this.grid.getIndexOfStep(targetElement.step) + 1;
      }
      else if (this._dragRight) {
        myIndex = this.grid.getIndexOfStep(this.step) + 1;
      }
      else if (this._dragLeft) {
        myIndex = this.grid.getIndexOfStep(this.step);
      }

      this.setDragRight(false);
      this.setDragBottom(false);
      this.setDragLeft(false);

      event.preventDefault();

      this.grid.setStepDefinitions(this.grid.stepDefinitions.slice(0, myIndex).concat(newStepDefinition).concat(this.grid.stepDefinitions.slice(myIndex)));
      this.grid.refresh();
      this.grid.afterAddStepDefinition(newStepDefinition);
    });

    this._$dom.on('dragenter', event => event.preventDefault());

    this._$dom.on('dragover', event => {
      // this bullshit sucks
      let offset = this._$dom.offset(),
          width = this._$dom.outerWidth(),
          height = this._$dom.outerHeight();

      let draggingStep = this.grid.dragStepHint();

      if (event.clientX <= offset.left + CHART_EDGE_SIZE) {
        if (!this.grid.checkDragCapability(event, Capabilities.CAPABILITIES_GROUPING)) {
          return;
        }

        // over left side
        this.setDragRight(false);
        this.setDragBottom(false);
        this.setDragLeft(true);
      }
      else if (event.clientY >= offset.top + height - CHART_EDGE_SIZE) {
        if (!this.grid.checkDragCapability(event, Capabilities.CAPABILITIES_GROUPING)) {
          return;
        }

        // over bottom
        this.setDragRight(false);
        this.setDragBottom(true);
        this.setDragLeft(false);
      }
      else if (event.clientX >= offset.left + width - CHART_EDGE_SIZE || draggingStep instanceof StepDefinitionChart) {
        if (!this.grid.checkDragCapability(event, Capabilities.CAPABILITIES_GROUPING)) {
          return;
        }

        // over right side
        this.setDragRight(true);
        this.setDragBottom(false);
        this.setDragLeft(false);
      }
      else {
        this.setDragRight(false);
        this.setDragBottom(false);
        this.setDragLeft(false); 

        if (event.originalEvent.dataTransfer.types.indexOf('columnid') < 0) {
          event.originalEvent.dataTransfer.dropEffect = "none";
          return;
        }
      }
      
      event.preventDefault();
      event.stopPropagation();
      event.originalEvent.dataTransfer.dropEffect = "move";

      if (event.originalEvent.dataTransfer.types.indexOf('columnid') >= 0) {
        let id = this.grid.dragColumnHintId();
        let matchedCapabilities = false;
        if (id) {
          let column = this.grid.findColumn(id);
          if (column) {
            let capabilities = column.capabilities;
            
            this.chart.chartInputs.forEach(chartInput => {
              if (capabilities.has(chartInput.requiredCapability)) {
                matchedCapabilities = true;
                this._$dom.addClass('capabilities-' + chartInput.requiredCapability);
              }
              else {
                this._$dom.removeClass('capabilities-' + chartInput.requiredCapability); 
              }
            });
          }
        }

        if (matchedCapabilities) {
          this._$dom.addClass('mid-drag');
        }
      }

      return true;
    });


    this._$dom.appendTo(this.grid.fullPane.container);


    let $verticalGrip = $(`<div class="vertical-grip">`).appendTo(this._$dom);

    $verticalGrip.on('mousedown', (event) => {
      if (event.button !== 0) {
        return;
      }

      let startingHeight = (this.specifiedHeight() || CHART_DEFAULT_HEIGHT);

      // let startWidth = this._lastLeftSize;
      DragHelper.doNSResizeHelper(event, startingHeight - CHART_MINIMUM_HEIGHT, CHART_MAXIMUM_HEIGHT - startingHeight, delta => {
        this.elementStack.elements.forEach(el => {
          this.grid.setState(CHART_HEIGHT_KEY, el.step.configId, startingHeight + delta);
          el.positionElement();
        });
      }, delta => {
        this.grid.layoutChanged();
        this.elementStack.elements.forEach(el => el.positionElement());
      });
    })

    this.positionElement();
  }

  setDragBottom(value) {
    if (this._dragBottom !== value) {
      this._dragBottom = value;
      if (value) {
        this._$dom.addClass('drag-bottom');
      }
      else {
        this._$dom.removeClass('drag-bottom');
      }

      if (this.elementStack) {
        this.elementStack.setDragBottom(value);
      }
    }
  }

  setDragRight(value) {
    if (this._dragRight !== value) {
      this._dragRight = value;
      if (value) {
        this._$dom.addClass('drag-right');
      }
      else {
        this._$dom.removeClass('drag-right');
      }

      if (this.chartAfter) {
        this.chartAfter.setDragLeft(value);
      }
    }
  }

  setDragLeft(value) {
    if (this._dragLeft !== value) {
      this._dragLeft = value;
      if (value) {
        this._$dom.addClass('drag-left');
      }
      else {
        this._$dom.removeClass('drag-left');
      }

      if (this.chartBefore) {
        this.chartBefore.setDragRight(value);
      }
    }
  }

  createDragTargets() {
    if (this._$dragTargets) {
      this._$dragTargets.remove();
    }
  
    this._$dragTargets = $(`<div class="chart-element-drag-targets">`).appendTo(this._$dom);
    this.chart.chartInputs.forEach(chartInput => {
      let currentValue = this.chart.getConfigDescription(chartInput);
      let $element = $(`<div class="chart-element-config">`).appendTo(this._$dragTargets);
      $element.addClass('required-capabilities-' + chartInput.requiredCapability)

      $element.append($('<div class="config-label">').text(`${chartInput.name}:`));
      $element.append($('<div class="config-value">').text(currentValue || 'None'));

      $element.on('drop', event => {
        event.preventDefault();
        event.stopPropagation();

        let id = parseInt(event.originalEvent.dataTransfer.getData('columnid'));
        if (!id) {
          return;
        }

        let column = this.grid.findColumn(id);
        this.chart.setConfig(chartInput, column);
        this.step.adjustCrossFilter(null); // cross filters make no sense if you have configured this
        this._$dom.removeClass('mid-drag');
        this.grid.refresh();
      });

      $element.on('dragenter', event => event.preventDefault());

      $element.on('dragover', event => {

        if (!this.grid.checkDragCapability(event, chartInput.requiredCapability)) {
          return;
        }

        event.preventDefault();
        event.stopPropagation();
        event.originalEvent.dataTransfer.dropEffect = "move";
        return true;
      });

    });

  }

  pageOut() {
    this._$dom.remove();
    this._$dom = null;
  }

  layoutChanged() {
    super.layoutChanged();
    this.positionElement();

    if (this.chartElement) {
      setTimeout(() => $(this.chartElement).trigger('resizechart'), 1);
    }
  }

  positionElement() {
    if (this._$dom) {
      if (this.rowIndex === this.numberItemsPerRow - 1) {
        this._$dom.addClass('last-chart');
      }
      else {
        this._$dom.removeClass('last-chart'); 
      }
      this._$dom.css({
        top: this.getTop(),
        left: `calc(((100% - ${this.leftPadding()}px) / ${this.numberItemsPerRow}) * ${this.rowIndex} + ${this.leftPadding()}px)`,
        width: `calc((100% - ${this.leftPadding()}px) / ${this.numberItemsPerRow})`,
        height: this.getHeight(),
        'z-index': this.grid.zindexOfStep(this.step)+1
      });
    }
  }

  getExpandedElement(currentElement) {
    return Promise.resolve().then(() => {
      let stepDefinition = this.grid.getStepDefinition(this.grid.getIndexOfStep(this.step) + 1);
      if (!stepDefinition) {
        return null;
      }
      if (currentElement && currentElement.step == stepDefinition) {
        return currentElement;
      }
      let expandedElement = stepDefinition.createTableElement(this.grid, this.filterContext);
      if (currentElement && expandedElement.softSetHeight) {
        expandedElement.softSetHeight(currentElement.getHeight());
      }
      return expandedElement;
    });
  }


}

module.exports = ChartElement;