const StepDefinition = require('./StepDefinition');
const ChartElement = require('../TableElement/ChartElement');
const ElementStack = require('../TableElement/ElementStack');
const ChartFactory = require('../Charts/ChartFactory');
const DEFINITION_TYPE_CHART = 'CHART';

class StepDefinitionChart extends StepDefinition {
  
  constructor(chartType) {
    super();
    this.chartType = chartType;
    this._chart = ChartFactory.createChart(this.chartType);
    this.configId = Math.random(); // better hash?
    this.type = 'StepDefinitionChart';
  }

  createTableElement(grid, filterContext) {
    this._grid = grid;
    return new ElementStack(grid, new ChartElement(grid, this, filterContext));
  }

  insetAmount() { return 0; }

  chart() {
    return this._chart;
  }

  title() {
    return (this._chart && this._chart.title) ? this._chart.title() : this.chartType;
  }

  adjustCrossFilter(crossFilter) {
    if (!crossFilter && !this._crossFilter) {
      return;
    }

    if (!crossFilter && this._crossFilter) {
      this._crossFilter = null;
    }
    else {
      let fixed = Object.assign({}, crossFilter, { configId: this.configId });
      if (JSON.stringify(fixed) === JSON.stringify(this._crossFilter)) {
        return;
      }
      this._crossFilter = fixed;
    }

    if (this._grid) {
      this._grid.propagateCrossFilters();
    }
  }

  serializeConfig() {
    return {
      type: DEFINITION_TYPE_CHART,
      configId: this.configId,
      chartType: this.chartType,
      chartConfig: this._chart.serializeConfig(),
      crossFilter: this._crossFilter
    }
  }
}

StepDefinition.registerStepDefinitionDeserializer(DEFINITION_TYPE_CHART, (grid, config) => {
  let result = new StepDefinitionChart(config.chartType);
  result.configId = config.configId || (Math.random());
  result._crossFilter = config.crossFilter;
  result._chart.deserializeConfig(grid, config.chartConfig);
  return result;
});

module.exports = StepDefinitionChart;