const Chart = require('chart.js');
const annotationPlugin = require('chartjs-plugin-annotation')
const Capabilities = require('../Capabilities');
const deepMerge = require('./deepMerge');
const GirdGridChart = require('./Chart');
Chart.plugins.register(annotationPlugin);

class ChartJSChart extends GirdGridChart {
  constructor(config, inputs) {
    super(config, inputs);
  }

  optionsAndData(dataProvider, filterContext, step, canvas) {
    return Promise.resolve({
      options: {},
      data: {},
      type: 'type'
    });
  }

  adjustDataForBounds(width, height, data, lastData) {
    return lastData;
  }

  truncateData(data, maxValues, column) {
    let ds = data.datasets[0];
    let dataMap = ds.data.map((d, i) => ({
      label: data.labels[i],
      data: d,
      backgroundColor: ds.backgroundColor[i],
      borderColor: ds.borderColor[i],
      originalIndex: i,
      include: true
    }));

    let isAverage = column.capabilities.has(Capabilities.CAPABILITIES_NORMALIZED_AGGREGATION);
    let otherValue;
    if (isAverage) {
      let items = dataMap.slice(maxValues);
      items.forEach(i => i.include = false);
      otherValue = items.reduce((acc, d) => acc + d.data, 0);
      otherValue /= data.datasets[0].data.length - maxValues;

    }
    else {
      dataMap.sort((a, b) => b.data - a.data);
      let items = dataMap.slice(maxValues);
      items.forEach(i => i.include = false);
      otherValue = items.reduce((acc, d) => acc + d.data, 0);
      dataMap.sort((a, b) => a.originalIndex - b.originalIndex);
    }

    dataMap = dataMap.filter(d => d.include);

    return {
      labels: dataMap.map(d => d.label).concat("Other"),
      datasets: [{
        label: data.datasets[0].label,
        data: dataMap.map(d => d.data).concat(otherValue),
        backgroundColor: dataMap.map(d => d.backgroundColor).concat('rgba(128,128,128,0.4)'),
        borderColor: dataMap.map(d => d.borderColor).concat('rgba(128,128,128,0.6)'),
        borderWidth: 1
      }]
    }
  }

  render(container, dataProvider, filterContext, step, previousRender) {
    let element = previousRender;
    if (!element || !element._chart) {
      if (element && !element._chart) {
        $(element).remove();
      }
      element = $('<canvas>').appendTo(container)[0];
    }
    else {
      element = $(element).appendTo(container)[0];
    }

    let chart = null;

    let optionsPromise = this.optionsAndData(dataProvider, filterContext, step, element);

    if (!optionsPromise) {
      return element;
    }

    element._optionsPromise = optionsPromise;
    $(container).find('.chart-error').remove();
    optionsPromise.then(optionsAndData => {
      if (element._optionsPromise !== optionsPromise) {
        return;
      }
      
      let options = optionsAndData.options,
          data = optionsAndData.data,
          originalData = {},
          lastData = data;

      deepMerge(originalData, data);

      data = this.adjustDataForBounds(element.offsetWidth, element.offsetHeight, data, lastData);

      if (element._chart) {
        chart = element._chart;
        deepMerge(element._chart.data, data);
        deepMerge(element._chart.options, options);
        element._chart.update();
        element._chart.resize();
      }
      else {
        chart = new Chart(element, {
          type: optionsAndData.type,
          data: Object.assign({}, data),
          options: Object.assign({}, options)
        });
        element._chart = chart; 
      }

      $(element).off('resizechart').on('resizechart', () => {
        let newData = this.adjustDataForBounds(element.offsetWidth, element.offsetHeight, originalData, lastData);
        if (newData !== lastData) {
          deepMerge(element._chart.data, newData);
          lastData = newData;
          element._chart.update();
        }
        chart.resize();
      });
    }, reason => {
      $(element).replaceWith(`<div class="chart-error"><div class="error">${reason.message || reason}</div></div>`);
    });

    return element;
  }
}

module.exports = ChartJSChart;