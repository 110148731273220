angular.module('flashlightApp').config(["growlProvider", "$logProvider", "$provide", "$mdThemingProvider", "$mdDateLocaleProvider", "$animateProvider", function(growlProvider, $logProvider, $provide, $mdThemingProvider, $mdDateLocaleProvider, $animateProvider) {
  $animateProvider.classNameFilter(/^((?!(fa-spin)).)*$/);
  $logProvider.debugEnabled(true);

  $mdThemingProvider.definePalette('amazingPaletteName', {
    '50': 'ffebee',
    '100': 'ffcdd2',
    '200': '#05AE7D',
    '300': '#05AE7D',
    '400': '#05AE7D',
    '500': '#05AE7D',
    '600': '#05AE7D',
    '700': '#05AE7D',
    '800': '#05AE7D',
    '900': '#05AE7D',
    'A100': '#05AE7D',
    'A200': '#05AE7D',
    'A400': '#05AE7D',
    'A700': '#05AE7D',
    'contrastDefaultColor': 'light', 
    'contrastDarkColors': ['50', '100',
     '200', '300', '400', 'A100'],
    'contrastLightColors': undefined 
  });
  $mdThemingProvider.theme('default')
    .primaryPalette('amazingPaletteName').accentPalette('indigo');

  growlProvider.globalTimeToLive({success: 3000, error: 5000, warning: 5000, info: 4000});

  // add next and toParams to $state (for resolves to reference)
  $provide.decorator('$state', ["$delegate", "$rootScope", function($delegate, $rootScope) {
    $rootScope.$on('$stateChangeStart', function(event, state, params) {
      $delegate.next = state;
      $delegate.toParams = params;
    });
    return $delegate;
  }]);
}]);
