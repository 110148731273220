const Promise = window.Promise = require('bluebird');
Promise.config({ cancellation: true, warnings: false, longStackTraces: false });
Promise.onPossiblyUnhandledRejection(function(err) {
  // filter angular cancelled promises
  if (err === 'canceled')
    return;
  // filter ui-router noise
  if (err && err.message && err.message.match(/transition (superseded|prevented|aborted|failed)/))
    return;
  // log to rollbar if stack exists
  if (err && err.stack && window.Rollbar)
    window.Rollbar.error(err);
  console.log('Unhandled rejection', err);
});

window.clues = require('clues');
