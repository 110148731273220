class Pane {
  constructor(grid, name, dontAllowSticky) {
    this._lastTop = 0;
    this._lastLeft = 0;
    this._hasScrollWidth = false;
    this.grid = grid;
    this.name = name;
    this.element = $(`<div class="pane ${name}">`).appendTo(grid.element);
    this.scrollContainer = $('<div class="scroll-container">').appendTo(this.element);
    this.scrollContainer.on('scroll', this.handleScroll.bind(this));
    this.container = $('<div class="grid-container">').appendTo(this.scrollContainer);

    if (dontAllowSticky !== true) {
      this.outerStickyContainer = $('<div class="outer-sticky-container">').appendTo(this.element);
      this.stickyContainer = $('<div class="sticky-container">').appendTo(this.outerStickyContainer);
    }
  }

  handleScroll() {
    let top = $(this.scrollContainer).scrollTop();
    let left = $(this.scrollContainer).scrollLeft();

    if (!this._hasScrollWidth) {
      left = null;
    }

    if (top != this._lastTop || left != this._lastLeft) {
      this._lastTop = top;
      this._lastLeft = left;

      if (this.outerStickyContainer) {
        this.outerStickyContainer.scrollLeft(left);
      }
      
      this.grid.scrollTo(top, left, true);
    }

    // TODO: this should probably be deferred... but it's kinda okay for now
    //$(this.outerStickyContainer).scrollLeft($(this.scrollContainer).scrollLeft());
  }

  setHeight(height) {
    this.container.css('height', height);
  }

  setWidth(width) {
    this.element.css('width', width);
  }

  setScrollWidth(width) {
    this._hasScrollWidth = true;
    this.container.css('width', width);
    if (this.stickyContainer) {
      this.stickyContainer.css('width', width);
    }
  }

  scrollToLeft(left) {
    this.scrollTo(this._lastTop, left);
  }

  scrollToTop(top) {
    this.scrollTo(top, this._lastLeft);
  }

  scrollTo(top, left) {
    if (!this._hasScrollWidth) {
      left = null;
    }
    if (this._lastTop != top || this._lastLeft != left) {
      this._lastTop = top;
      this._lastLeft = left;
      this.scrollContainer.scrollTop(top);
      this.scrollContainer.scrollLeft(left);

      if (this.outerStickyContainer) {
        this.outerStickyContainer.scrollLeft(left);
      }
    }
  }

}

module.exports = Pane;