angular.module('flashlightApp').factory('PortfolioProgressService', ["$http", "$timeout", "$interval", function($http, $timeout, $interval) {
  const FIVE_SECONDS = 5 * 1000;

  class PortfolioProgress {

    constructor() {
      this._portfolios = [];
      this.listeners = new Map();
      this.checking = false;

      // Mocks used for testing only
      this.nMocks = 10;
      this.mockChunkSize = 80;
      this.mocks = Array(this.nMocks).fill(0).map((d,i) => ({
        display: `ZUNI 2006-OA1 B${i} - 98981YAG7 - ZUNI06-1`,
        statusPct: 0,
        size: Math.round(Math.random() * 500)
      }));
    }

    check() {
      if (! this.checking) {
        this.checking = true;
        this.poll();
      }
    }

    poll() {
      $http.get('/api/portfolio_progress').then(d => {
        let data = d.data.portfolio_progress;
        let _start = this._portfolios.length === 0 && Array.isArray(data);
        let _end   = this._portfolios.length !== 0 && !Array.isArray(data);
        this._portfolios = [];
        if (Array.isArray(data)) {
          this._portfolios = data;
          $timeout(() => {
            this.poll();
          }, FIVE_SECONDS);
        } else {
          this.checking = false;
        }
        if (_start) {
          this.start();
        }
        if (_end) {
          this.end();
        }
      });
    }

    get portfolios() {
      return this._portfolios.filter((d,i) => d.statusPct !== 0 || i === 0);
    }

    start() {
      for (let [key, listener] of this.listeners) {       // eslint-disable-line no-unused-vars
        let cb = $interval(listener.fn, listener.interval);
        listener.cb = cb;
      }
    }

    end() {
      for (let [key, listener] of this.listeners) {       // eslint-disable-line no-unused-vars
        // flush a last update
        listener.fn();
        $interval.cancel(listener.cb);
        delete listener.cb;
      }
    }

    // register a listener to be triggered whenever there is progress
    // to be seen.

    register(key, fn, interval) {
      let listener = { fn: fn, interval: interval };
      this.listeners.set(key, listener);
      if (this.portfolios.length !== 0) {
        listener.cb = $interval(listener.fn, listener.interval);
      }
    }

    unregister(key) {
      let listener = this.listeners.get(key);
      if (listener) {
        $interval.cancel(listener.cb);
        this.listeners.delete(key);
      }
    }

    mock() {
      this.mocks = this.mocks.map(d => {
        d.statusPct = Math.min(100,Math.round(((d.statusPct * 0.01 * d.size + Math.random() * this.mockChunkSize)/d.size)*100));
        return d;
      }).filter(d => d.statusPct < 100);
      return Promise.resolve().then(() => {
        if (this.mocks.length > 0) {
          return { data: { portfolio_progress: this.mocks }};
        } else {
          return { data: { portfolio_progress: { error: true }}};
        }
      });
    }
  }
  
  return new PortfolioProgress();
}]);
