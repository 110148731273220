module.exports = [{
    title: "Day",
    groupingTitle: "Day",
    key: "Day",
    id: 0
  },
  {
    title: "Week",
    groupingTitle: "Week",
    key: "Week",
    id: 1
  },
  {
    title: "Month",
    groupingTitle: "Month",
    key: "Month",
    id: 2
  },
  {
    title: "Quarter",
    groupingTitle: "Quarter",
    key: "Quarter",
    id: 3
  },
  {
    title: "Year",
    groupingTitle: "Year",
    key: "Year",
    id: 4
  }
]