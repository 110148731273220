const ColorGenerator = require('../ColorGenerator');
const Capabilities = require('../Capabilities');
const ChartFactory = require('./ChartFactory');
const ChartInput = require('./ChartInput');
const ChartJSChart = require('./ChartJSChart');

const PIE_CHART_GROUPING = new ChartInput('grouping', 'Slice Grouping', Capabilities.CAPABILITIES_GROUPING);
const PIE_CHART_Y_AXIS = new ChartInput('yAxis', 'Slice Size', Capabilities.CAPABILITIES_SUMMING_AGGREGATION);

class PieChart extends ChartJSChart {
  constructor(config) {
    super(config, [PIE_CHART_GROUPING, PIE_CHART_Y_AXIS]);
  }

  title() {
    return 'Pie';
  }

  getConfigDescription(chartInput) {
    let result = this.getConfig(chartInput);
    if (result) {
      if (chartInput === PIE_CHART_GROUPING) {
        return result.groupingTitle;
      }
      return result.title;
    }
  }

  adjustDataForBounds(width, height, data, lastData) {
    let maxValues = Math.max(Math.floor((height - 75) / 20), 3);
    let cols = Math.floor(width / 400);
    maxValues *= cols || 1;

    if (data.labels.length <= maxValues) {
      return data;
    }
    if (lastData.labels.length === maxValues) {
      return lastData;
    }
    return this.truncateData(data, maxValues, this.getConfig(PIE_CHART_Y_AXIS));
  }

  optionsAndData(dataProvider, filterContext, step) {

    let groupingColumn = this.getConfig(PIE_CHART_GROUPING);
    let yAxisColumn = this.getConfig(PIE_CHART_Y_AXIS);

    if (!groupingColumn || !yAxisColumn) {
      return;
    }

    return dataProvider.groupingChartData(groupingColumn, yAxisColumn, filterContext, step.configId, 'pie').then(chartData => {
      chartData = chartData.filter(data => data.value || data.c).map(d => {
        d = Object.assign({}, d); // make a copy of the data
        d.key = String(groupingColumn.formatValue(d.key));
        return d;
      });
      let keys = chartData.map(data => data.key);
      let data = chartData.map(data => data.value);

      let hasComparison = false;
      let comparisonData = chartData.map(data => {
        if (data.c) {
          hasComparison = true;
        }
        return data.c;
      });

      let crossFilter = step.crossFilter(),
          crossFilterKey = crossFilter && crossFilter.key && crossFilter.key.toString();

      function getColor(key, isComparison) {
        if (crossFilterKey && crossFilterKey != key.toString()) {
          return 'rgba(128,128,128,0.4)'; 
        }

        return ColorGenerator.getColor(groupingColumn, key, isComparison ? 0.4 : hasComparison ? 0.7 : 0.5);
      }

      return {
        data: {
          labels: keys.map(v => v.length > 20 ? v.substring(0,17) + '...' : v),
          datasets: [{
            label: yAxisColumn.title,
            data: data,
            backgroundColor: keys.map(key => getColor(key)),
            borderColor: keys.map(key => getColor(key)),
            borderWidth: 1
          },
          hasComparison ? {
            label: yAxisColumn.title,
            data: comparisonData,
            backgroundColor: keys.map(key => getColor(key, true)),
            borderColor: keys.map(key => 'rgb(70,70,70)'),
            borderWidth: 4
          } : null].filter(a => a)
        },
        options: {
          onClick: function(event, [chartElement]) {
            if (!chartElement) {
              step.adjustCrossFilter();
            } else {
              let label = String(chartElement._chart.config.data.labels[chartElement._index]);
              let d = chartData.find(d => d.key == label);
              if (!d || d.key == crossFilterKey) {
                step.adjustCrossFilter();
              } else {
                step.adjustCrossFilter(d);
              }
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          title: { display: true, text: `${yAxisColumn.title} by ${groupingColumn.title}` },
          legend: { display: true, position: 'right' },
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                return yAxisColumn.formatValue(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]);
              },
              title: function(tooltipItem, data) {
                return data.labels[tooltipItem[0].index];
              }
            }
          }
        },
        type: hasComparison ? 'doughnut' : 'pie'
      }
    });
  }
}

ChartFactory.register('pie', PieChart);