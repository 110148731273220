angular.module('flashlightApp').controller('VerifyController', ["$rootScope", "$scope", "$http", "$window", "$stateParams", "$timeout", "growl", "$state", "stateParams2QueryParams", "mfaDisabled", function($rootScope, $scope, $http, $window, $stateParams, $timeout, growl, $state, stateParams2QueryParams, mfaDisabled) {
  $scope.termsOfService = $stateParams.termsOfService;
  $scope.mfaDisabled = mfaDisabled;
  $timeout(()=> $('.auth input').on('input', ()=> $scope.errorMessage = ''));
  $scope.form = { verificationToken: $stateParams.verificationToken, token: $rootScope.token, acceptedTOS: false };
  $scope.formCtrl = {};
  $scope.methodIcons = {
    'verified': 'fa-circle-check',
    'email': 'fa-envelope',
    'phone': 'fa-mobile-screen-button',
    'cert': 'fa-mobile-screen-button',
    'googleAuthenticator': 'fa-mobile-screen-button',
    'restart': 'fa-building-columns',
    '...': 'fa-mobile-screen-button'
  };

  $scope.verifyMethods = ['email'].concat($rootScope.customer&&$rootScope.customer.user.mfa||[])
    .slice(0,2)
    .map(d => ({ name: d, verified: (JSON.parse($stateParams.verified||'{}'))[d] }));

  // add placeholder second step
  if ($scope.verifyMethods.length === 1 && !mfaDisabled)
    $scope.verifyMethods.push({
      name: '...',
    });

  $scope.currentMethod = ($scope.verifyMethods
    .filter(d => !d.verified)[0]||{}).name;

  $scope.goHome = (delay=1) => {
    setTimeout(() => {
      $window.location.href = '/';
      $window.location.hash = '';
      setTimeout(() => $window.location.reload(), 1);
    }, delay);
  }

  if (!$scope.currentMethod) {
    $scope.goHome()
  }

  $scope.verifyEmail = function () {
   if ($scope.form.password !== $scope.form.passwordConfirm) {
      growl.warning('Passwords must match.');
      $('input[type="password"]').eq(0).focus();
      return;
    }
    if ($scope.verifyInProgress) return;
    $scope.verifyInProgress = true;
    return $http.post('/auth/verify', $scope.form).then(() => {
      growl.success('Successful login');
      $scope.goHome(500);
    }, err => {
      if (err.data.message === 'MFA_REQUIRED_PHONE_PHONE') {
        $scope.verifyMethods = [ { name: "email", verified: true }, { name: "phone", verified: false }];
        $scope.currentMethod = "phone";
        $scope.form.username = err.data.username;  // Passed back by server to use for login
        $scope.verifyInProgress = false;  
        return;
      }
      if (err.data.message === 'INVALID_VERIFICATION_TOKEN') {
        $state.go('root.login');
        try { growl.warning('Password already set or token has expired'); } catch(e) { }
        return;
      }
      $scope.verifyInProgress = false;
      var xflash = err.data.xflash;
      if (xflash) $scope.errorMessage = Object.keys(xflash).map(d => xflash[d])[0];
      else $scope.errorMessage = err.data.message;
    });
  };

  $scope.sendPhoneCode = (voice) => {
    $scope.submitInProgress = true;
    return $http.post('/auth/send_code', {user:$scope.form, token:$rootScope.token, voice:voice })
      .then(() => {
        $scope.submitInProgress = false;
        growl.success('An authorization code has been sent to your mobile device. Please enter it here.', {ttl:60000});
      }, err => {
        $scope.submitInProgress = false;
        var xflash = err.data.xflash;
        if (xflash) $scope.errorMessage = Object.keys(xflash).map(d => xflash[d])[0];
        else $scope.errorMessage = err.data.message;
      });
  };

  $scope.verifyPhone = () => {
    if ($scope.verifyInProgress) {
      return;
    }
    $scope.verifyInProgress = true;
    return $http.post('/auth/login', {user:$scope.form, token:$rootScope.token })
      .then(() => {
        growl.success('Successful login');
        $scope.goHome(500); 
      }, err => {
        $scope.verifyInProgress = false;
        if (err.data.message === 'INVALID_PHONE_AUTH_CODE' || err.data.message === 'MFA_REQUIRED_PHONE_PHONE') {
          try { growl.error('Invalid Phone Code. Please Try Again.'); } catch(e) { }
          return;
        }
        var xflash = err.data.xflash;
        if (xflash) $scope.errorMessage = Object.keys(xflash).map(d => xflash[d])[0];
        else $scope.errorMessage = err.data.message;
      });
  };

  $scope.getCert = (download) => {
    return (download&&$http.post('/auth/send_code',{token:$rootScope.token})||Promise.resolve())
      .then(() => $http.post('/auth/mfa/cert/get', { token:$rootScope.token, base64:download }))
      .then(d => {
        $('#downloadedCert').val('true').trigger('input');
        if (!download) {
          $scope.verifyCert();
          return; 
        }
        var link = $('<span>If the certificate did not download automatically, <a download="'+d.data.filename+'" href="'+d.data.content+'" target="_new">download it now</a>.');
        $timeout(() => {
          $('.downloadLink').html('').append(link);
          link[0].click();
        });
      }, err => {
        var xflash = err.data.xflash;
        if (xflash) $scope.errorMessage = Object.keys(xflash).map(d => xflash[d])[0];
        else $scope.errorMessage = err.data.message;
      });
  };

  $scope.verifyCert = () => {
    $scope.verifyInProgress = false;
    let verified = JSON.parse($stateParams.verified||'{}');
    verified.cert = true;
    $state.go('.', { verified: JSON.stringify(verified) }, { reload: true });
  };

}]);
