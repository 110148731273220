class StepDefinition {

  constructor() {
    this.id = ++StepDefinition.__ID_COUNTER;
  }

  createTableElement(grid, filterContext) {
    return [];
  }

  insetAmount() { return 15; }

  leftSize() { return 0; }

  rightSize() { return 0; }

  createHeader(draggable) {
    let result = $(`<div class="cell cell-header cell-micro-header ${draggable ? '' : 'fixed-position'}" ${draggable ? 'draggable="true"' : ''}>`).text(this.title());
    return result;
  }

  title() {
    return '';
  }

  crossFilter() {
    return this._crossFilter;
  }

  adjustCrossFilter(crossFilter) {
    this._crossFilter = crossFilter;
  }

  serializeConfig() {
    throw 'NOT_IMPLEMENTED';
  }

  static registerStepDefinitionDeserializer(type, handler) {
    StepDefinition._stepDefinitionDeserializers[type] = handler;
  }

  static deserializeConfig(grid, config) {
    let type = config.type;
    return StepDefinition._stepDefinitionDeserializers[type](grid, config);
  }

}
StepDefinition._stepDefinitionDeserializers = {};

StepDefinition.__ID_COUNTER = 0;

module.exports = StepDefinition;