var ConfigsOpen = [];

class BaseConfig {
  constructor(element, elementToCover, width, height, screenBody) {
    this._$elementToCover = elementToCover;
    this.width = width;
    this.height = height;

    if (screenBody) {
      this._$screen = $('<div class="gird-grid-config-screen">').appendTo(document.body);
    }

    this._$element = $(element)
                        .addClass('gird-grid-config')
                        .css({
                          width,
                          height
                        })
                        .appendTo(document.body);
    this.outlets = [...$(this._$element).find('[outlet]')].reduce((acc, el) => {
      acc[$(el).attr('outlet')] = el;
      return acc;
    }, {});
    this.position();

    setTimeout(() => {
      this.position();
      ConfigsOpen.push(this);
      this.boundClose = el => this.close(el); // can't do a .bind here in case subclasses override close... :(
      $(document.body).on('mousedown mousewheel contextmenu', this.boundClose);
      $(window).on('resize', this.boundClose);
    }, 1);
  }

  close(el) {
    let myIndex = ConfigsOpen.indexOf(this);
    if (el && myIndex !== ConfigsOpen.length - 1) {
      return; // only close if topmost config if this comes from an event
    }

    if (el) { 
      let stop = this._$element[0];
      let elementToCheck = el.target;
      while (elementToCheck) {
        if (elementToCheck === stop) {
          return;
        }
        elementToCheck = elementToCheck.parentElement;
      }
    }

    for (var i = ConfigsOpen.length - 1; i > myIndex; i--) {
      ConfigsOpen[i].close();
    }

    if (this._$screen) {
      this._$screen.remove();
    }

    ConfigsOpen = ConfigsOpen.filter(f => f !== this);
    this._$element.remove();
    $(document.body).off('mousedown mousewheel contextmenu', this.boundClose);
    $(window).off('resize', this.boundClose);
    
    // hacky as shit
    if (ConfigsOpen.length === 0) {
      $('.cell.active').removeClass('active');
    }
  }

  position() {
    if (this._$elementToCover) {
      let position = this._$elementToCover.offset();

      let maxTop = window.scrollY + window.innerHeight - (this.height === 'auto' ? 300 : this.height);
      let maxLeft = window.scrollX + window.innerWidth - (this.width === 'auto' ? 300 : this.width);

      this._$element.css({
        left: Math.min(maxLeft, position.left),
        top: Math.min(maxTop, position.top)
      });
    }
  }
}

module.exports = BaseConfig;