const Capabilities = require('../Capabilities');
const ChartFactory = require('./ChartFactory');
const ChartInput = require('./ChartInput');
const ChartJSChart = require('./ChartJSChart');

const SCATTER_X_AXIS = new ChartInput('xAxis', 'X-Axis', Capabilities.CAPABILITIES_NUMBER_LABEL);
const SCATTER_Y_AXIS = new ChartInput('yAxis', 'Y-Axis', Capabilities.CAPABILITIES_NUMBER_LABEL);

class ScatterChart extends ChartJSChart {
  constructor(config) {
    super(config, [SCATTER_X_AXIS, SCATTER_Y_AXIS]);
  }

  title() {
    return 'Scatter';
  }

  optionsAndData(dataProvider, filterContext, step, canvas) {
    let x = this.getConfig(SCATTER_X_AXIS);
    let y = this.getConfig(SCATTER_Y_AXIS);
    
    if (!x || !y) {
      return;
    }

    let crossFilter = step.crossFilter(), hasCrossFilter = !!crossFilter;

    return dataProvider.scatterChartData(x, y, filterContext, step.configId).then(chartData => {

      let sumX = 0,
        sumY = 0,
        smallestX = Infinity,
        largestX = -Infinity,
        rSquared = 0,
        b0 = 0,
        b1 = 0;

      let primary = chartData.p || chartData;

      let data = primary.map(d => {
        sumX += d.d1;
        sumY += d.d2;
        smallestX = Math.min(smallestX, d.d1);
        largestX = Math.max(largestX, d.d1);
        return { x: d.d1, y: d.d2 };
      });

      let comparisonData = [];
      if (chartData.c) {
        comparisonData = chartData.c.map(d => ({
          x: d.d1,
          y: d.d2,
          comparison: true
        }));

        data = comparisonData.concat(data);
      }

      // handle dragging
      let isDragging = false,
        startDragX, startDragY, mouseoutTimeout = null, chartInstance = null;

      let eventCatcherAnnotation = {
        drawTime: 'beforeDatasetsDraw', // overrides annotation.drawTime if set
        type: 'box',
        borderWidth: 0,
        backgroundColor: 'transparent',

        // Fires when the user clicks this annotation on the chart
        // (be sure to enable the event in the events array below).
        onMousedown: function(e) {
          chartInstance = this.chartInstance || chartInstance;
          if (!chartInstance) {
            return;
          }

          isDragging = true;
          startDragX = chartInstance.scales['x-axis-0'].getValueForPixel(e.offsetX);
          startDragY = chartInstance.scales['y-axis-0'].getValueForPixel(e.offsetY);

          let pa = chartInstance.options.annotation.annotations[0];
          pa.xMin = startDragX;
          pa.xMax = startDragX;
          pa.yMin = startDragY;
          pa.yMax = startDragY;
          pa.empty = true;
          chartInstance.update();
        },
        onMousemove: function(e) {
          chartInstance = this.chartInstance || chartInstance;
          if (!chartInstance) {
            return;
          }

          e.stopPropagation();
          if (isDragging) {
            let xAxis = chartInstance.scales['x-axis-0'];
            let yAxis = chartInstance.scales['y-axis-0'];
            let x = xAxis.getValueForPixel(e.offsetX);
            let y = yAxis.getValueForPixel(e.offsetY);

            x = Math.max(Math.min(x, xAxis.max), xAxis.min);
            y = Math.max(Math.min(y, yAxis.max), yAxis.min);

            let pa = chartInstance.options.annotation.annotations[0];
            pa.xMin = Math.min(startDragX, x);
            pa.yMin = Math.min(startDragY, y);
            pa.xMax = Math.max(startDragX, x);
            pa.yMax = Math.max(startDragY, y);
            pa.empty = false;
            chartInstance.update();
          }
        },
        onMouseup: function(e) {
          e.stopPropagation();
          chartInstance = this.chartInstance || chartInstance;
          if (!chartInstance) {
            return;
          }

          if (isDragging) {
            clearTimeout(mouseoutTimeout);
            isDragging = false;

            let newCrossFilter = null;

            let pa = chartInstance.options.annotation.annotations[0];
            if (!pa.empty) {

              // make sure there is data
              let d = chartInstance.data.datasets[0];
              let dataExists = ((d && d.data) || []).find(d => d.x >= pa.xMin && d.x <= pa.xMax &&
                d.y >= pa.yMin && d.y <= pa.yMax);

              if (dataExists) {
                newCrossFilter = {
                  xMin: pa.xMin,
                  xMax: pa.xMax,
                  yMin: pa.yMin,
                  yMax: pa.yMax,
                  x: chartInstance.scales['x-axis-0'].options.columnKey,
                  y: chartInstance.scales['y-axis-0'].options.columnKey
                };
              }
              else {
                newCrossFilter = null;
                pa.empty = true;
                pa.yMax = pa.yMin;
                pa.xMax = pa.xMin;
                chartInstance.update();
              }
            }

            step.adjustCrossFilter(newCrossFilter);
          }
        },
        onMouseout: function(e) {
          chartInstance = this.chartInstance || chartInstance;
          if (isDragging) {
            clearTimeout(mouseoutTimeout);
            mouseoutTimeout = setTimeout(() => {
              eventCatcherAnnotation.onMouseup(e);
            }, 1000);
          }
        }

      };
      let progressAnnotation = {
        drawTime: 'beforeDatasetsDraw', // overrides annotation.drawTime if set
        type: 'box',
        empty: true,
        borderColor: '#497b83',
        borderWidth: 2,
        backgroundColor: '#497b8333',
        xMin: crossFilter ? crossFilter.xMin : data ? data[0].x : 0,
        xMax: crossFilter ? crossFilter.xMax : data ? data[0].x : 0,
        yMin: crossFilter ? crossFilter.yMin : data ? data[0].y : 0,
        yMax: crossFilter ? crossFilter.yMax : data ? data[0].y : 0,

        // ID of the X scale to bind onto
        xScaleID: 'x-axis-0',

        // ID of the Y scale to bind onto
        yScaleID: 'y-axis-0',
        onMousemove: eventCatcherAnnotation.onMousemove,
        onMouseup: eventCatcherAnnotation.onMouseup,
        onMouseout: eventCatcherAnnotation.onMouseout,
        onMousedown: eventCatcherAnnotation.onMousedown
      };


      let datasets = [{
        type: 'line',
        label: 'Line Dataset',
        data: data,
        pointBackgroundColor: data.map(d => {
          if (!hasCrossFilter || 
              (d.x >= progressAnnotation.xMin && d.x <= progressAnnotation.xMax &&
              d.y >= progressAnnotation.yMin && d.y <= progressAnnotation.yMax)) {
            if (d.comparison) {
              return 'rgba(255, 143, 15, 0.6)';  
            }
            return 'rgba(54, 162, 235, 1)';
          }
          return 'rgba(128, 128, 150, 0.7)';
        }),
        showLine: false
      }];

      if (data.length >= 4) {
        let diff = (largestX - smallestX) * 0.05;
        if ((smallestX >= 0 && smallestX - diff >= 0) || (smallestX < 0)) {
          smallestX -= diff;
        }
        largestX += diff;

        let averageX = sumX / data.length;
        let averageY = sumY / data.length;

        let numerator = 0,
          denominator = 0;
        for (var i = 0; i < data.length; i++) {
          numerator += (data[i].x - averageX) * (data[i].y - averageY);
          denominator += (data[i].x - averageX) * (data[i].x - averageX);
        }

        b1 = numerator / denominator;
        b0 = averageY - (b1 * averageX);

        let ssTot = data.reduce((sum, d) => sum + ((d.y - averageY) * (d.y - averageY)), 0);
        let ssReg = data.reduce((sum, d) => {
          let predicted = b0 + (d.x * b1);
          return sum + ((predicted - averageY) * (predicted - averageY));
        }, 0);

        rSquared = ssReg / ssTot;

        datasets.push({
          type: 'line',
          label: 'Line Dataset',
          data: [{ x: smallestX, y: b0 + (smallestX * b1) },
            { x: largestX, y: b0 + (largestX * b1) }
          ],
          borderColor: 'rgba(235, 162, 54, 1)',
          fill: false,
          showLine: true,
          borderWidth: 2,
          pointRadius: 1
        })
      }


      $(canvas).off('mousemove.scatter').on('mousemove.scatter', e => {
        eventCatcherAnnotation.onMousemove(e);
        eventCatcherAnnotation.onMouseout(e);
      });
      $(canvas).off('mouseup.scatter').on('mouseup.scatter', e => {
        eventCatcherAnnotation.onMouseup(e);
      });

      return {
        type: 'line',
        data: {
          datasets
        },
        options: {
          title: { display: false },
          legend: { display: false },
          animation: {
            duration: 0
          },
          maintainAspectRatio: false,
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                if (tooltipItem.datasetIndex === 0) {
                  return `${y.formatValue(tooltipItem.yLabel)} ${y.groupingTitle}`;
                }
                return `${y.title} = ${b0.toFixed(3)} + ${b1.toFixed(3)}(${x.title})`;
              },
              title: function(tooltipItem, data) {
                if (tooltipItem[0].datasetIndex === 0) {
                  return `${x.formatValue(tooltipItem[0].xLabel)} ${x.groupingTitle} ${tooltipItem[0].index < comparisonData.length ? 'Comparison' :''}`;
                }
                return `r²=${rSquared.toFixed(3)}`;
              }
            }
          },
          scales: {
            xAxes: [{
              type: 'linear',
              position: 'bottom',
              ticks: {
                callback: function(value) {
                  return x.formatValue(value, true);
                },
                autoSkip: false
              },
              scaleLabel: {
                display: true,
                labelString: x.groupingTitle
              },
              columnKey: x.key
            }],
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: y.groupingTitle
              },
              ticks: {
                callback: function(value) {
                  return y.formatValue(value, true);
                }
              },
              columnKey: y.key
            }]
          },


          annotation: {
            drawTime: 'afterDraw', // (default)
            events: ['mousedown', 'mousemove', 'mouseup', 'mouseout'],
            annotations: [
              progressAnnotation,
              eventCatcherAnnotation
            ]
          }

        }
      }
    });
  }

}


ChartFactory.register('scatter', ScatterChart);