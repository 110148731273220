angular.module('flashlightApp').run(["$rootScope", "$window", function($rootScope, $window) {
  const checkSizeDelay = 2000;
  var checkSizeTimeout = null;
  var ID_COUNTER = 0;
  var remainingIds = new Set();
  $rootScope.beginRender = () => {
    let id = ID_COUNTER++;
    remainingIds.add(id);
    return () => {
      remainingIds.delete(id);
      if (remainingIds.size === 0) {
        clearTimeout(checkSizeTimeout);
        checkSizeTimeout = setTimeout(() => {
          if (remainingIds.size === 0) {
            $window.renderable = true;
          }
        }, checkSizeDelay);
      }
    };
  };
}]);