const Settings = require('../Settings');

class TableElement {
  
  constructor(grid) {
    this.grid = grid;
    this.isPagedIn = false;
  }

  getHeight() {
    return 0;
  }

  pageIn() {}

  pageOut() {}

  hash() {
    return Promise.resolve(null);
  }

  fastHash() {
    return 0;
  }

  executePageIn() {
    if (!this.isPagedIn) {
      this.isPagedIn = true;
      this.pageIn();
    }
  }

  executePageOut() {
    if (this.isPagedIn) {
      this.isPagedIn = false;
      this.pageOut();
    }
  }

  leftPadding() {
    if (this.step) {
      return this.grid.getInsetAmount(this.step);
    }
    return 0;
  }

  viewportChanged() {
    if (this.isVisible()) {
      this.executePageIn();
    }
    else {
      this.executePageOut();
    }
  }

  refresh() {}

  isVisible() {
    let top = this.getTop(), bottom = this.getBottom();
    return (top < this.grid.viewport.bottom + Settings.SCREEN_OVERFLOW_BUFFER && bottom > this.grid.viewport.top - Settings.SCREEN_OVERFLOW_BUFFER);
  }

  setElementAbove(element) {
    this.elementAbove = element;
    this._bottom = undefined;
    this._top = undefined;
  }

  getBottom() {
    if (this._bottom === undefined) {
      if (this.elementAbove) {
        this._bottom = this.elementAbove.getBottom() + this.getHeight();
      }
      else {
        this._bottom = this.getHeight();
      }
    }
    return this._bottom;
  }

  getTop() {
    if (this._top === undefined) {
      if (this.elementAbove) {
        this._top = this.elementAbove.getBottom();
      }
      else {
        this._top = 0;
      }
    }
    return this._top;
  }


  getStickyOffsetTop() {
    if (this.elementAbove) {
      return this.elementAbove.getStickyOffsetTop();
    }
    return 0;
  }

  getStickyOffsetBottom() {
    return 0;
  }


  layoutChanged() {
    this._bottom = undefined;
    this._top = undefined;
  }

}

module.exports = TableElement;